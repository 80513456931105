import { useState } from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { Invoice, PaymentHistory, Activities, Appointments, ChangeLogs, CommunicationHistory, ECertificate, ServiceReport } from '../../services/PropertyData';
//Quotation,

export const PropertyTabs = ({ propertyId, propertyData }) => {
    const [value, setValue] = useState("invoice");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <TabContext value={value}>
            <Box sx={{ width: '100%', bgcolor: 'background.paper', borderBottom: '1px solid #cccccc' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    TabIndicatorProps={{
                        sx: {
                            backgroundColor: "#000000"
                        }
                    }}
                    scrollButtons
                    allowScrollButtonsMobile
                    aria-label="scrollable force tabs"

                >
                    {/* <Tab label="Quotation" value="quote" sx={{ fontWeight: 'bold' }} /> */}
                    <Tab label="Invoice" value="invoice" sx={{ fontWeight: 'bold' }} />
                    <Tab label="Payment History" value="payment" sx={{ fontWeight: 'bold' }} />
                    <Tab label="Activities" value="activity" sx={{ fontWeight: 'bold' }} />
                    <Tab label="Appointments" value="appointment" sx={{ fontWeight: 'bold' }} />
                    <Tab label="Change Logs" value="logs" sx={{ fontWeight: 'bold' }} />
                    <Tab label="Service Report" value="service" sx={{ fontWeight: 'bold' }} />
                    <Tab label="E-Certificate" value="certificate" sx={{ fontWeight: 'bold' }} />
                    <Tab label="Communication History" value="communication" sx={{ fontWeight: 'bold' }} />
                </Tabs>
            </Box>
            {/* <TabPanel value="quote" className='tabpanel'>
                <Quotation propertyId={propertyId} />
            </TabPanel> */}
            <TabPanel value="invoice" className='tabpanel'>
                <Invoice propertyId={propertyId} propertyData={propertyData} />
            </TabPanel>
            <TabPanel value="payment" className='tabpanel'>
                <PaymentHistory />
            </TabPanel>
            <TabPanel value="activity" className='tabpanel'>
                <Activities />
            </TabPanel>
            <TabPanel value="appointment" className='tabpanel'>
                <Appointments />
            </TabPanel>
            <TabPanel value="logs" className='tabpanel'>
                <ChangeLogs />
            </TabPanel>
            <TabPanel value="service" className='tabpanel'>
                <ServiceReport propertyId={propertyId} />
            </TabPanel>
            <TabPanel value="certificate" className='tabpanel'>
                <ECertificate />
            </TabPanel>
            <TabPanel value="communication" className='tabpanel'>
                <CommunicationHistory />
            </TabPanel>
        </TabContext>
    );
}