import { useEffect, useContext } from 'react';
import { useForm, Controller } from "react-hook-form";
import dayjs from 'dayjs';
//import 'dayjs/locale/en-au';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Box, TextField, Autocomplete, Typography } from '@mui/material';
//import Checkbox from '@mui/material/Checkbox';
import { FormContext } from "./ManageJobs";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
//import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
//import CheckBoxIcon from '@mui/icons-material/CheckBox';
//import * as moment from 'moment'

/* const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
 */
const JobForm = () => {

    const _role = localStorage.getItem("_role");

    const { dialogOpen, handleDialogClose, serviceUsersOptions, jobStatusOptions, formField, setFormField, onSubmit } = useContext(FormContext);
    //const [startDate, setStartDate] = useState(dayjs(new Date()));
    //const [endDate, setEndDate] = useState(dayjs(new Date()));

    //const electriciansData = serviceUsersOptions ? serviceUsersOptions.filter(a => a.code === "E-TRADIE") : [];
    //const plumbersData = serviceUsersOptions ? serviceUsersOptions.filter(a => a.code === "P-TRADIE") : [];

    //const [technicianInput, setTechnicianInput] = useState(formField.technician_name);

    const actionFun = formField.action;

    const service_type = (formField.service_type === 'ES' || formField.service_type === 'SA') ? 'E-TRADIE' : 'P-TRADIE';

    const { reset, handleSubmit, control } = useForm();

    useEffect(() => {

        //if(formField.property_id){        
        reset(formField);
        //} 

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formField]);

    /*const getSelectedElectricians = (electricians) => {

        let electricianItems = [];
        let i = 0;
        //console.log(electricians);
        if (electricians) {
            // eslint-disable-next-line   
            serviceUsersOptions.find((opt) => {
                // eslint-disable-next-line 
                electricians.map((item) => {
                    //console.log(item)
                    if (opt.id === item) {
                        electricianItems[i] = opt;
                        i++;
                    }
                });

            });
        }

        //console.log(electricianItems);

        return electricianItems || [];
    }

     const getSelectedPlumbers = (plumbers) => {

        let plumberItems = [];
        let i = 0;
        if (plumbers) {
            // eslint-disable-next-line   
            plumbersData.find((opt) => {
                // eslint-disable-next-line 
                plumbers.map((item) => {
                    if (opt.id === item) {
                        plumberItems[i] = opt;
                        i++;
                    }
                });
            });
        }

        //console.log(plumberItems);

        return plumberItems || [];
    }*/

    const getSelectedItem = (label) => {
        // eslint-disable-next-line
        const item = jobStatusOptions.find((opt) => {
            if (opt && opt.label === label) {
                //setPrefixValue(prefix);
                return opt;
            };

        })

        return item || null;
    }

    const getSelectedTechnician = (id, code) => {
        // eslint-disable-next-line
        const item = serviceUsersOptions.filter(a => a.code === code).find((opt) => {

            if (opt && opt.id === id) {

                return opt;
            };

        });


        return item || null;
    }

    return (
        <Dialog
            fullWidth
            open={dialogOpen}
            onClose={handleDialogClose}
            scroll='body'
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogTitle id="scroll-dialog-title">{actionFun && actionFun[0].toUpperCase() + actionFun.slice(1)} Job</DialogTitle>
            <Box component="form" id="job-form" name="job-form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>

                <DialogContent dividers={false}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>


                        <DatePicker
                            disablePast
                            label="Job Date"
                            format="DD/MM/YYYY"
                            value={dayjs(formField.job_date)}
                            onChange={(newValue) => { setFormField((prev) => ({ ...prev, job_date: newValue })) }}
                            sx={{ marginRight: '15px' }}
                        />

                        {/*  <DatePicker
                                disablePast
                                label="Service Date"
                                format="DD/MM/YYYY"
                                value={dayjs(formField.service_date)}
                                onChange={(newValue) => setFormField((prev) => ({ ...prev, service_date: newValue }))}
                                readOnly={(_role === 'SYS' || _role === 'ADMSTF') ? false : true}
                            /> */}


                    </LocalizationProvider>
                    <TextField
                        id="job_time" name="job_time" variant="outlined" label="Job Time"
                        helperText='Example Format: 9:00 AM - 2:00 PM'

                        inputProps={{ maxLength: 100, value: formField.job_time }}

                        onChange={(e) => setFormField((prev) => ({ ...prev, job_time: e.target.value }))}
                    />
                    <Typography
                        sx={{ display: 'block', marginTop: '15px', marginBottom: '15px' }}
                        component="h6"
                        variant="subtitle-1"
                        color="text.primary"
                    >
                        Assign Technician
                    </Typography>

                    <Controller
                        name="technician_id"
                        control={control}
                        rules={{
                            required: "Technician is required"
                        }}
                        render={({ field, fieldState }) => (
                            <Autocomplete
                                disablePortal
                                readOnly={(_role === 'SYS' || _role === 'ADMSTF') ? false : true}
                                id="technician_id"
                                options={serviceUsersOptions.filter(a => a.code === service_type)}
                                getOptionLabel={(option) => option.label}

                                sx={{ width: '100%', marginTop: '20px' }}
                                renderOption={(props, option) => (
                                    <Box component="li" {...props} key={option.id}>
                                        {option.label}
                                    </Box>
                                )}
                                isOptionEqualToValue={(options, value) => options.valueOf === value.valueOf}
                                renderInput={(params) => <TextField {...params} label="Select Technician" variant="outlined"
                                    error={!!fieldState.error}
                                    helperText={fieldState.error?.message}

                                />}
                                onChange={(_, data) => {
                                    field.onChange(data ? data.label : '')
                                    setFormField((prev) => ({ ...prev, technician_id: data ? data.id : '', technician_name: data ? data.label : '' }))
                                }}

                                value={getSelectedTechnician(formField.technician_id, service_type)}
                            />
                        )}
                    />

                    <Controller
                        name="job_status"
                        control={control}
                        rules={{
                            required: "Job Status is required"
                        }}
                        render={({ field, fieldState }) => (
                            <Autocomplete
                                disablePortal
                                id="job_status"
                                options={jobStatusOptions.filter(a => (_role === 'SYS' || _role === 'ADMIN') ? a : /Assigned|Completed - Pending/.test(a.label))}
                                getOptionLabel={(option) => option.label}
                                sx={{ width: '100%', marginTop: '20px' }}
                                renderOption={(props, option) => (
                                    <Box component="li" {...props} key={option.id}>
                                        {option.label}
                                    </Box>
                                )}
                                isOptionEqualToValue={(options, value) => options.valueOf === value.valueOf}
                                renderInput={(params) => <TextField {...params} label="Status" variant="outlined"
                                    error={!!fieldState.error}
                                    helperText={fieldState.error?.message}

                                />}
                                onChange={(_, data) => {
                                    field.onChange(data ? data.label : '')
                                    setFormField((prev) => ({ ...prev, job_status: data ? data.label : '' }))
                                }}

                                value={getSelectedItem(formField.job_status)}
                            />
                        )}
                    />

                </DialogContent>
                <DialogActions className='p-4'>
                    <Button type="button" className="themeholy-btn btn-normal" onClick={handleDialogClose}>Cancel</Button>
                    <Button type="submit" className="themeholy-btn btn-primary">Submit</Button>
                </DialogActions>
            </Box>
        </Dialog>
    );

}

export default JobForm;