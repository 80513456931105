import { apiBaseUrl } from '../common';
import axios from 'axios';

const _token = localStorage.getItem("_token");
axios.defaults.withCredentials = true;
axios.defaults.headers['Accept'] = 'application/json';
axios.defaults.headers['Content-Type'] = 'application/json';
if (_token) axios.defaults.headers['Authorization'] = 'Bearer ' + _token;

export const UserData = {
    // eslint-disable-next-line
    fetchData(filter) {
        var params = '';

        if(filter === 'job'){
            params = '?filter=job'
        }
        return axios.post(apiBaseUrl + 'auth/users'+params).then(response => response.data).then(resData => {

            if (resData.status === 'success') {
                return resData.data;
            }
        }).catch((error) => {
            console.log(error);
            if (error.code === "ERR_NETWORK") {

            }
            return [];
        });
    }

};