import { useState, useEffect, forwardRef, createContext, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { GridRowsProp, GridColDef } from '@mui/x-data-grid';
import { Alert, IconButton, Collapse, Button, Chip, Dialog, Slide, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import DataTableList from '../DataTableList';
import { apiBaseUrl } from '../../common';
import axios from 'axios';
//import { MasterForm } from "./MasterForm";
//import { AccountContext } from "../../pages/MyAccount";
import { JobContext } from "../../pages/Jobs";
import { AppContext } from "../../pages/Main";
import JobForm from "./JobForm";
import InvoiceForm from "./InvoiceForm";
import Checklist from "./Checklist";
import dayjs from 'dayjs';
export const FormContext = createContext(null);

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ManageJobs = () => {
    const navigate = useNavigate(null);

    const _token = localStorage.getItem("_token");
    const _role = localStorage.getItem("_role");
    axios.defaults.withCredentials = true;
    axios.defaults.headers['Accept'] = 'application/json';
    axios.defaults.headers['Content-Type'] = 'application/json';
    axios.defaults.headers['Authorization'] = 'Bearer ' + _token;

    const { handleLogout } = useContext(AppContext);

    const { setBackDropOpen, serviceUsersOptions, jobStatusOptions } = useContext(JobContext);

    //const { setBackDropOpen } = useContext(AccountContext);
    const [loader, setLoader] = useState(true);
    const [loaderText, setLoaderText] = useState(false);
    const [DTColumns, setDTColumns] = useState([]);
    const [alertStatus, setAlertStatus] = useState('success');
    const [alertOpen, setAlertOpen] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [invoiceOpen, setInvoiceOpen] = useState(false);
    const [checkListOpen, setCheckListOpen] = useState(false);
    const [jobData, setJobData] = useState({});

    const [confirmopen, setConfirmOpen] = useState(false);
    const [delData, setDelData] = useState({});

    const [serviceId, setServiceId] = useState('');

    const [formField, setFormField] = useState({
        'property_id': '',
        'job_status': '',
        'product_code': '',
        'technician_id': '',
        // 'service_date': '',
        'job_date': '',
        'job_time': '',
        'service_type': '',
        'action': '',
    });
    //const [rowKeyVal, setRowKeyVal] = useState(0);
    const [alertMessage, setAlertMessage] = useState('');
    const [mailOpen, setMailOpen] = useState(false);

    const fetchData = (property_id) => {
        setBackDropOpen(true);
        var params = '';
        if (property_id) params = '?property_id=' + property_id;


        /* if(doFilter === true){
            params='?';
            if(pcodeValue) params+='suburb='+pcodeValue.suburb;
            if(ownerValue){
                if(params.length>1) params+='&';
                params+='owner_id='+ownerValue.id;
            } 
            if(agentValue){
                if(params.length>1) params+='&';
                params+='agent_id='+agentValue.id;
            } 
            setDoFilter(false);
        }   */

        axios.get(apiBaseUrl + 'services' + params)
            .then(response => response.data).then(resData => {
                //console.log(resData.data);
                if (resData.status === 'success') {
                    setDTColumns(resData.data);
                    setLoader(false);
                } else {
                    setAlertOpen(true);
                    setAlertStatus('error');
                    setAlertMessage(resData.message);
                    handleAutoClose();
                }
                setBackDropOpen(false);

            }).catch((error) => {
                console.log(error);
                if (error.code === "ERR_NETWORK") {
                    error.message = "Something went wrong. Server is not repond to request. Please contact administrator."
                } else if (error.response.status === 401) {
                    handleLogout();
                    navigate('/login');
                }
                setAlertOpen(true);
                setAlertStatus('error');
                setAlertMessage(error.message);
                handleAutoClose();
                setBackDropOpen(false);
            });
    }

    useEffect(() => {

        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSendEmail = () => {
        setMailOpen(true);
        /* axios.defaults.withCredentials = false;
        axios.get('https://safetyninja.360membershipcrm.com.au/safetycheck/sendemail.php')
            .then(response => {
                console.log(response)
                setMailOpen(true);
            }); */
    }

    const actionButtons = (params) => {
        if (_role === 'SYS' || _role === 'ADMIN') {
            return (
                <>
                    {/Cancelled/.test(params.row.job_status) && <Button type="button" title="Add Job" onClick={(e) => handleAdd(params.row.id)} style={{ minWidth: '24px' }}><i className="fa fa-square-plus"></i></Button>}
                    <Button type="button" title="Edit Job" onClick={(e) => handleEdit(params.api.getRowIndexRelativeToVisibleRows(params.row.id), params.row)} style={{ minWidth: '24px' }}><i className="fa fa-edit"></i></Button>
                    <Button type="button" title="Delete Job" onClick={(e) => deleteRecord(params.row.id)} style={{ minWidth: '24px', color: 'red' }}><i className="fa fa-trash"></i></Button>
                    {/* <Link to="https://safetyninja.360membershipcrm.com.au/ElectricalSafetyChecklist.pdf" target="_blank" title='Checklist'><Button type="button" style={{ minWidth: '24px', color: 'green' }}><i className="fa fa-th-list"></i></Button></Link> */}
                    {(/Assigned|Completed|Closed|Lead/.test(params.row.job_status) && params.row.technician) && <><Button type="button" title='Checklist' onClick={(e) => handleChecklist(params.row)} style={{ minWidth: '24px', color: 'green' }}><i className="fa fa-th-list"></i></Button>
                        <Link to="https://safetyninja.360membershipcrm.com.au/safetycheck/sendemail.php" title='Save & Send Email' target="_blank" rel="noopener noreferrer" onClick={handleSendEmail}><Button type="button" style={{ minWidth: '24px', color: 'black' }}><i className="fa fa-save"></i></Button></Link>
                    </>}
                    {(/Completed|Closed|Lead/.test(params.row.job_status)) && <Button type="button" title='Invoice' onClick={(e) => handleInvoice(params.row.id, params.row.product.service_charge, params.row.invoices)} style={{ minWidth: '24px', color: 'green' }}><i className="fa fa-file-invoice"></i></Button>}
                </>);
        } else if (_role === 'E-TRADIE' || _role === 'P-TRADIE') {
            return (
                <>
                    <Button type="button" title="Edit Job" onClick={(e) => handleEdit(params.api.getRowIndexRelativeToVisibleRows(params.row.id), params.row)} style={{ minWidth: '24px' }}><i className="fa fa-edit"></i></Button>
                    {(/Assigned|Completed|Closed|Lead/.test(params.row.job_status) && params.row.technician) && <Button type="button" title='Checklist' onClick={(e) => handleChecklist(params.row)} style={{ minWidth: '24px', color: 'green' }}><i className="fa fa-th-list"></i></Button>}
                </>
            );
        }
    }

    const handleStatusColor = (status) => {
        var color = 'default';
        if (/Assigned/.test(status)) {
            color = 'primary';
        } else if (/Approved/.test(status)) {
            color = 'success';
        } else if (/Pending Approval/.test(status)) {
            color = 'secondary';
        } else if (/Cancelled/.test(status)) {
            color = 'error';
        }
        return color;
    }

    const showText = (params) => {
        let stype = params.row.service_type;

        if (params.row.product.code === 'PROD3LMSM' || params.row.product.code === 'PROD4LMSM') {
            stype += ' only';
        } else if (params.row.service_type === 'ES') {
            stype += '/SA';
        }

        return (<>{params.row.product_code + ' - ' + stype} {params.row.invoices && <><br />Inv #: {params.row.invoices.invoice_number}</>}</>);
    }

    const rows: GridRowsProp = DTColumns;

    const columns: GridColDef[] = [
        { field: 'sno', headerName: '#', width: 80, renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1 },
        { field: 'id', headerName: 'Job ID', width: 80 },
        //{ field: 'service_date', headerName: 'Service Date', width: 100 },
        { field: 'job_date', headerName: 'Job Date', width: 100 },
        { field: 'job_time', headerName: 'Job Time', width: 150 },
        { field: 'property_owner', headerName: 'Land Lord', width: 125, renderCell: (params) => (params.row.property.propertyowner.prefix ? params.row.property.propertyowner.prefix + '. ' : '') + params.row.property.propertyowner.lastname + ' ' + params.row.property.propertyowner.firstname, valueGetter: (params) => (params.row.property.propertyowner.prefix ? params.row.property.propertyowner.prefix + '. ' : '') + ' ' + params.row.property.propertyowner.lastname + ' ' + params.row.property.propertyowner.firstname },
        { field: 'property_id', headerName: 'Property', width: 200, renderCell: (params) => (params.row.property.address1 + (params.row.property.address2 ? ', ' + params.row.property.address2 : '') + ', ' + params.row.property.suburb + ', ' + params.row.property.state + ' - ' + params.row.property.postcode), valueGetter: (params) => params.row.property.address1 + (params.row.property.address2 ? ', ' + params.row.property.address2 : '') + ', ' + params.row.property.suburb + ', ' + params.row.property.state + ' - ' + params.row.property.postcode },
        { field: 'product_code', headerName: 'Service Type', width: 150, renderCell: (params) => showText(params), valueGetter: (params) => showText(params) },
        { field: 'technician_id', headerName: 'Technician', width: 125, renderCell: (params) => (params.row.technician.lastname ? params.row.technician.lastname : '') + ' ' + (params.row.technician.firstname ? params.row.technician.firstname : ''), valueGetter: (params) => (params.row.technician.lastname ? params.row.technician.lastname : '') + ' ' + (params.row.technician.firstname ? params.row.technician.firstname : '') },
        {
            field: 'job_status', headerName: 'Status', width: 200, renderCell: (params) => (params.row.job_status ? <Chip sx={{
                height: 'auto',
                '& .MuiChip-label': {
                    display: 'block',
                    whiteSpace: 'normal',
                },
            }} label={params.row.job_status} color={handleStatusColor(params.row.job_status)} size="small" /> : ''), valueGetter: (params) => params.row.job_status ? params.row.job_status : ''
        },
        { field: 'actions', headerName: 'Actions', width: 150, sortable: false, renderCell: (params) => actionButtons(params) },
    ];

    const handleEdit = (key, data) => {
        setLoaderText(false);

        setServiceId(data.id);
        //setRowKeyVal(key);

        //const service_date = data.service_date.split('/');
        const job_date = data.job_date.split('/');

        setFormField({
            'property_id': data.property_id,
            'job_status': data.job_status,
            'service_type': data.service_type,
            'technician_id': data.technician_id,
            'technician_name': (data.technician.lastname ? data.technician.lastname : '') + ' ' + (data.technician.firstname ? data.technician.firstname : ''),
            // 'service_date': service_date[2] + '/' + service_date[1] + '/' + service_date[0],
            'job_date': job_date[2] + '/' + job_date[1] + '/' + job_date[0],
            'job_time': data.job_time,
            'action': 'edit',
        });
        setDialogOpen(true);

        //setTimeout(() => { document.getElementById('service_date').focus() }, 100);
    };

    const handleAdd = () => {
        setLoaderText(false);
        setServiceId('');
        setFormField({
            'property_id': '',
            'job_status': '',
            'service_type': '',
            'technician_id': '',
            // 'service_date': '',
            'job_date': '',
            'job_time': '',
            'action': 'add',
        });
        setDialogOpen(true);
        //setTimeout(() => { document.getElementById('service_date').focus() }, 100);
    };

    const deleteRecord = (id) => {
        var data = {
            "id": id,
            "_method": 'DELETE'
        }
        setDelData(data);
        setConfirmOpen(true);
    }

    const handleDelete = () => {
        axios.post(apiBaseUrl + 'services/' + delData.id, delData)
            .then(response => response.data).then(resData => {
                setAlertOpen(true);
                if (resData.status === 'success') {
                    setAlertStatus('success');
                    fetchData();
                } else {
                    setAlertStatus('error');
                }
                setAlertMessage(resData.message);
                handleAutoClose();
                setLoaderText(false);
                setConfirmOpen(false);
                setDelData({});
            }).catch((error) => {
                console.log(error);
                setLoaderText(false);
                setAlertOpen(true);
                setAlertStatus('error');
                setAlertMessage('Something went wrong with Service API. Error: ' + error.message);
                handleAutoClose();
                setConfirmOpen(false);
                setDelData({});
            });
    };

    const handleInvoice = (id, amount, invoices) => {
        console.log(amount);
        setServiceId(id);
        setFormField({
            'invoice_id': invoices ? invoices.id : '',
            'invoice_date': new Date(),
            'amount': amount,
            'received': invoices ? invoices.received : 0,
            'balance': invoices ? invoices.balance : 0,
            'description': invoices ? invoices.description : '',
            'paid_status': invoices ? invoices.paid_status : null,
            'action': invoices && invoices.id ? 'edit' : 'add',
        });
        setInvoiceOpen(true);
    }
    const handleChecklist = (data) => {
        setServiceId(data.id);
        setJobData(data);
        setCheckListOpen(true);
    }

    const handleAutoClose = () => {
        window.scrollTo(0, 0);
        setTimeout(() => setAlertOpen(false), 5000);
    };

    const handleDialogClose = (event, reason) => {
        if (reason && reason === "backdropClick") return;
        setDialogOpen(false);
        setServiceId('');
        setFormField({
            'property_id': '',
            'job_status': '',
            'service_type': '',
            'technician_id': '',
            'technician_name': '',
            // 'service_date': '',
            'job_date': '',
            'job_time': '',
            'action': '',
        });
    };

    const handleInvoiceClose = (event, reason) => {
        if (reason && reason === "backdropClick") return;
        setInvoiceOpen(false);
        setServiceId('');
        setFormField({
            'invoice_id': '',
            'invoice_date': '',
            'description': '',
            'amount': '',
            'received': '',
            'balance': '',
            'paid_status': '',
            'action': '',
        });
    };

    const handleChecklistClose = (event, reason) => {
        if (reason && reason === "backdropClick") return;
        setCheckListOpen(false);
        setServiceId('');
        setJobData({});
    };

    const handleConfirmClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setConfirmOpen(false);
            setDelData({});
        }
    };

    const handleMailClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setMailOpen(false);
        }
    };

    const onSubmit = (data) => {
        setLoaderText(true);

        //console.log(serviceId);


        //data.service_date = dayjs(data.service_date).format('DD-MM-YYYY');
        data.job_date = dayjs(data.job_date).format('DD-MM-YYYY');

        //console.log(data);

        var axiosCall = null;

        if (formField.action === 'edit') {
            data._method = 'PUT';
            axiosCall = axios.post(apiBaseUrl + 'services/' + serviceId, data);
        } else if (formField.action === 'add') {
            axiosCall = axios.post(apiBaseUrl + 'services', data);
        }

        axiosCall.then(response => response.data).then(resData => {
            // console.log(rowKeyVal);
            setAlertOpen(true);
            if (resData.status === 'success') {
                setAlertStatus('success');
                handleDialogClose();
                fetchData();
            } else {
                setAlertStatus('error');
            }
            setAlertMessage(resData.message);
            handleAutoClose();
            setLoaderText(false);
            handleDialogClose();
        }).catch((error) => {
            console.log(error);
            setLoaderText(false);
            setAlertOpen(true);
            setAlertStatus('error');
            setAlertMessage('Something went wrong with Service API. Error: ' + error.message);
            handleAutoClose();
            handleDialogClose();
        });
    };

    const onInvoiceSubmit = (data) => {
        setLoaderText(true);

        //console.log(serviceId);
        data.service_id = serviceId;
        data.invoice_date = dayjs(data.invoice_date).format('DD-MM-YYYY');

        // console.log(data);

        var axiosCall = null;

        if (formField.action === 'edit') {
            data._method = 'PUT';
            axiosCall = axios.post(apiBaseUrl + 'invoices/' + formField.invoice_id, data);
        } else if (formField.action === 'add') {
            axiosCall = axios.post(apiBaseUrl + 'invoices', data);
        }

        axiosCall.then(response => response.data).then(resData => {
            setAlertOpen(true);
            if (resData.status === 'success') {
                setAlertStatus('success');
                handleInvoiceClose();
                fetchData();
            } else {
                setAlertStatus('error');
            }
            setAlertMessage(resData.message);
            handleAutoClose();
            setLoaderText(false);
            handleInvoiceClose();
        }).catch((error) => {
            console.log(error);
            setLoaderText(false);
            setAlertOpen(true);
            setAlertStatus('error');
            setAlertMessage('Something went wrong with Service API. Error: ' + error.message);
            handleAutoClose();
            handleInvoiceClose();
        });
    }

    return (
        <>
            <Collapse in={alertOpen}>
                <Alert variant="filled" severity={alertStatus}
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setAlertOpen(false);
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    sx={{ mb: 2 }}
                >
                    {alertMessage}
                </Alert>
            </Collapse>

            <Dialog
                open={confirmopen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleConfirmClose}
                aria-describedby="alert-dialog-slide-description"

            >
                <DialogTitle>Are you sure you want to delete this Job?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        If yes, job will be deleted from the records and no longer be exists.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className="themeholy-btn btn-secondary" onClick={handleConfirmClose}>Cancel</Button>
                    <Button className="themeholy-btn btn-primary" onClick={() => handleDelete({ delData })}>Yes</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={mailOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleMailClose}
                aria-describedby="alert-dialog-slide-description"

            >
                <DialogTitle>Save Checklist</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Save and Mail has been sent successfully.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className="themeholy-btn btn-primary" onClick={(e) => handleMailClose(e, 'ok')}>OK</Button>
                </DialogActions>
            </Dialog>


            <FormContext.Provider value={{
                handleDialogClose, onSubmit, loaderText, formField, setFormField, alertStatus,
                alertMessage,
                alertOpen,
                setAlertOpen,
                setAlertStatus,
                setAlertMessage,
                dialogOpen,
                serviceUsersOptions,
                jobStatusOptions,
                handleInvoiceClose,
                invoiceOpen,
                serviceId,
                onInvoiceSubmit,
                checkListOpen,
                handleChecklistClose,
                jobData,
                fetchData
            }}>
                {dialogOpen && <JobForm />}
                {invoiceOpen && <InvoiceForm />}
                {checkListOpen && <Checklist />}
            </FormContext.Provider>
            {/*(_role === 'SYS' || _role === 'ADMSTF') && (
            <div style={{ display: "flex", marginBottom: "15px" }}>
                <Button className="themeholy-btn btn-primary" style={{ marginLeft: "auto" }} onClick={handleAdd}>Add New Job</Button>
            </div>
            )*/}
            <DataTableList columns={columns} rows={rows} loading={loader} />
        </>
    );
}

export default ManageJobs;