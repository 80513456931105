import { useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import { Box, Alert, TextField, IconButton, Button, AppBar, Toolbar, Typography, FormControl, InputLabel, Select, MenuItem, Collapse } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { FormContext } from "./EmailTemplate";
import { placeholder } from '../../common';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const editorConfiguration = {
    toolbar: {
        items: [
            'undo', 'redo',
            '|', 'heading',
            '|', 'bold', 'italic',
            '|', 'link', 'bulletedList', 'numberedList', 'outdent', 'indent'
        ],
        shouldNotGroupWhenFull: false
    }
}

export const EmailTemplateForm = () => {

    const { handleDialogClose, onSubmit, loaderText, formField, setFormField, alertStatus,
        alertMessage,
        alertOpen,
        setAlertOpen } = useContext(FormContext);

    const { register, handleSubmit, formState: { errors } } = useForm();

    const actionFun = formField.action;

    useEffect(() => {

        setFormField({
            'emtemp_type': formField.emtemp_type,
            'emtemp_subject': formField.emtemp_subject,
            'emtemp_content': formField.emtemp_content,
            'status': formField.status,
            'action': formField.action,
            'changedFlag': 'N',
        });

    }, [formField, setFormField]);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    return (

        <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <AppBar sx={{ position: 'fixed' }} className="app-header-top">
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleDialogClose}
                        aria-label="close"
                        className="me-auto"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ p: 2, flex: 1, width: 'auto' }} variant="h6" component="div" className="d-none d-lg-block">
                        {actionFun && actionFun[0].toUpperCase() + actionFun.slice(1)} Email Template
                    </Typography>
                    <Button type="button" autoFocus className="themeholy-btn btn-normal" style={{ marginRight: '10px' }} onClick={handleDialogClose}>
                        Cancel
                    </Button>
                    <Button type="submit" autoFocus className="themeholy-btn btn-normal" disabled={loaderText}>
                        {(loaderText) ? 'Saving...' : 'Save'}
                    </Button>
                </Toolbar>
            </AppBar>
            <div className="container" style={{ paddingTop: '90px' }}>
                <Collapse in={alertOpen}>
                    <Alert variant="filled" severity={alertStatus}
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setAlertOpen(false);
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                    >
                        {alertMessage}
                    </Alert>
                </Collapse>

                {formField.action && (
                    <div className="row">
                        <div className="col-md-12 d-block d-lg-none">
                            <Typography sx={{ marginBottom: '15px' }} variant="h6" component="div">
                                {actionFun && actionFun[0].toUpperCase() + actionFun.slice(1)} Email Template
                            </Typography>
                        </div>
                        <div className="col-md-12 col-lg-6 m-auto">

                            <FormControl fullWidth>
                                <InputLabel id="status-select-label"> Select Type </InputLabel>
                                <Select
                                    labelId="status-select-label"
                                    id="emtemp_type"
                                    name="emtemp_type"
                                    error={errors.emtemp_type ? true : false}
                                    value={formField.emtemp_type}
                                    label="Select Type *"
                                    {...register("emtemp_type", { required: "Type is required" })}
                                    onChange={(e) => setFormField((prev) => ({ ...prev, emtemp_type: e.target.value }))}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem value='EMAIL'>EMAIL</MenuItem>
                                    <MenuItem value='SMS'>SMS</MenuItem>
                                </Select>
                            </FormControl>

                            <TextField
                                fullWidth
                                id="emtemp_subject" name="emtemp_subject"
                                variant="outlined" error={errors.emtemp_subject ? true : false}
                                label="Email Subject *"
                                className="col-md-12"
                                helperText={errors.emtemp_subject?.message}
                                inputProps={{ value: formField.emtemp_subject }}

                                {...register("emtemp_subject", { required: "Email Subject is required" })}
                                onChange={(e) => setFormField((prev) => ({ ...prev, emtemp_subject: e.target.value }))}
                            />

                            <CKEditor
                                id="emtemp_content"
                                name="emtemp_content *"
                                editor={ClassicEditor}
                                data={formField.emtemp_content}
                                config={editorConfiguration}
                                onReady={editor => {
                                    // You can store the "editor" and use when it is needed.
                                    console.log('Editor is ready to use!', editor);
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setFormField((prev) => ({ ...prev, emtemp_content: data }))
                                }}
                                onBlur={(event, editor) => {
                                    console.log('Blur.', editor);
                                }}
                                onFocus={(event, editor) => {
                                    console.log('Focus.', editor);
                                }}
                            />

                            <label className="w-full error text-red text-sm text-start">{formField.emtemp_content ? "" : "Email Content Required"}</label>



                            <div className="mt-3 mb-4 fs-xs">
                                <label className="text-danger">Placeholder must be covered by square bracket []. For example:</label>
                                {placeholder.PREFIX},{placeholder.FIRSTNAME},{placeholder.LASTNAME},{placeholder.DUEDATE},{placeholder.AGENT},{placeholder.SERVICETYPE},{placeholder.INVOICENO},{placeholder.INVOICEDATE},{placeholder.PAIDAMOUNT}
                            </div>


                            {actionFun === 'edit' && (
                                <FormControl fullWidth>
                                    <InputLabel id="status-select-label"> Select Status </InputLabel>
                                    <Select

                                        labelId="status-select-label"
                                        id="status"
                                        name="status"
                                        error={errors.status ? true : false}
                                        value={formField.status}
                                        label="Select Status *"
                                        {...register("status", { required: "Status is required" })}
                                        onChange={(e) => setFormField((prev) => ({ ...prev, status: e.target.value }))}
                                        MenuProps={MenuProps}
                                    >
                                        <MenuItem value='Y'>Active</MenuItem>
                                        <MenuItem value='N'>Inactive</MenuItem>
                                    </Select>
                                </FormControl>
                            )}

                            <div className="row">
                                <div className="col-md-12 col-lg-6">
                                    <Button type="button" className="themeholy-btn btn-normal mb-3 w-100" onClick={handleDialogClose}>Cancel</Button>
                                </div>
                                <div className="col-md-12 col-lg-6">
                                    <Button type="submit" autoFocus className="themeholy-btn btn-primary w-100" disabled={loaderText}>{(loaderText) ? 'Saving...' : 'Save'}</Button>
                                </div>
                            </div>

                        </div>
                    </div>

                )}

            </div>
        </Box>
    );


}