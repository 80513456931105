import { useState, useEffect, useContext, forwardRef } from "react";
import { Link } from 'react-router-dom';
import { apiBaseUrl } from '../../common';
import axios from 'axios';
import { Box, IconButton, AppBar, Toolbar, Typography, Chip, Button, Collapse, Alert, Slide, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { FormContext } from "./ManageProperties";
import DataTableList from '../DataTableList';
import OwnerInfo from "./ownerinfo";
import OwnerForm from "./ownerform";
import { PropertyTabs } from "./PropertyTabs";

//let userOptions = [];
//let postcodeOptions = [];

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export const PropertyView = () => {
    const _token = localStorage.getItem("_token");
    const _role = localStorage.getItem("_role");
    const _profile_id = localStorage.getItem("_profile_id");
    axios.defaults.withCredentials = true;
    axios.defaults.headers['Accept'] = 'application/json';
    axios.defaults.headers['Content-Type'] = 'application/json';
    axios.defaults.headers['Authorization'] = 'Bearer ' + _token;

    let { handleViewDialogClose, handleAdd, handleEdit, handleView, userOptions, postcodeOptions, propertyId, setPropertyId, viewField, setViewField, viewUpdateFlag } = useContext(FormContext);

    const [DTColumns, setDTColumns] = useState([]);
    const [loader, setLoader] = useState(true);
    const [alertStatus, setAlertStatus] = useState('success');
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [noteOpen, setNoteOpen] = useState(false);
    const [notes, setNotes] = useState('');
    const [ownerEdit, setOwnerEdit] = useState(false);
    const [loaderText, setLoaderText] = useState(false);
    const [viewChangeFlag, setViewChangeFlag] = useState(false);

    const fetchData = () => {
        //console.log('fetch:' + viewField.owner_id);
        //setBackDropOpen(true);
        var params = {};

        if (!viewField) {
            params.owner_id = _profile_id;
        } else {
            params.owner_id = viewField.owner_id;
        }

        axios.post(apiBaseUrl + 'propertyview', params)
            .then(response => response.data).then(resData => {
                if (resData.status === 'success') {
                    setDTColumns(resData.data);
                    if (!propertyId) {
                        var data = resData.data[0];
                        setPropertyId(data.id);

                        setViewField({
                            'property_id': data.id,
                            'owner_id': data.owner_id,
                            'owner_prefix': data.owner.prefix,
                            'owner_name': data.owner.lastname + ' ' + data.owner.firstname,
                            'owner_email': data.owneruser.email,
                            'owner_mobile': data.owneruser.mobile,
                            'owner_address1': data.owner.address1,
                            'owner_address2': data.owner.address2,
                            'owner_suburb': data.owner.suburb,
                            'owner_state': data.owner.state,
                            'owner_postcode': data.owner.postcode,
                            'agent_id': data.agent_id,
                            'agent_prefix': data.agent ? data.agent.prefix : '',
                            'tenancy_name': data.tenant_name,
                            'tenancy_mobile': data.tenancy_mobile,
                            'agent_name': (data.agent.lastname ? data.agent.lastname : '') + ' ' + (data.agent.firstname ? data.agent.firstname : ''),
                            'agency_name': data.agent ? data.agent.agency_name : '',
                            'agent_mobile': data.agentuser ? data.agentuser.mobile : '',
                            'address1': data.address1,
                            'address2': data.address2,
                            'suburb': data.suburb,
                            'state': data.state,
                            'postcode': data.postcode,
                            'product': data.product,
                            'product_code': data.product_code,
                            'product_desc': data.product.description + ' - $' + data.product.service_charge,
                            'status': data.status,
                            'payments': data.payments,
                            'action': 'view'
                        });
                    }
                    setLoader(false);
                } else {
                    setAlertOpen(true);
                    setAlertStatus('error');
                    setAlertMessage(resData.message);
                    handleAutoClose();
                }
                //setBackDropOpen(false);

            }).catch((error) => {
                console.log(error);
                setAlertOpen(true);
                setAlertStatus('error');
                setAlertMessage(error.message);
                handleAutoClose();
                //setBackDropOpen(false);
            });
    }


    useEffect(() => {
        fetchData();
        //setImages([]);    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [propertyId, viewUpdateFlag]);


    const handleAutoClose = () => {
        window.scrollTo(0, 0);
        setTimeout(() => setAlertOpen(false), 5000);
    };

    const handleOpenAdd = () => {
        //setDialogViewOpen(false); 
        //console.log(formField);
        const data = {
            owner_id: viewField.owner_id,
            owner_prefix: viewField.owner_prefix,
            owner_name: viewField.owner_name,
            agent_id: viewField.agent_id,
            agent_prefix: viewField.agent_prefix,
            agent_name: viewField.agent_name,
            tenancy_id: viewField.tenancy_id,
            tenant_prefix: viewField.tenancy_prefix,
            tenant_name: viewField.tenancy_name,
            agency_name: viewField.agency_name
        }
        handleAdd(data);
        //setTimeout(() => { document.getElementById('address1').focus() }, 100);
    };
    /*
        const handleOpenEdit = (key, data) => {
            //setDialogViewOpen(false);     
            handleEdit('viewedit', key, data);
            setTimeout(() => { document.getElementById('address1').focus() }, 100);
    
        }; */

    const viewAlert = (notes) => {
        setNoteOpen(true);
        setNotes(notes);
    };


    const handleNoteClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setNoteOpen(false);
            setNotes('');
        }
    };

    const handleOwnerEdit = () => {
        setOwnerEdit(true);
    }

    const handleOwnerView = (e) => {
        setOwnerEdit(false);
        setLoaderText(false);
        document.getElementById('propertyview').scrollIntoView({ behavior: 'smooth' });
    }

    const onSubmit = (data) => {
        setLoaderText(true);
        //console.log(data);
        //console.log(propertyId);
        if (_role === 'AGENT') data.agent_id = viewField.agent_id;

        data.id = propertyId;
        //setFormField((prev) => ({ ...prev, owner_id: data.owner_id }));
        if (viewChangeFlag) {
            axios.post(apiBaseUrl + 'updateowner', data)
                .then(response => response.data).then(resData => {
                    setAlertOpen(true);
                    if (resData.status === 'success') {
                        fetchData();
                        setAlertStatus('success');
                    } else {
                        setAlertStatus('error');
                    }
                    setAlertMessage(resData.message);
                    handleAutoClose();
                    setLoaderText(false);
                    setOwnerEdit(false);
                }).catch((error) => {
                    console.log(error);
                    setLoaderText(false);
                    setAlertOpen(true);
                    setAlertStatus('error');
                    setAlertMessage('Something went wrong with Properties API. Error: ' + error.message);
                    handleAutoClose();
                });
        } else {
            setLoaderText(false);
            setOwnerEdit(false);
        }
    };


    const rows: GridRowsProp = DTColumns;

    const columns: GridColDef[] = [
        { field: 'sno', headerName: '#', width: 50, renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1 },
        { field: 'tenancy_name', headerName: 'Tenant', width: 160, renderCell: (params) => { return params.row.tenancy_name + ' (' + params.row.tenancy_mobile + ')' }, valueGetter: (params) => params.row.tenancy_name + ' (' + params.row.tenancy_mobile + ')' },
        { field: 'service_duedate', headerName: 'Service Due Date', width: 140 },
        { field: 'address', headerName: 'Address', width: 250, renderCell: (params) => { return (<>{params.row.address1}<br /> {params.row.address2}</>) }, valueGetter: (params) => params.row.address1 + ',' + params.row.address2 },
        { field: 'suburb', headerName: 'Suburb', width: 200 },
        { field: 'state', headerName: 'state', width: 100 },
        { field: 'postcode', headerName: 'postcode', width: 100 },
        { field: 'status', headerName: 'Status', width: 100, renderCell: (params) => (params.row.status === 'Y') ? <Chip label="Active" color="success" size="small" /> : <Chip label="Inactive" color="warning" size="small" />, valueGetter: (params) => (params.row.status === 'Y') ? 'Active' : 'Inactive' },
        { field: 'actions', headerName: 'Actions', width: 150, sortable: false, renderCell: (params) => { return (<><Button type="button" onClick={(e) => handleView(params.api.getRowIndexRelativeToVisibleRows(params.row.id), params.row)} style={{ minWidth: '24px' }}><i className="fa fa-eye"></i></Button><Button type="button" onClick={(e) => handleEdit(params.row)} style={{ minWidth: '24px' }}><i className="fa fa-edit"></i></Button> <Chip label="Pay" color="primary" size="small" className="ml-2" /> {params.row.showalert === 'Y' && <Button type="button" onClick={(e) => viewAlert(params.row.alertnote)} style={{ minWidth: '24px' }}><i className="fa fa-message"></i></Button>}</>) } },

    ];

    // console.log(images);

    return (

        <>
            {viewField && (
                <>
                    <Box>
                        {_role !== 'LDLORD' && (
                            <AppBar sx={{ position: 'fixed' }} className="app-header-top">
                                <Toolbar>
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        onClick={(e) => handleViewDialogClose(e, viewChangeFlag)}
                                        aria-label="close"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                        View Property
                                    </Typography>

                                </Toolbar>
                            </AppBar>
                        )}
                        <Dialog
                            fullWidth
                            open={noteOpen}
                            TransitionComponent={Transition}
                            keepMounted
                            onClose={handleNoteClose}
                            aria-describedby="alert-dialog-slide-notes"

                        >
                            <DialogTitle sx={{ bgcolor: '#000000', color: '#ffffff', marginBottom: '15px' }}>Notes</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-slide-notes">
                                    <i className="fa fa-info-circle"></i> {notes}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button className="themeholy-btn btn-primary" onClick={handleNoteClose}>Ok</Button>
                            </DialogActions>
                        </Dialog>

                        {_role !== 'LDLORD' && <div style={{ margin: "45px" }}>&nbsp;</div>}

                        <div className="container" id="propertyview">
                            <div className="row">
                                <div className="col-md-12 col-lg-6 d-flex" >
                                    <div className="card border-radius w-100">
                                        <div className="card-header">
                                            <div className="row">
                                                <h5 className="mt-1 col-md-8" style={{ textAlign: 'left' }}>{propertyId && 'Property '}Land Lord Info:</h5>
                                                {!ownerEdit && (propertyId ? <Link onClick={handleOwnerEdit} className="col-md-4 mt-3 text-end"><i className="fa fa-pencil"></i></Link> : <Link to='/my-account' className="col-md-4 mt-3 text-end"><i className="fa fa-pencil"></i></Link>)}
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <FormContext.Provider value={
                                                {
                                                    viewField,
                                                    setViewField,
                                                    userOptions,
                                                    postcodeOptions,
                                                    onSubmit,
                                                    handleOwnerView,
                                                    loaderText,
                                                    viewChangeFlag,
                                                    setViewChangeFlag,
                                                    propertyId

                                                }
                                            }>
                                                {ownerEdit ? <OwnerForm /> : <OwnerInfo />}

                                            </FormContext.Provider>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-6 d-flex">
                                    <div className="card border-radius w-100">
                                        <div className="card-header">
                                            <h5 className="mt-1" style={{ textAlign: 'left' }}>Payment Info:</h5>
                                        </div>
                                        <div className="card-body text-start">
                                            <div className="row">
                                                <label className="col-6 fw-bold">Overall Due Amount</label>
                                                <div className="col-6">: ${viewField.payments ? viewField.payments.amount.toFixed(2) : '0.00'}</div>
                                            </div>
                                            <div className="row">
                                                <label className="col-6 fw-bold">Available Credit</label>
                                                <div className="col-6">: ${viewField.payments ? viewField.payments.received.toFixed(2) : '0.00'}</div>
                                            </div>
                                            <div className="row">
                                                <label className="col-6 fw-bold">Pay Amount</label>
                                                <div className="col-6">: ${viewField.payments ? (viewField.payments.amount - viewField.payments.received).toFixed(2) : '0.00'}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 mt-3">
                                                    {(viewField.payments && (viewField.payments.amount - viewField.payments.received) > 0) ?
                                                        <Button className="themeholy-btn btn-primary w-100">Pay Now</Button>
                                                        : <Button className="themeholy-btn btn-primary w-100" disabled style={{ backgroundColor: '#bdbdbd' }}>Pay Now</Button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </Box>


                    <div className="container mt-3">

                        <Collapse in={alertOpen}>
                            <Alert variant="filled" severity={alertStatus}
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                            setAlertOpen(false);
                                        }}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                                sx={{ mb: 2, id: "alertmessage" }}
                            >
                                {alertMessage}
                            </Alert>
                        </Collapse>

                        <div style={{ display: "flex", marginBottom: "15px" }}>
                            <Button className="themeholy-btn btn-primary" style={{ marginLeft: "auto" }} onClick={handleOpenAdd}>Add New Property</Button>
                        </div>
                        <DataTableList columns={columns} rows={rows} loading={loader} id={propertyId} />
                    </div>

                    {propertyId && <div className="container mt-5 text-start">
                        <Typography sx={{ mb: 5 }} variant="h4" component="div">
                            {viewField.address1 + (viewField.address2 ? ', ' + viewField.address2 : '') + ', ' + viewField.suburb + ', ' + viewField.state + ' - ' + viewField.postcode}
                        </Typography>
                        <PropertyTabs propertyId={propertyId} propertyData={viewField} />
                    </div>}
                </>
            )}

        </>

    );
}