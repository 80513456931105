import { useEffect, useState } from 'react';
import { DataGrid, GridToolbar  } from '@mui/x-data-grid';

export default function DataTableList(props) {

  const [togglecol, settogglecol] = useState({})

  useEffect(()=>{
    settogglecol(props.columnVisibilityModel);
  }, [props.columnVisibilityModel])
  return (    
    //checkboxSelection disableColumnMenu disableColumnSelector disableDensitySelector disableColumnFilter

    <div style={{ width: '100%', minHeight: '50px' }}>
      <DataGrid
        rowHeight={50}
        rows={props.rows}
        columns={props.columns}        
        loading={props.loading}
        autoHeight={true}
        pageSizeOptions={[100]}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        columnVisibilityModel={togglecol}
        initialState={{
          filter: {
            filterModel: {
              items: [],
              quickFilterExcludeHiddenColumns: true,
            },
          }
        }}
        getRowClassName={(params) => {
          return (props.id && params.id === props.id) ? 'bg-theme2 text-white' : "";
        }}
      />
    </div>
  );
}
