import { apiBaseUrl } from '../common';
import axios from 'axios';

//const _token = localStorage.getItem("_token");
axios.defaults.withCredentials = true;
axios.defaults.headers['Accept'] = 'application/json';
axios.defaults.headers['Content-Type'] = 'application/json';
//if (_token) axios.defaults.headers['Authorization'] = 'Bearer ' + _token;

export const RolesData = {
    // eslint-disable-next-line
    fetchData() {

        //var axiosAPI = '';
        //if (_token) {
         //axiosAPI = axios.get(apiBaseUrl + 'lkps?type=PREFIX');
        //} else {
            const axiosAPI = axios.get(apiBaseUrl + 'auth/roles');
        //}
        return axiosAPI.then(response => response.data).then(resData => {

            if (resData.status === 'success') {
                return resData.data;
            }
        }).catch((error) => {
            console.log(error);
        });

    }

};