import { useState, useEffect, createContext } from "react";
import SiteHeader from "../components/SiteHeader";
import Footer from "../components/Footer";
import { Routes, Route, Link, useLocation } from "react-router-dom";
import { Backdrop } from "@mui/material";
import ManageUsers from "../components/Users/ManageUsers";
//import { Link } from "react-router-dom";
//import { useNavigate } from 'react-router-dom';

//import { apiBaseUrl, webBaseUrl } from '../common';

export const UsersContext = createContext(null);

const Users = () => {
    //const navigate = useNavigate(null);
    const _role = localStorage.getItem("_role");
    const comp = useLocation();
    let compseg = comp.pathname.split('/')[2];
    compseg = (compseg === 'e-tradie') ? 'Electrician-Tradie' : (compseg === 'p-tradie') ? 'Plumber Tradie' : compseg;
    const compTitle = compseg ? compseg.replace('-', ' ') : ((_role === 'SYS') ? 'Admin Staffs' : (_role === 'ADMSTF') ? 'Agents' : 'Land Lords');
    const [backdropopen, setBackDropOpen] = useState(false);

    useEffect(() => {
        // eslint-disable-next-line   
        const _token = localStorage.getItem("_token");

    }, []);

    const handleClose = (event, reason) => {
        //if (reason && reason === "backdropClick") return false;

        setBackDropOpen(false);
    };

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdropopen}
            >
                {/*<CircularProgress color="inherit" />*/}
                <div className="circle bg-theme">
                    Data is loading.<br />
                    It will take a moment.<br />
                    <div className="loaderDots">Please wait</div>
                </div>
            </Backdrop>
            <SiteHeader menu='users' />
            <div className="container-fluid" >
                <div className="row">
                    {(_role === 'SYS' || _role === 'ADMSTF') && (
                        <div className="col-lg-2 d-none d-lg-inline-block sidebar">

                            <nav className="sidebar-menu">
                                <ul>
                                    {_role !== 'ADMSTF' && (
                                        <li>
                                            <Link to="/users/admin-staffs" className={compseg === 'admin-staffs' ? 'active' : ''}>Admin Staffs</Link>
                                        </li>
                                    )}
                                    <li>
                                        <Link to="/users/agents" className={compseg === 'agents' ? 'active' : ''}>Agents</Link>
                                    </li>
                                    <li>
                                        <Link to="/users/land-lords" className={compseg === 'land-lords' ? 'active' : ''}>Land Lords</Link>
                                    </li>
                                    <li>
                                        <Link to="/users/e-tradie" className={compseg === 'Electrician-Tradie' ? 'active' : ''}>Electrician Tradie</Link>
                                    </li>
                                    <li>
                                        <Link to="/users/p-tradie" className={compseg === 'Plumber Tradie' ? 'active' : ''}>Plumber Tradie</Link>
                                    </li>
                                </ul>
                            </nav>

                        </div>
                    )}
                    <div className={((_role === 'SYS' || _role === 'ADMSTF') ? "col-md-12 col-lg-10" : "col-12") + " user-panel"}>
                        <div className="title">{(_role === 'SYS') ? 'Users' : 'Land Lords'}{(_role === 'SYS' || _role === 'ADMSTF') && (<><i className="fa fa-chevron-right"></i><span>{compTitle}</span></>)}</div>
                        <UsersContext.Provider value={{ backdropopen, setBackDropOpen, handleClose }}>
                            <Routes>
                                <Route basename={(_role === 'SYS' || _role === 'ADMSTF') ? '/users' : '/land-lords'}>
                                    <Route path='/' element={<ManageUsers type={(_role === 'SYS') ? 'ADMSTF' : ((_role === 'ADMSTF') ? 'AGENT' : 'LDLORD')} />} />
                                    <Route path='/admin-staffs' element={<ManageUsers type="ADMSTF" />} />
                                    <Route path="/agents" element={<ManageUsers type="AGENT" />} />
                                    <Route path="/land-lords" element={<ManageUsers type="LDLORD" />} />
                                    {/*<Route path="/tenants" element={<ManageUsers type="TENANT" />} />*/}
                                    <Route path="/e-tradie" element={<ManageUsers type="E-TRADIE" />} />
                                    <Route path="/p-tradie" element={<ManageUsers type="P-TRADIE" />} />
                                </Route>
                            </Routes>
                        </UsersContext.Provider>

                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Users;