import { useState, useEffect, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { Box, Button, TextField, Alert, IconButton, Collapse, Autocomplete, Checkbox, Chip } from "@mui/material";
//, FormControl, InputLabel, Select, MenuItem
import CloseIcon from '@mui/icons-material/Close';
import { apiBaseUrl } from '../../common';
import axios from 'axios';
import { AccountContext } from "../../pages/MyAccount";
import { PrefixData } from "../../services/PrefixData";
import { PostcodeData } from '../../services/PostcodeData';
import { UserData } from '../../services/UsersData';
import { AppContext } from '../../pages/Main';
import { useNavigate } from 'react-router-dom';
import { PatternFormat } from 'react-number-format';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

//let prefixOptions = [];
//let postcodeOptions = [];

const Profile = () => {
    const _token = localStorage.getItem("_token");
    const _role = localStorage.getItem("_role");
    let _profile_id = localStorage.getItem("_profile_id");
    axios.defaults.withCredentials = true;
    axios.defaults.headers['Accept'] = 'application/json';
    axios.defaults.headers['Content-Type'] = 'application/json';
    axios.defaults.headers['Authorization'] = 'Bearer ' + _token;

    const [formField, setFormField] = useState({
        prefix: "",
        firstname: "",
        lastname: "",
        mobile: "",
        email: "",
        address1: "",
        address2: "",
        suburb: "",
        state: "",
        postcode: ""
    });

    const { register, control, handleSubmit, formState: { errors }, reset } = useForm();

    const navigate = useNavigate(null);
    const [loader, setLoader] = useState(false);
    //const [backdropopen, setBackDropOpen] = useState(false);
    const [alertStatus, setAlertStatus] = useState('success');
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    const { handleLogout } = useContext(AppContext);
    const { setBackDropOpen } = useContext(AccountContext);

    const [pcodeValue, setPcodeValue] = useState([]);
    //const [pcodeInput, setPcodeInput] = useState('');

    const [showProfileForm, setShowProfileForm] = useState(false);
    const [prefixOptions, setPrefixOptions] = useState(null);
    const [postcodeOptions, setPostcodeOptions] = useState(null);
    const [userOptions, setUserOptions] = useState(null);
    //let inputRef;

    const singleData = () => {
        PostcodeData.fetchData().then(postCodeDataList => {
            setPostcodeOptions(postCodeDataList);
            PrefixData.fetchData().then(prefixDataList => {
                setPrefixOptions(prefixDataList);
                UserData.fetchData().then(userDataList => {
                    setUserOptions(userDataList);
                    axios.get(apiBaseUrl + 'individuals/' + _profile_id)
                        .then(response => response.data).then(resData => {
                            if (resData.status === 'success') {
                                setShowProfileForm(true);
                                resData.data.email = resData.data.individualuser.email;
                                resData.data.mobile = resData.data.individualuser.mobile;
                                setFormField(resData.data);
                                setPcodeValue({ code: resData.data.postcode, suburb: resData.data.suburb, state_shortname: resData.data.state });
                                //setPcodeInput(resData.data.suburb);
                                reset(resData.data);
                                setBackDropOpen(false);
                            } else {
                                setAlertOpen(true);
                                setAlertStatus('error');
                                setAlertMessage(resData.message);
                                handleAutoClose();
                                setBackDropOpen(false);
                            }

                        }).catch((error) => {
                            console.log(error);
                            if (error.response.status === 401) {
                                handleLogout();
                                navigate('/login');
                            }

                            setAlertOpen(true);
                            setAlertStatus('error');
                            setAlertMessage(error.message);
                            handleAutoClose();
                            setBackDropOpen(false);

                        });
                });
            });
        });
    }


    useEffect(() => {
        setBackDropOpen(true);

        singleData();

        //if (inputRef) inputRef.focus();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //console.log(prefixOptions);

    const getSelectedItem = (prefix) => {
        // eslint-disable-next-line
        const item = prefixOptions.find((opt) => {
            if (opt && opt.description === prefix)
                return opt;
        })
        return item || null;
    }

    //prefixOptions.map((prefOption) => console.log(prefOption.description)); 



    /*const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
     const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    }; */

    const getSelectedAgents = (agents) => {
        // return  [agentData[0], agentData[5]];
        let agentItems = [];
        if (agents) {
            let i = 0;
            // eslint-disable-next-line   
            userOptions.filter(a => a.code === "AGENT").find((opt) => {
                // eslint-disable-next-line 
                agents.map((item) => {
                    if (opt.id === item) {
                        agentItems[i] = opt;
                        i++;
                    }
                });
            });
        }
        //console.log(agentItems);

        return agentItems || [];
    }

    const onSubmit = (data) => {
        // console.log(data);

        setLoader(true);
        if (data.email) {

            axios.defaults.withCredentials = true;
            axios.defaults.headers['Accept'] = 'application/json';
            axios.defaults.headers['Content-Type'] = 'application/json';
            axios.defaults.headers['Authorization'] = 'Bearer ' + _token;

            var axiosAPI = '';

            data.agents = formField.agents;

            if (_profile_id) { //First time
                data._method = 'PUT';
                axiosAPI = axios.post(apiBaseUrl + 'individuals/' + _profile_id, data);
            } else {
                axiosAPI = axios.post(apiBaseUrl + 'individuals', data);
            }

            axiosAPI.then(response => response.data).then(resData => {
                setAlertOpen(true);
                if (resData.status === 'success') {
                    if (!_profile_id) {
                        localStorage.setItem('_profile_id', resData.item.id);
                        _profile_id = resData.item.id;
                    }
                    setAlertStatus('success');
                } else {
                    setAlertStatus('error');
                }
                setAlertMessage(resData.message);
                handleAutoClose();
                setLoader(false);
            }).catch((error) => {
                console.log(error);
                setLoader(false);
                setAlertOpen(true);
                setAlertStatus('error');
                setAlertMessage('Something went wrong with Individual API. Error: ' + error.message);
                handleAutoClose();
            });

        }
    };

    const handleAutoClose = () => {
        window.scrollTo(0, 0);
        setTimeout(() => setAlertOpen(false), 5000);
    };

    const handleMobileChange = (e) => {
        if (e.target.value.match("(_+)") === null) {
            setFormField((prev) => ({ ...prev, mobile: e.target.value }))
            return e.target.value;
        } else return '';
    }

    return (
        <>

            <Collapse in={alertOpen}>
                <Alert variant="filled" severity={alertStatus}
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setAlertOpen(false);
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    sx={{ mb: 2 }}
                >
                    {alertMessage}
                </Alert>
            </Collapse>

            {showProfileForm && (

                <Box component="form" id="profile-form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-lg-6">
                                <h5 className="mb-3" style={{ textAlign: 'left' }}>Personal Info:</h5>
                                <Controller
                                    name="prefix"
                                    control={control}
                                    rules={{
                                        required: "Prefix is required"
                                    }}
                                    render={({ field, fieldState }) => (
                                        <Autocomplete
                                            disablePortal
                                            id="prefix"
                                            options={prefixOptions}
                                            getOptionLabel={(option) => option.description}

                                            sx={{ width: '100%' }}
                                            renderOption={(props, option) => (
                                                <Box component="li" {...props} key={option.id}>
                                                    {option.description}
                                                </Box>
                                            )}
                                            isOptionEqualToValue={(options, value) => options.valueOf === value.valueOf}
                                            renderInput={(params) => <TextField {...params} label="Prefix" variant="outlined"
                                                error={!!fieldState.error}
                                                helperText={fieldState.error?.message}
                                            /* inputRef={input => {
                                                inputRef = input;
                                            }} */
                                            />}
                                            onChange={(_, data) => {
                                                field.onChange(data ? data.description : '')
                                                setFormField((prev) => ({ ...prev, prefix: data ? data.description : '' }))
                                            }}

                                            value={getSelectedItem(formField.prefix)}
                                        />
                                    )}
                                />

                                <TextField fullWidth id="firstname" name="firstname" variant="outlined" error={errors.firstname ? true : false} label="First Name *" helperText={errors.firstname?.message} {...register("firstname", { required: "First Name is required" })} inputProps={{ maxLength: 40, value: formField.firstname }} onChange={(e) => { setFormField((prev) => ({ ...prev, firstname: e.target.value })) }} />

                                <TextField fullWidth id="lastname" name="lastname" variant="outlined" error={errors.lastname ? true : false} label="Last Name *" helperText={errors.lastname?.message} {...register("lastname", { required: "Last Name is required" })} inputProps={{ maxLength: 40, value: formField.lastname }} onChange={(e) => { setFormField((prev) => ({ ...prev, lastname: e.target.value })) }} />

                                <TextField fullWidth id="email" name="email" variant="outlined" error={errors.email ? true : false} label="Email Address *" helperText={errors.email?.message} {...register("email", {
                                    required: "Email Address is required", pattern: {
                                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        message: 'Please enter a valid email',
                                    }
                                })} inputProps={{ maxLength: 40, value: formField.email }} onChange={(e) => { setFormField((prev) => ({ ...prev, email: e.target.value })) }} />

                                <Controller
                                    name="mobile"
                                    control={control}

                                    rules={{
                                        required: "A valid mobile number is required",
                                        pattern: {
                                            value: /^04\d{8}$/,
                                            message: 'Please enter a valid mobile number',
                                        }
                                    }}

                                    render={({ field, fieldState }) => (

                                        <PatternFormat
                                            label="Mobile *"
                                            customInput={TextField}
                                            type="tel"
                                            format="04########"
                                            allowEmptyFormatting
                                            fullWidth
                                            mask="_"
                                            error={!!fieldState.error}
                                            helperText={fieldState.error?.message}
                                            value={formField.mobile}
                                            onChange={(e) => { field.onChange(handleMobileChange(e)) }}
                                        />
                                    )}
                                />
                                {_role === 'LDLORD' && (
                                    <Controller
                                        render={(props) => (
                                            <Autocomplete
                                                multiple
                                                disableClearable
                                                disableCloseOnSelect
                                                options={userOptions.filter(a => a.code === "AGENT")}
                                                getOptionLabel={(option) => option.label}
                                                value={getSelectedAgents(formField.agents)}
                                                // filterOptions={filterOptions}
                                                renderOption={(args, option, { selected }) => (
                                                    <Box component="li" {...args} key={option.id}>
                                                        <Checkbox
                                                            icon={icon}
                                                            checkedIcon={checkedIcon}
                                                            style={{ marginRight: 8 }}
                                                            checked={selected}
                                                        />
                                                        {option.label}
                                                    </Box>
                                                )}
                                                renderTags={(tagValue, getTagProps) => {
                                                    return tagValue.map((option, index) => (
                                                        <Chip {...getTagProps({ index })} key={option.id} label={option.label} />
                                                    ))
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Agents"
                                                        variant="outlined"
                                                        placeholder="choose agents"
                                                    />
                                                )}
                                                onChange={(_, data) => {
                                                    props.field.onChange(data.id);
                                                    let agentArr = [];
                                                    data.map((item, index) => agentArr[index] = item.id);
                                                    //console.log(agentArr);
                                                    setFormField((prev) => ({ ...prev, agents: agentArr }));
                                                }}

                                            />
                                        )}


                                        name="agents[]"
                                        control={control}
                                    />
                                )}
                                {_role === 'AGENT' && (<>
                                    <TextField
                                        fullWidth
                                        id="agency_name" name="agency_name" variant="outlined" error={errors.agency_name ? true : false} label="Agency Name *"
                                        helperText={errors.agency_name?.message}

                                        inputProps={{ maxLength: 100, value: formField.agency_name }}

                                        {...register("agency_name", { required: "Agency Name is required" })}

                                        onChange={(e) => setFormField((prev) => ({ ...prev, agency_name: e.target.value }))}

                                    />
                                </>)}
                            </div>
                            <div className="col-md-12 col-lg-6">
                                <h5 className="mb-3" style={{ textAlign: 'left' }}>Address Info:</h5>
                                <TextField fullWidth id="address1" name="address1" variant="outlined" error={errors.email ? true : false} label="Address 1 *" helperText={errors.address1?.message} {...register("address1", { required: "Address 1 is required" })} inputProps={{ maxLength: 40, value: formField.address1 }} onChange={(e) => { setFormField((prev) => ({ ...prev, address1: e.target.value })) }} />

                                <TextField fullWidth id="address2" name="address2" variant="outlined" label="Address 2" inputProps={{ maxLength: 40, value: (formField.address2 ? formField.address2 : '') }} {...register("address2")} onChange={(e) => { setFormField((prev) => ({ ...prev, address2: e.target.value })) }} />

                                {/*} <TextField fullWidth id="suburb" name="suburb" variant="outlined" label="Suburb *"  inputProps={{ maxLength: 40, value: (formField.suburb ? formField.suburb : '') }} {...register("suburb")} onChange={(e) => { setFormField({ ...prev, suburb: e.target.value })) }} /> */}

                                <Autocomplete
                                    sx={{ width: '100%' }}
                                    id="suburb"
                                    name="suburb"
                                    value={pcodeValue}
                                    onChange={(event, newValue) => {
                                        if (newValue) {
                                            setPcodeValue({ code: newValue.code, suburb: newValue.suburb, state_shortname: newValue.state_shortname });
                                            setFormField((prev) => ({ ...prev, suburb: newValue?.suburb, postcode: newValue?.code, state: newValue?.state_shortname }));
                                            //setPcodeInput(newValue.suburb);
                                        } else {
                                            setPcodeValue({ code: '', suburb: '', state_shortname: '' });
                                            setFormField((prev) => ({ ...prev, suburb: '', state: '', postcode: '' }));
                                        }
                                    }}
                                    /* inputValue={pcodeInput}
                                    onInputChange={(event, newInputValue, eventType) => {
                                        if (eventType === 'reset') {
                                            setPcodeInput(pcodeInput);
                                        } else {
                                            setPcodeInput(newInputValue);
                                        }
                                    }} */

                                    getOptionLabel={(option) => option.suburb ? option.suburb : ''}

                                    options={postcodeOptions}

                                    renderOption={(props, option) => (
                                        <Box component="li" {...props} key={option.id}>
                                            {option.suburb} - {option.code}, {option.state_shortname}.
                                        </Box>
                                    )}

                                    //isOptionEqualToValue={(options, value) => options.valueOf === value.valueOf}

                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Search Suburb *"
                                            error={errors.suburb ? true : false}
                                            helperText={errors.suburb?.message}
                                            {...register("suburb", { required: "Suburb is required" })}
                                        />
                                    )}
                                />

                                <TextField fullWidth id="state" name="state" variant="outlined" error={errors.state ? true : false} label="State *" helperText={errors.state?.message} {...register("state", { required: "State is required" })} inputProps={{ maxLength: 4, value: formField.state }} onChange={(e) => { setFormField((prev) => ({ ...prev, state: e.target.value })) }} />


                                <TextField fullWidth id="postcode" name="postcode" variant="outlined" error={errors.postcode ? true : false} label="Postcode *" helperText={errors.postcode?.message} {...register("postcode", { required: "Postal Code is required" })} inputProps={{ maxLength: 4, value: formField.postcode }} onChange={(e) => { setFormField((prev) => ({ ...prev, postcode: e.target.value })) }} />
                            </div>
                        </div>
                        <Button type="submit" className="themeholy-btn btn-primary" variant="contained" disabled={loader ? true : false}>{(loader) ? 'Updating...' : 'Update Profile'}</Button>
                    </div>
                </Box>
            )}
        </>

    );
}

export default Profile;