import { useState } from "react";
import SiteHeader from "../components/SiteHeader";
import Footer from "../components/Footer";
import { useNavigate } from 'react-router-dom';
import { apiBaseUrl } from '../common';
//import breadCrumbBg from '../assets/img/bg/breadcumb-bg.jpg';
import { useForm } from "react-hook-form";
import axios from 'axios';
import { Alert, IconButton, Collapse } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const Login = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate(null);
    const [alertStatus, setAlertStatus] = useState('success');
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    /* useEffect(() => {
        const _token = localStorage.getItem("_token");
        if (_token) navigate('/dashboard');

    }, [navigate]); */

    const onSubmit = (data) => {
        setLoader(true);
        setAlertOpen(false);
        setAlertStatus('success');
        setAlertMessage('');
        if (data.email && data.password) {

            const user = {
                email: data.email,
                password: data.password
            };
            // axios.defaults.withCredentials = true;
            //axios.get(apiBaseUrl + 'sanctum/csrf-cookie').then(res => res.data).then(csrf => {                  
            //axios.defaults.headers['Access-Control-Allow-Origin'] = '*';
            //axios.defaults.withCredentials = true; 
            axios.defaults.headers['Accept'] = 'application/json';
            axios.defaults.headers['Content-Type'] = 'application/json';
            //axios.defaults.headers['X-CSRF-Token'] = csrf;
            axios.post(apiBaseUrl + 'auth/login', user)
                .then(response => response.data).then(authData => {
                    setAlertOpen(true);
                    if (authData.status === 'success') {
                        setAlertStatus('success');
                        localStorage.setItem('_profile_id', authData.data.profile_id);
                        localStorage.setItem('_role', authData.data.role);
                        localStorage.setItem('_token', authData.data.access_token);
                        localStorage.setItem('_token_expires', authData.data.expires_in);
                        const _role = localStorage.getItem("_role");
                        if (_role !== 'LDLORD' && _role !== 'AGENT') {
                            navigate('/dashboard');
                        } else if (_role === 'AGENT') {
                            navigate('/land-lords');
                        } else {
                            navigate('/properties');
                        }

                    } else {
                        setAlertStatus('error');
                    }
                    setAlertMessage(authData.message);
                    handleAutoClose();
                    setLoader(false);
                }).catch((error) => {
                    console.log(error);
                    setLoader(false);
                    setAlertOpen(true);
                    setAlertStatus('error');
                    setAlertMessage('Something went wrong with Login API. Error: ' + error.message);
                    handleAutoClose();
                });
            /* }).catch((error) => {
                console.log(error.message);
                setLoader(false);
                setAlertOpen(true);
                setAlertStatus('error');
                setAlertMessage('Something went wrong with Login API. Error: ' + error.message);
                handleAutoClose();
            }); */

        }
    };

    const handleAutoClose = () => {
        setTimeout(() => setAlertOpen(false), 5000);
    };

    return (
        <>
            <SiteHeader menu='login' />

            {/* <div className="breadcumb-wrapper background-image" style={{ backgroundImage: `url('${breadCrumbBg}')` }}>
                <div className="container">
                    <div className="breadcumb-content">
                        <h1 className="breadcumb-title">Login</h1>
                        <ul className="breadcumb-menu">
                            <li><Link to={webBaseUrl}>Home</Link></li>
                            <li>Login</li>
                        </ul>
                    </div>
                </div>
            </div> */}
            <div className="space-bottom">
                <div className="container">
                    <div className="bg-smoke bg-bottom-right">
                        <div className="row">
                            <div className="col-xl-6 m-auto">
                                <div className="quote-form-box bg-transparent">
                                    <h4 className="form-title text-center">Login</h4>
                                    <Collapse in={alertOpen}>
                                        <Alert variant="filled" severity={alertStatus}
                                            action={
                                                <IconButton
                                                    aria-label="close"
                                                    color="inherit"
                                                    size="small"
                                                    onClick={() => {
                                                        setAlertOpen(false);
                                                    }}
                                                >
                                                    <CloseIcon fontSize="inherit" />
                                                </IconButton>
                                            }
                                            sx={{ mb: 2 }}
                                        >
                                            {alertMessage}
                                        </Alert>
                                    </Collapse>
                                    <form method="POST" className="contact-form ajax-contact" id="frmLogin" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                                        <div className="row">
                                            <div className="form-group col-md-12">
                                                <input type="text" autoFocus className={"form-control " + (errors.email ? 'is-invalid' : '')} placeholder="Email Address or Mobile Number" autoComplete="off" {...register("email", { required: "Email Address or Mobile number is required" })} />
                                                <label className="w-full error text-red text-sm text-start">{errors.email?.message}</label>
                                            </div>

                                            <div className="form-group col-md-12">
                                                <input type="password" className={"form-control " + (errors.password ? 'is-invalid' : '')} placeholder="Password" autoComplete="off" {...register("password", { required: "Password is required" })} />
                                                <label className="w-full error text-red text-sm text-start">{errors.password?.message}</label>
                                            </div>

                                            <div className="form-btn col-12"><button className="themeholy-btn btn-fw" disabled={(loader) ? 'disabled' : ''}>{(loader) ? 'Processing...' : 'Login'}</button></div>
                                            { /* <div className="form-btn col-12" style={{ 'marginTop': '30px', 'textAlign': 'center' }}><Link to="/register" style={{ 'color': 'blue' }}>New user? Create an account</Link></div> */}
                                        </div>
                                        <p className="form-messages mb-0 mt-3"></p>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Login;