import { useEffect, useContext } from 'react';
import { useForm } from "react-hook-form";
import dayjs from 'dayjs';
import { Button, FormControl, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Box, InputLabel, Select, MenuItem } from '@mui/material';
import { FormContext } from "./ManageJobs";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const InvoiceForm = () => {

    const _role = localStorage.getItem("_role");

    const { loaderText, invoiceOpen, handleInvoiceClose, formField, setFormField, onInvoiceSubmit } = useContext(FormContext);

    const actionFun = formField.action;

    const { reset, handleSubmit, formState: { errors }, register } = useForm();

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };


    useEffect(() => {

        //if(formField.property_id){        
        reset(formField);
        //} 

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formField]);


    return (
        <Dialog
            fullWidth
            open={invoiceOpen}
            onClose={handleInvoiceClose}
            scroll='body'
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"

        >
            <DialogTitle id="scroll-invoice-dialog">{actionFun && actionFun[0].toUpperCase() + actionFun.slice(1)} Invoice</DialogTitle>
            <Box component="form" id="invoice-form" name="invoice-form" noValidate autoComplete="off" onSubmit={handleSubmit(onInvoiceSubmit)}>

                <DialogContent dividers={false}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            disablePast
                            label="Invoice Date"
                            id="invoice_date"
                            name="invoice_date"
                            format="DD/MM/YYYY"
                            value={dayjs(formField.invoice_date)}
                            onChange={(newValue) => { setFormField((prev) => ({ ...prev, invoice_date: newValue })) }}
                            readOnly={(_role === 'SYS' || _role === 'ADMSTF') ? false : true}
                            error={errors.invoice_date ? true : false}
                            helperText={errors.invoice_date?.message}
                        />
                    </LocalizationProvider>
                    <label className="w-full error fs-xs text-start">{errors.invoice_date?.message}</label>
                    <textarea
                        id="description"
                        name="description"
                        rows={4}
                        className='mt-3'
                        style={{ backgroundColor: '#ffffff', border: '1px solid #ccc', borderRadius: '4px', width: '100%' }}
                        placeholder="Description"

                        {...register("description", { required: false })}
                        onChange={(e) => setFormField((prev) => ({ ...prev, description: e.target.value }))}

                        defaultValue={formField.description}

                    />
                    <TextField
                        fullWidth
                        id="amount" name="amount"
                        variant="outlined"
                        label="Amount"
                        {...register("amount", { required: "Amount is required" })}
                        error={errors.amount ? true : false}
                        helperText={errors.amount?.message}
                        inputProps={{ value: formField.amount ? formField.amount : 0 }}

                        onChange={(e) => setFormField((prev) => ({ ...prev, amount: e.target.value }))}
                    />
                    <label className="w-full error fs-xs text-start">{errors.amount?.message}</label>
                    <TextField
                        fullWidth
                        className='mt-3'
                        id="received" name="received"
                        variant="outlined"
                        label="Received"
                        {...register("received", { required: false })}
                        error={errors.received ? true : false}
                        helperText={errors.received?.message}
                        inputProps={{ value: formField.received ? formField.received : 0 }}

                        onChange={(e) => {
                            setFormField((prev) => ({ ...prev, received: e.target.value, balance: (parseFloat(formField.amount) - parseFloat(e.target.value)) }))
                        }
                        }
                    />
                    <label className="w-full error fs-xs text-start">{errors.received?.message}</label>
                    <TextField
                        fullWidth
                        className='mt-3'
                        id="balance" name="balance"
                        variant="outlined"
                        label="Balance"
                        {...register("balance", { required: false })}
                        inputProps={{ value: formField.balance ? formField.balance : 0, readOnly: true }}

                        onChange={(e) => setFormField((prev) => ({ ...prev, balance: e.target.value }))}
                    />
                    <FormControl sx={{ width: '100%' }} className="mt-3" >
                        <InputLabel id="status-select-label"> Select Paid Status </InputLabel>
                        <Select
                            labelId="status-select-label"
                            id="paid_status"
                            name="paid_status"
                            error={errors.status ? true : false}
                            value={formField.paid_status}
                            label="Select Paid Status"
                            {...register("paid_status", { required: "Paid Status is required" })}
                            onChange={(e) => setFormField((prev) => ({ ...prev, paid_status: e.target.value }))}
                            MenuProps={MenuProps}
                        >
                            <MenuItem value='Y'>Paid</MenuItem>
                            <MenuItem value='N'>Unpaid</MenuItem>
                        </Select>
                    </FormControl>
                    <label className="w-full error fs-xs text-start">{errors.paid_status?.message}</label>
                </DialogContent>
                <DialogActions className='p-4'>
                    <Button type="button" className="themeholy-btn btn-normal" onClick={handleInvoiceClose}>Cancel</Button>
                    <Button type="submit" className="themeholy-btn btn-primary" disabled={loaderText}>{loaderText ? 'Submitting' : 'Submit'}</Button>
                </DialogActions>
            </Box>
        </Dialog>
    );

}

export default InvoiceForm;