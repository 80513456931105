import { useState, useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../assets/img/logo-main.png';
import Logo1 from '../assets/img/logo_new1.png';
import { webBaseUrl } from '../common';
import { AppContext } from '../pages/Main';
import { useNavigate } from 'react-router-dom';

const SiteHeader = (props) => {
    const navigate = useNavigate(null);
    const _token = localStorage.getItem("_token");
    const _role = localStorage.getItem("_role");
    const [showHeaderMenu, setShowHeaderMenu] = useState('hide');
    const [showMenu, setShowMenu] = useState('');
    //const [activeSubMenu, setActiveSubMenu] = useState('');
    const [showUsersSubMenu, setShowUsersSubMenu] = useState('themeholy-submenu');
    const [showSettingsSubMenu, setshowSettingsSubMenu] = useState('themeholy-submenu');

    const { handleLogout } = useContext(AppContext);

    const uTypeArr = { 'SYS': 'Admin', 'ADMSTF': 'Admin Staff', 'AGENT': 'Agent', 'LDLORD': 'Land Lord', 'TENANT': 'Tenant', 'E-TRADIE': 'Electrician Tradie', 'P-TRADIE': 'Plumber Tradie' }
    const userType = uTypeArr[_role];
    const comp = useLocation();
    let compseg = comp.pathname.split('/')[2];

    const [usersAttr, setUsersAttr] = useState({
        className: 'menu-item-has-children themeholy-item-has-children',
        onClick: () => handleSubMenuToggle(0)
    });

    const [settingsAttr, setSettingsAttr] = useState({
        className: 'menu-item-has-children themeholy-item-has-children',
        onClick: () => handleSubMenuToggle(1)
    });

    useEffect(() => {
        if (_token) {
            if (props.menu === 'login' || props.menu === 'LDLORD' || props.menu === 'AGENT') {
                navigate('/dashboard');
            }
        } else if (props.menu !== 'login' && props.menu !== 'LDLORD' && props.menu !== 'AGENT') {
            navigate('/login');
        }
        if (_token === null) {
            setShowHeaderMenu('');
        }

        setShowMenu('');

        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [_token, compseg]);

    const handleMenuToggle = (cls) => {
        setShowMenu(cls);
        if (cls) {
            if (comp.pathname.split('/')[1] === 'users') {
                usersAttr.className = 'menu-item-has-children themeholy-item-has-children themeholy-active';
                setUsersAttr(usersAttr);
                setShowUsersSubMenu('');
            } else if (comp.pathname.split('/')[1] === 'my-account') {
                settingsAttr.className = 'menu-item-has-children themeholy-item-has-children themeholy-active';
                setSettingsAttr(settingsAttr);
                setshowSettingsSubMenu('');
            }
        }
    }
    const handleSubMenuToggle = (i) => {
        if (i === 0) {
            /* if (activeSubMenu === 'themeholy-active') {
                setActiveSubMenu('');
                setShowSubMenu('themeholy-submenu');
            } else {
                setActiveSubMenu('themeholy-active');
                setShowSubMenu('');
            } */

            if (usersAttr.className === 'menu-item-has-children themeholy-item-has-children themeholy-active') {
                usersAttr.className = 'menu-item-has-children themeholy-item-has-children';
                setUsersAttr(usersAttr);
                setShowUsersSubMenu('themeholy-submenu');
            } else {
                usersAttr.className = 'menu-item-has-children themeholy-item-has-children themeholy-active'
                setUsersAttr(usersAttr);
                setShowUsersSubMenu('');
            }
        } else {
            if (settingsAttr.className === 'menu-item-has-children themeholy-item-has-children themeholy-active') {
                settingsAttr.className = 'menu-item-has-children themeholy-item-has-children';
                setSettingsAttr(settingsAttr);
                setshowSettingsSubMenu('themeholy-submenu');
            } else {
                settingsAttr.className = 'menu-item-has-children themeholy-item-has-children themeholy-active'
                setSettingsAttr(settingsAttr);
                setshowSettingsSubMenu('');
            }
        }
    }

    return (
        <>
            <div className={"themeholy-menu-wrapper " + showMenu}>
                <div className="themeholy-menu-area text-center">
                    <button className="themeholy-menu-toggle" onClick={() => handleMenuToggle('')}><i className="fal fa-times"></i></button>
                    <div className="mobile-logo"><Link to="index.html"><img src={Logo1} alt="Safety Ninja" /></Link></div>
                    <div className="themeholy-mobile-menu">
                        <ul>
                            {!showHeaderMenu ? (
                                <>
                                    <li>
                                        <Link to="index.html" >Home</Link>
                                    </li>
                                    <li><Link to="our-services.html">Our Services</Link></li>
                                    <li><Link to="why-choose-our-services.html">Why Choose Our Service?</Link></li>
                                    <li><Link to="safety-resources.html">Safety Resources</Link></li>
                                    <li><Link to="pricing.html">Pricing</Link></li>
                                    <li className="active"><Link to="contact.html">Contact</Link></li>
                                </>
                            ) : (
                                <>
                                    {(_role !== 'LDLORD' && _role !== 'AGENT') && <li><Link to="/dashboard" className={(props.menu === 'dashboard') ? 'active' : ''}>Dashboard</Link></li>}
                                    {_role !== 'LDLORD' && _role !== 'E-TRADIE' && _role !== 'P-TRADIE' && (
                                        <li {...usersAttr}>
                                            <Link to={(_role === 'SYS' || _role === 'ADMSTF') ? '#' : '/land-lords'} className={(props.menu === 'users' || props.menu === 'land-lords') ? 'active' : ''}>{(_role === 'SYS' || _role === 'ADMSTF') ? 'Users' : 'Land Lords'}</Link>
                                            {(_role === 'SYS' || _role === 'ADMSTF') && (
                                                <ul className={showUsersSubMenu}>
                                                    {_role !== 'ADMSTF' && (
                                                        <li>
                                                            <Link to="/users/admin-staffs" className={compseg === 'admin-staffs' ? 'active' : ''}>Admin Staffs</Link>
                                                        </li>
                                                    )}
                                                    <li>
                                                        <Link to="/users/agents" className={compseg === 'agents' ? 'active' : ''}>Agents</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/users/land-lords" className={compseg === 'land-lords' ? 'active' : ''}>Land Lords</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/users/e-tradie" className={compseg === 'Electrician-Tradie' ? 'active' : ''}>Electrician Tradie</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/users/p-tradie" className={compseg === 'Plumber Tradie' ? 'active' : ''}>Plumber Tradie</Link>
                                                    </li>
                                                </ul>
                                            )}

                                        </li>
                                    )}
                                    {(!_role || (_role && (_role !== 'E-TRADIE' && _role !== 'P-TRADIE'))) && (
                                        <li><Link to="/properties" className={(props.menu === 'properties') ? 'active' : ''}>Properties</Link></li>
                                    )}
                                    <li><Link to="/jobs" className={(props.menu === 'jobs') ? 'active' : ''}>Jobs</Link></li>

                                    <li {...settingsAttr}><Link to={(_role === 'SYS' || _role === 'ADMSTF') ? '#' : '/my-account'} className={(props.menu === 'my-account') ? 'active' : ''}>{(_role === 'SYS' || _role === 'ADMSTF') ? 'Settings' : 'My Account'}</Link>

                                        {(_role === 'SYS' || _role === 'ADMSTF') && (
                                            <ul className={showSettingsSubMenu}>
                                                <li>
                                                    <Link to="/my-account/profile" className={(!compseg || compseg === 'profile') ? 'active' : ''}>Profile</Link>
                                                </li>
                                                <li>
                                                    <Link to="/my-account/change-password" className={(compseg === 'change-password') ? 'active' : ''}>Change Password</Link>
                                                </li>


                                                <li>
                                                    <Link to="/my-account/user-types" className={compseg === 'user-types' ? 'active' : ''}>User Types</Link>
                                                </li>
                                                <li>
                                                    <Link to="/my-account/prefix" className={compseg === 'prefix' ? 'active' : ''}>Prefix</Link>
                                                </li>
                                                <li>
                                                    <Link to="/my-account/postcode" className={compseg === 'postcode' ? 'active' : ''}>Postcode</Link>
                                                </li>
                                                <li>
                                                    <Link to="/my-account/job-status" className={compseg === 'job-status' ? 'active' : ''}>Job Status</Link>
                                                </li>
                                                <li>
                                                    <Link to="/my-account/email-template" className={compseg === 'email-template' ? 'active' : ''}>Email Templates</Link>
                                                </li>


                                            </ul>
                                        )}


                                    </li>

                                    <li><Link to="#" onClick={handleLogout}>Logout</Link></li>

                                </>
                            )}
                        </ul>
                    </div>
                </div >
            </div >
            <header className="themeholy-header header-layout1 header-layout2">
                <div className="header-top">
                    <div className="container themeholy-container">
                        <div className="row justify-content-center justify-content-lg-between align-items-center gy-2">
                            <div className="col-auto d-none d-lg-block">
                                <p className="header-notice">Welcome to Safety Ninja.</p>
                            </div>
                            <div className="col-auto">
                                <div className="header-links">
                                    <ul>
                                        <li className="d-none"><i className="fal fa-clock"></i>Work Time: Mon - Fri 09AM - 6PM</li>
                                        {userType && <li>{userType + ' Dashboard'}</li>}
                                        <li className={"d-none d-lg-inline-block " + (!userType ? "no-pip" : "")}>
                                            <div className="login-link">
                                                {!showHeaderMenu ? (
                                                    <>
                                                        <Link to='https://app.safetyninja.360membershipcrm.com.au/'>Login</Link>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Link to='/my-account' className={(props.menu === 'my-account') ? 'active' : ''}>My Account</Link> | <Link onClick={handleLogout} to='#'>Log Out</Link>
                                                    </>
                                                )}
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sticky-wrapper">
                    <div className="menu-area">
                        <div className="container themeholy-container">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-auto">
                                    <div className="header-logo"><Link to={webBaseUrl}><img src={Logo} alt="Safety Ninja" /></Link></div>
                                </div>
                                <div className="col">
                                    <div className={"menu-top d-none d-xl-block " + showHeaderMenu}>
                                        <div className="row justify-content-between align-items-center">
                                            <div className="col-auto">
                                                <div className="header-info">
                                                    <div className="icon-btn"><i className="fas fa-phone"></i></div>
                                                    <div className="media-body"><span className="header-info_label">Call Us Any Time:</span> <Link to="tel:1300175528" className="header-info_link">1300 175 528</Link></div>
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <div className="header-info">
                                                    <div className="icon-btn"><i className="fas fa-messages"></i></div>
                                                    <div className="media-body"><span className="header-info_label">Email Us: </span><Link to="mailto:info@safetyninja.com.au" className="header-info_link">info@SafetyNinja.com.au</Link></div>
                                                </div>
                                            </div>
                                            {/* <!--<div className="col-auto">-->
                                                <!--   <div className="header-info">-->
                                                    <!--      <div className="icon-btn"><i className="fas fa-location-dot"></i></div>-->
                                                    <!--      <div className="media-body"><span className="header-info_label">Our Office Location: </span><Link to="https://maps.app.goo.gl/HFa1iXB3SBTLgUju8" className="header-info_link">11/17 Baretta Road, Ravenhall, VIC - 3023.</Link></div>-->
                                                    <!--   </div>-->
                                                <!--</div>-->  d-none d-xxl-block */}
                                            <div className="col-auto"><Link to={webBaseUrl + "contact.html"} className="themeholy-btn style4">Get a Quote</Link></div>
                                        </div>
                                    </div>
                                    <div className="menu-wrap">
                                        <div className="row">
                                            <div className="col text-end text-xl-start">
                                                <nav className="main-menu d-none d-lg-inline-block">
                                                    <ul>
                                                        {!showHeaderMenu ? (
                                                            <>
                                                                <li>
                                                                    <Link to={webBaseUrl}>Home</Link>
                                                                </li>
                                                                <li><Link to={webBaseUrl + "our-services.html"}>Our Services</Link></li>
                                                                <li><Link to={webBaseUrl + "why-choose-our-services.html"}>Why Choose Our Service?</Link></li>
                                                                <li><Link to={webBaseUrl + "safety-resources.html"}>Safety Resources</Link></li>
                                                                <li><Link to={webBaseUrl + "pricing.html"}>Pricing</Link></li>
                                                                <li ><Link to={webBaseUrl + "contact.html"} className="active">Contact</Link></li>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {(_role !== 'LDLORD' && _role !== 'AGENT') && <li><Link to="/dashboard" className={(props.menu === 'dashboard') ? 'active' : ''}>Dashboard</Link></li>}
                                                                {_role !== 'LDLORD' && _role !== 'E-TRADIE' && _role !== 'P-TRADIE' && (
                                                                    <li><Link to={(_role === 'SYS' || _role === 'ADMSTF') ? '/users' : '/land-lords'} className={(props.menu === 'users' || props.menu === 'land-lords') ? 'active' : ''}>{(_role === 'SYS' || _role === 'ADMSTF') ? 'Users' : 'Land Lords'}</Link></li>
                                                                )}
                                                                {(!_role || (_role && (_role !== 'E-TRADIE' && _role !== 'P-TRADIE'))) && (
                                                                    <li><Link to="/properties" className={(props.menu === 'properties') ? 'active' : ''}>Properties</Link></li>
                                                                )}
                                                                <li><Link to="/jobs" className={(props.menu === 'jobs') ? 'active' : ''}>Jobs</Link></li>
                                                                {(_role === 'SYS' || _role === 'ADMSTF') && (
                                                                    <li><Link to="/my-account" className={(props.menu === 'my-account') ? 'active' : ''}>Settings</Link></li>
                                                                )}
                                                            </>
                                                        )}
                                                    </ul>
                                                </nav>
                                                <button type="button" className="themeholy-menu-toggle d-block d-lg-none" onClick={() => handleMenuToggle('themeholy-body-visible')}><i className="far fa-bars"></i></button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="logo-bg"></div>
                    </div>
                </div>
            </header>
        </>
    );
}

export default SiteHeader;