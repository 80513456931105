import React from "react";

function NoPage(){
    return(
        <h1>
            OOPS! Page Not Found
        </h1>
    );    
}

export default NoPage;