import { useEffect, useContext, forwardRef, useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { apiBaseUrl } from '../../common';
import axios from 'axios';
import dayjs from 'dayjs';
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Slide, Box, InputLabel, Select, MenuItem,
    CircularProgress, Typography, Fade, IconButton,
    Table, TableBody, TableCell, TableHead, TableRow, Collapse, Popover, Alert, FormControl, TextField, RadioGroup, Radio, FormControlLabel, TextareaAutosize
} from '@mui/material';
import Switch from '@mui/material/Switch';
import CloseIcon from '@mui/icons-material/Close';
import { FormContext } from "./ManageJobs";
import { styled } from '@mui/material/styles';
import ImageUploading from "react-images-uploading";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
//import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
//import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
//import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
//import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
        borderRadius: 22 / 2,
        '&::before, &::after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
        },
        '&::before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
            left: 12,
        },
        '&::after': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M19,13H5V11H19V13Z" /></svg>')`,
            right: 12,
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 16,
        height: 16,
        margin: 2,
    },
}));

const ChecklistForm = ({ serviceId, openItems, setOpenItems, CLfetchData, handleClose }) => {

    //console.log(openItems);

    const _token = localStorage.getItem("_token");
    axios.defaults.withCredentials = true;
    axios.defaults.headers['Accept'] = 'application/json';
    axios.defaults.headers['Content-Type'] = 'application/json';
    axios.defaults.headers['Authorization'] = 'Bearer ' + _token;

    const { register, handleSubmit, formState: { errors } } = useForm();
    const [loaderText, setLoaderText] = useState(false);
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const [remAllImg, setRemAllImg] = useState('hide');
    const maxNumber = 10;

    const [alertStatus, setAlertStatus] = useState('success');
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [images, setImages] = useState(openItems ? openItems.pictures : []);

    //console.log(images);
    const onImageChange = (imageList, addUpdateIndex) => {
        // data for submit
        //console.log(imageList, addUpdateIndex);
        setImages(imageList);

        //setFormField((prev) => ({ ...prev, picture: imageList }));

        if (imageList.length > 1) {
            setRemAllImg('show');
        } else {
            setRemAllImg('hide');
        }
    }

    const handleAutoClose = () => {
        window.scrollTo(0, 0);
        setTimeout(() => setAlertOpen(false), 5000);
    }
    const handleSafeChange = (e) => {
        setOpenItems((prev) => ({ ...prev, safe: (e.target.value === 'safe' ? 'Y' : 'N'), complaint: (e.target.value === 'complaint' ? 'Y' : 'N') }))
    }

    const onSubmit = (data) => {
        setLoaderText(true);

        data.service_id = serviceId;
        data.category = openItems.category;
        data.code = openItems.code;
        data.description = openItems.description;
        data.safe = openItems.safe;
        data.complaint = openItems.complaint;
        data.pictures = images;

        if (openItems.app_num) {
            data.app_num = openItems.app_num;
        }

        // console.log(data);

        axios.post(apiBaseUrl + 'servicechecklist/update', data).then(response => response.data).then(resData => {
            setAlertOpen(true);
            if (resData.status === 'success') {
                setAlertStatus('success');
                handleClose();
                CLfetchData();
            } else {
                setAlertStatus('error');
            }
            setAlertMessage(resData.message);
            handleAutoClose();
            setLoaderText(false);
        }).catch((error) => {
            console.log(error);
            setLoaderText(false);
            setAlertOpen(true);
            setAlertStatus('error');
            setAlertMessage('Something went wrong with updateing service checklist API. Error: ' + error.message);
            handleAutoClose();
        });
    };

    if (openItems) {
        return (
            <Box component="form" id="checklist-form" name="checklist-form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <div className='bg-black p-3'><Typography className="text-white">{openItems.description}</Typography></div>

                <div className='container p-3 m-auto'>
                    <Collapse in={alertOpen}>
                        <Alert variant="filled" severity={alertStatus}
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setAlertOpen(false);
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                            sx={{ mb: 2 }}
                        >
                            {alertMessage}
                        </Alert>
                    </Collapse>

                    <FormControl fullWidth>
                        <InputLabel id={"service-type-lbl-" + openItems.id}> Select Service Type </InputLabel>
                        <Select
                            labelId={"service-type-lbl-" + openItems.id}
                            id={"service-type-" + openItems.id}
                            name="service_type"
                            value={openItems.service_type ? openItems.service_type : ''}
                            label="Select Service Type"
                            error={errors.service_type ? true : false}
                            {...register("service_type", { required: "Service Type is required" })}
                            onChange={(e) => setOpenItems((prev) => ({ ...prev, service_type: e.target.value }))}
                            MenuProps={MenuProps}
                        >
                            <MenuItem value='Complaint'>Complaint</MenuItem>
                            <MenuItem value='Disconnected'>Disconnected</MenuItem>
                            <MenuItem value='Faulty'>Faulty</MenuItem>
                            <MenuItem value='Non Complaint'>Non Complaint</MenuItem>
                            <MenuItem value='Not Tested'>Not Tested</MenuItem>
                            <MenuItem value='Recommendation'>Recommendation</MenuItem>
                            <MenuItem value='Safety Issue'>Safety Issue</MenuItem>
                        </Select>
                        <label className="w-full error fs-xs text-start">{errors.service_type?.message}</label>
                    </FormControl>
                    <TextField
                        fullWidth
                        className="mt-3"
                        id="location" name="location"
                        variant="outlined"
                        label="Location"
                        {...register("location", { required: "Location is required" })}
                        error={errors.location ? true : false}
                        helperText={errors.location?.message}
                        inputProps={{ value: openItems.location ? openItems.location : '' }}

                        onChange={(e) => setOpenItems((prev) => ({ ...prev, location: e.target.value }))}
                    />
                    <div className="mt-3">
                        <FormControl fullWidth>
                            <RadioGroup
                                row
                                aria-labelledby="safe-complaint"
                                name="safe-complaint"
                                onChange={handleSafeChange}
                            >
                                <FormControlLabel value="safe" control={<Radio />} label="Safe" />
                                <FormControlLabel value="complaint" control={<Radio />} label="Complaint" />
                            </RadioGroup>
                        </FormControl>
                    </div>
                    <TextField
                        fullWidth
                        className="mt-3"
                        id="comments" name="comments"
                        variant="outlined"
                        label="Comments"
                        {...register("comments", { required: "Comments is required" })}
                        error={errors.comments ? true : false}
                        helperText={errors.comments?.message}
                        inputProps={{ value: openItems.comments ? openItems.comments : '' }}

                        onChange={(e) => setOpenItems((prev) => ({ ...prev, comments: e.target.value }))}
                    />
                    <TextField
                        fullWidth
                        className="mt-3"
                        id="additional_notes" name="additional_notes"
                        variant="outlined"
                        label="Additional Notes"
                        {...register("additional_notes", { required: false })}
                        inputProps={{ value: openItems.additional_notes ? openItems.additional_notes : '' }}

                        onChange={(e) => setOpenItems((prev) => ({ ...prev, additional_notes: e.target.value }))}
                    />
                    <TextField
                        fullWidth
                        className="mt-3"
                        id="summary" name="summary"
                        variant="outlined"
                        label="Summary"
                        {...register("summary", { required: false })}
                        inputProps={{ value: openItems.summary ? openItems.summary : '' }}

                        onChange={(e) => setOpenItems((prev) => ({ ...prev, summary: e.target.value }))}
                    />
                    <div className="mt-3">
                        <ImageUploading
                            multiple
                            value={images}
                            onChange={onImageChange}
                            maxNumber={maxNumber}
                            dataURLKey="data_url"
                            acceptType={['jpg', 'jpeg', 'png']}
                        >
                            {({
                                imageList,
                                onImageUpload,
                                onImageRemoveAll,
                                onImageUpdate,
                                onImageRemove,
                                isDragging,
                                dragProps,
                                errors
                            }) => (
                                <>
                                    <button type="button" className="btn btn-primary mx-2 my-2 " onClick={onImageUpload}>Choose Images</button>

                                    <button type="button" className={"btn btn-danger mx-2 my-2 " + remAllImg} onClick={onImageRemoveAll}>Remove all images</button>
                                    <div {...dragProps} className="border border-light p-1" style={{ minHeight: '100px' }}>
                                        <label className="m-1">{isDragging ? "Drop here please" : "Drag & Drop images here to upload"}</label>
                                        <div className="container">
                                            <div className="row">
                                                {images && images.map((image, index) => (
                                                    <div key={index} className="card col-md-2 p-0 m-1">
                                                        <div className="card-body image-item text-center">
                                                            <img src={image.data_url} alt={"image" + index} style={{ width: '100%' }} />
                                                            <div className="image-item__btn-wrapper">
                                                                <button type="button" onClick={() => onImageUpdate(index)} className="m-1 border-0 bg-white"><i className="fa fa-edit"></i></button>
                                                                <button type="button" onClick={() => { onImageRemove(index); if (!images.length || images.length === 1) setRemAllImg('hide'); }} className="m-1 border-0 bg-white"><i className="fa fa-close"></i></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    {errors && <div className="text-danger">
                                        {errors.maxNumber && <span>Number of selected images exceed maxNumber</span>}
                                        {errors.acceptType && <span>Your selected file type is not allow</span>}
                                        {errors.maxFileSize && <span>Selected file size exceed maxFileSize</span>}
                                        {errors.resolution && <span>Selected file is not match your desired resolution</span>}
                                    </div>}
                                </>

                            )}
                        </ImageUploading>
                    </div>
                    <div className='row'>
                        <div className='col-12 col-md-4 col-lg-6 col-sm-2 col-xl-6 col-xxl-6'></div>
                        <div className='col-12 col-md-4 col-lg-3 col-sm-5 col-xl-3 col-xxl-3 text-end pb-2'>
                            <Button type="button" className="themeholy-btn btn-normal w-100" onClick={handleClose}>Cancel</Button>
                        </div>
                        <div className='col-12 col-md-4 col-lg-3 col-sm-5 col-xl-3 col-xxl-3 text-end pb-2'>
                            <Button type="submit" className="themeholy-btn btn-primary w-100" disabled={loaderText}>
                                {(loaderText) ? 'Submitting...' : 'Submit'}</Button>
                        </div>
                    </div>
                </div>
            </Box>
        )
    }
}

const Checklist = () => {

    const _token = localStorage.getItem("_token");
    const _role = localStorage.getItem("_role");
    axios.defaults.withCredentials = true;
    axios.defaults.headers['Accept'] = 'application/json';
    axios.defaults.headers['Content-Type'] = 'application/json';

    const { serviceId, jobData, checkListOpen, handleChecklistClose, setAlertOpen, setAlertStatus, setAlertMessage, fetchData } = useContext(FormContext);

    axios.defaults.headers['Authorization'] = 'Bearer ' + _token;
    const [loading, setLoading] = useState(true);
    const [checklistData, setChecklistData] = useState([]);

    const [clAlertStatus, setCLAlertStatus] = useState('success');
    const [clAlertOpen, setCLAlertOpen] = useState(false);
    const [clAlertMessage, setCLAlertMessage] = useState('');
    const [openB, setOpenB] = useState([]);
    const [openC, setOpenC] = useState([]);
    const [openD1, setOpenD1] = useState([]);
    const [openD2, setOpenD2] = useState([]);
    const [openGS1SY, setOpenGS1SY] = useState([]);
    const [openGS1SE, setOpenGS1SE] = useState([]);
    const [openGS2SY, setOpenGS2SY] = useState([]);
    const [openGS2SE, setOpenGS2SE] = useState([]);
    const [openGS3SY, setOpenGS3SY] = useState([]);
    const [openGS3SE, setOpenGS3SE] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const openForm = Boolean(anchorEl);
    const [openFormId, setOpenFormId] = useState('');
    const [openItems, setOpenItems] = useState({});
    const [loaderText, setLoaderText] = useState(false);
    const [confirmopen, setConfirmOpen] = useState(false);
    const [delData, setDelData] = useState({});


    let checklist_title = '';
    let checklist_name = '';
    //const GSCheckList = ['Appliance 1', 'Appliance 2', 'Appliance 3'];

    if (jobData.product.code === 'PROD3LMSM') {
        checklist_title = 'Electrical Safety Check Only';
        checklist_name = 'Electrical';
    } else if (jobData.product.code === 'PROD4LMSM') {
        checklist_title = 'Smoke Alarm Safety Check Only';
        checklist_name = 'Smke Alarm';
    } else if (jobData.service_type === 'ES') {
        checklist_title = 'Electrical & Smoke Alarm Safety Check';
        checklist_name = 'Electrical';
    } else if (jobData.service_type === 'GS') {
        checklist_title = 'Gas Safety Check';
        checklist_name = 'Gas';
    }

    const curdate = new Date();

    const [formField, setFormField] = useState({});


    const handleChange = (e, item, sectn, idx, index) => {

        if (!idx) {
            if (sectn === 'BSFTYCHK') {
                setOpenB(
                    openB.map(e1 => e1.id === item.id ? ({ ...e1, checked: e.target.checked ? 'Y' : 'N' }) : (e1))
                )
            } else if (sectn === 'CSFTYCHK') {
                setOpenC(
                    openC.map(e1 => e1.id === item.id ? ({ ...e1, checked: e.target.checked ? 'Y' : 'N' }) : (e1))
                )
            } else if (sectn === 'D1SFTYCHK') {
                setOpenD1(
                    openD1.map(e1 => e1.id === item.id ? ({ ...e1, checked: e.target.checked ? 'Y' : 'N' }) : (e1))
                )
            } else if (sectn === 'D2SFTYCHK') {
                setOpenD2(
                    openD2.map(e1 => e1.id === item.id ? ({ ...e1, checked: e.target.checked ? 'Y' : 'N' }) : (e1))
                )
            }
        } else {
            if (sectn === 'GASFTYCHK') {
                if (idx === 1) {
                    setOpenGS1SY(
                        openGS1SY.map(e1 => e1.id === item.id ? ({ ...e1, checked: e.target.checked ? 'Y' : 'N' }) : (e1))
                    )
                } else if (idx === 2) {
                    setOpenGS2SY(
                        openGS2SY.map(e1 => e1.id === item.id ? ({ ...e1, checked: e.target.checked ? 'Y' : 'N' }) : (e1))
                    )
                } else if (idx === 3) {
                    setOpenGS3SY(
                        openGS3SY.map(e1 => e1.id === item.id ? ({ ...e1, checked: e.target.checked ? 'Y' : 'N' }) : (e1))
                    )
                }
            } else if (sectn === 'GASERVICE') {
                if (idx === 1) {
                    setOpenGS1SE(
                        openGS1SE.map(e1 => e1.id === item.id ? ({ ...e1, checked: e.target.checked ? 'Y' : 'N' }) : (e1))
                    )
                } else if (idx === 2) {
                    setOpenGS2SE(
                        openGS2SE.map(e1 => e1.id === item.id ? ({ ...e1, checked: e.target.checked ? 'Y' : 'N' }) : (e1))
                    )
                } else if (idx === 3) {
                    setOpenGS3SE(
                        openGS3SE.map(e1 => e1.id === item.id ? ({ ...e1, checked: e.target.checked ? 'Y' : 'N' }) : (e1))
                    )
                }
            }
        }
    };

    const handleClick = (e, item, idx) => {
        //console.log(idx);
        setOpenFormId(item.id);
        if (idx) {
            item.app_num = idx;
        }
        //console.log(item);
        setOpenItems(item);
        setAnchorEl(e.currentTarget);
    };


    const handleClose = () => {
        setOpenItems({});
        setOpenFormId('');
        setAnchorEl(null);
    };


    const CLfetchData = () => {
        axios.get(apiBaseUrl + 'servicechecklist/' + serviceId)
            .then(response => response.data).then(resData => {
                //console.log(resData.data);                
                if (resData.status === 'success') {
                    setChecklistData(resData.data);
                    if (jobData.service_type === 'ES') {
                        setOpenB(resData.data.BSFTYCHK);
                        setOpenC(resData.data.CSFTYCHK);
                        setOpenD1(resData.data.D1SFTYCHK);
                        setOpenD2(resData.data.D2SFTYCHK);
                    }

                    if (jobData.service_type === 'GS') {
                        //console.log(resData.data);
                        setChecklistData(resData.data);
                        setOpenGS1SY(resData.data[0].GASFTYCHK);
                        setOpenGS2SY(resData.data[1].GASFTYCHK);
                        setOpenGS3SY(resData.data[2].GASFTYCHK);
                        setOpenGS1SE(resData.data[0].GASERVICE);
                        setOpenGS2SE(resData.data[1].GASERVICE);
                        setOpenGS3SE(resData.data[2].GASERVICE);
                    }

                    // console.log(GSCheckList);

                    const sa_next_due_date = jobData.sa_next_due_date ? jobData.sa_next_due_date : curdate.setFullYear(new Date().getFullYear() + 1);

                    let inspectnextdt = dayjs(curdate.setMonth(new Date().getMonth() + jobData.product.service_duration)).format('DD/MM/YYYY');
                    if (jobData.job_date) {
                        inspectnextdt = jobData.job_date.split('/');
                        var date = new Date(inspectnextdt[2] + '-' + inspectnextdt[1] + '-' + inspectnextdt[0]);
                        inspectnextdt = dayjs(date.setMonth(date.getMonth() + jobData.product.service_duration)).format('DD/MM/YYYY');
                    }
                    setFormField({
                        sa_check: jobData.sa_check ? jobData.sa_check : 'N',
                        sa_next_due_date: sa_next_due_date,
                        recommendation: jobData.recommendation,
                        inspectnextdt: inspectnextdt
                    });
                    setLoading(false);
                } else {
                    setAlertOpen(true);
                    setAlertStatus('error');
                    setAlertMessage(resData.message);
                    handleAutoClose();
                    setLoading(false);
                }

            }).catch((error) => {
                console.log(error);
                if (error.code === "ERR_NETWORK") {
                    error.message = "Something went wrong. Server is not repond to request. Please contact administrator.";
                } else if (error.response.status === 401) {
                    error.message = "Something went wrong. Session expired."
                }
                setCLAlertOpen(true);
                setCLAlertStatus('error');
                setCLAlertMessage(error.message);
                handleAutoClose();
                setLoading(false);
            });
    }

    useEffect(() => {
        //console.log(serviceId);

        if (serviceId) {
            CLfetchData();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serviceId]);

    const handleAutoClose = () => {
        window.scrollTo(0, 0);
        setTimeout(() => setCLAlertOpen(false), 5000);
    };

    const deleteDetails = (e, item, sectn, idx) => {
        if (item.cid) {
            setDelData({ 'id': item.id, 'cid': item.cid, 'sectn': sectn, 'idx': idx });
            setConfirmOpen(true);
        } else {
            if (!idx) {
                if (sectn === 'BSFTYCHK') {
                    setOpenB(
                        openB.map(e1 => e1.id === item.id ? ({ ...e1, checked: 'N' }) : (e1))
                    )
                } else if (sectn === 'CSFTYCHK') {
                    setOpenC(
                        openC.map(e1 => e1.id === item.id ? ({ ...e1, checked: 'N' }) : (e1))
                    )
                } else if (sectn === 'D1SFTYCHK') {
                    setOpenD1(
                        openD1.map(e1 => e1.id === item.id ? ({ ...e1, checked: 'N' }) : (e1))
                    )
                } else if (sectn === 'D2SFTYCHK') {
                    setOpenD2(
                        openD2.map(e1 => e1.id === item.id ? ({ ...e1, checked: 'N' }) : (e1))
                    )
                }
            } else {
                if (sectn === 'GASFTYCHK') {
                    if (idx === 1) {
                        setOpenGS1SY(
                            openGS1SY.map(e1 => e1.id === item.id ? ({ ...e1, checked: 'N' }) : (e1))
                        )
                    } else if (idx === 2) {
                        setOpenGS2SY(
                            openGS2SY.map(e1 => e1.id === item.id ? ({ ...e1, checked: 'N' }) : (e1))
                        )
                    } else if (idx === 3) {
                        setOpenGS3SY(
                            openGS3SY.map(e1 => e1.id === item.id ? ({ ...e1, checked: 'N' }) : (e1))
                        )
                    }
                } else if (sectn === 'GASERVICE') {
                    if (idx === 1) {
                        setOpenGS1SE(
                            openGS1SE.map(e1 => e1.id === item.id ? ({ ...e1, checked: 'N' }) : (e1))
                        )
                    } else if (idx === 2) {
                        setOpenGS2SE(
                            openGS2SE.map(e1 => e1.id === item.id ? ({ ...e1, checked: 'N' }) : (e1))
                        )
                    } else if (idx === 3) {
                        setOpenGS3SE(
                            openGS3SE.map(e1 => e1.id === item.id ? ({ ...e1, checked: 'N' }) : (e1))
                        )
                    }
                }
            }
        }
    }

    const handleDelete = () => {
        axios.post(apiBaseUrl + 'deletechecklistitem', { 'id': delData.id, 'cid': delData.cid })
            .then(response => response.data).then(resData => {
                setAlertOpen(true);
                if (resData.status === 'success') {
                    setAlertStatus('success');
                    if (!delData.idx) {
                        if (delData.sectn === 'BSFTYCHK') {
                            setOpenB(
                                openB.map(e1 => e1.id === delData.id ? (resData.item) : (e1))
                            )
                        } else if (delData.sectn === 'CSFTYCHK') {
                            setOpenC(
                                openC.map(e1 => e1.id === delData.id ? ({ ...e1, checked: 'N' }) : (e1))
                            )
                        } else if (delData.sectn === 'D1SFTYCHK') {
                            setOpenD1(
                                openD1.map(e1 => e1.id === delData.id ? ({ ...e1, checked: 'N' }) : (e1))
                            )
                        } else if (delData.sectn === 'D2SFTYCHK') {
                            setOpenD2(
                                openD2.map(e1 => e1.id === delData.id ? ({ ...e1, checked: 'N' }) : (e1))
                            )
                        }
                    } else {
                        if (delData.sectn === 'GASFTYCHK') {
                            if (delData.idx === 1) {
                                setOpenGS1SY(
                                    openGS1SY.map(e1 => e1.id === delData.id ? ({ ...e1, checked: 'N' }) : (e1))
                                )
                            } else if (delData.idx === 2) {
                                setOpenGS2SY(
                                    openGS2SY.map(e1 => e1.id === delData.id ? ({ ...e1, checked: 'N' }) : (e1))
                                )
                            } else if (delData.idx === 3) {
                                setOpenGS3SY(
                                    openGS3SY.map(e1 => e1.id === delData.id ? ({ ...e1, checked: 'N' }) : (e1))
                                )
                            }
                        } else if (delData.sectn === 'GASERVICE') {
                            if (delData.idx === 1) {
                                setOpenGS1SE(
                                    openGS1SE.map(e1 => e1.id === delData.id ? ({ ...e1, checked: 'N' }) : (e1))
                                )
                            } else if (delData.idx === 2) {
                                setOpenGS2SE(
                                    openGS2SE.map(e1 => e1.id === delData.id ? ({ ...e1, checked: 'N' }) : (e1))
                                )
                            } else if (delData.idx === 3) {
                                setOpenGS3SE(
                                    openGS3SE.map(e1 => e1.id === delData.id ? ({ ...e1, checked: 'N' }) : (e1))
                                )
                            }
                        }
                    }
                } else {
                    setAlertStatus('error');
                }
                setAlertMessage(resData.message);
                handleAutoClose();
                setLoaderText(false);
                setConfirmOpen(false);
                setDelData({});
            }).catch((error) => {
                console.log(error);
                setLoaderText(false);
                setAlertOpen(true);
                setAlertStatus('error');
                setAlertMessage('Something went wrong with Service API. Error: ' + error.message);
                handleAutoClose();
                setConfirmOpen(false);
                setDelData({});
            });
    };

    const handleConfirmClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setConfirmOpen(false);
            setDelData({});
        }
    };

    const handSubmit = () => {

        setLoaderText(true);

        var data = formField;

        //console.log(formField);

        data.saveAll = 'Y';

        data.service_id = serviceId;

        data.sa_next_due_date = dayjs(formField.sa_next_due_date).format('DD-MM-YYYY');

        if (jobData.service_type === 'ES') {
            data.BSFTYCHK = openB
            data.CSFTYCHK = openC;
            data.D1SFTYCHK = openD1;
            data.D2SFTYCHK = openD2;
        }

        if (jobData.service_type === 'GS') {
            data.GASFTYCHK = [openGS1SY, openGS2SY, openGS3SY];
            //data.GASFTYCHK[1] = openGS2SY;
            //data.GASFTYCHK[2] = openGS3SY;
            data.GASERVICE = [openGS1SE, openGS2SE, openGS3SE];
            //data.GASERVICE[1] = openGS2SE;
            //data.GASERVICE[2] = openGS3SE;
        }

        // console.log(data);

        axios.post(apiBaseUrl + 'servicechecklist/update', data).then(response => response.data).then(resData => {
            setAlertOpen(true);
            console.log(resData);
            if (resData.status === 'success') {
                setAlertStatus('success');
                handleClose();
                fetchData();
            } else {
                setAlertStatus('error');
            }
            setAlertMessage(resData.message);
            handleChecklistClose();
            setLoaderText(false);
        }).catch((error) => {
            console.log(error);
            setLoaderText(false);
            setAlertOpen(true);
            setAlertStatus('error');
            setAlertMessage('Something went wrong with updateing service checklist API. Error: ' + error.message);
            handleChecklistClose();
        });


    }

    return (

        <Dialog
            fullWidth={true}
            maxWidth='lg'
            open={checkListOpen}
            onClose={handleChecklistClose}
            scroll='body'
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"

        >
            <IconButton
                aria-label="close"
                onClick={handleChecklistClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: 'white',
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogTitle id="scroll-checklist-dialog" className="app-header-top text-center" component="header">
                <Typography
                    sx={{ display: 'block', marginTop: '15px', marginBottom: '15px' }}
                    component="div"
                    variant="body"
                    color="white"
                >
                    Residential Tenancies Regulations {curdate.getFullYear()}
                </Typography>
                <Typography
                    sx={{ display: 'block', marginTop: '15px', marginBottom: '15px' }}
                    component="h5"
                    variant="subtitle-1"
                    color="white"
                >
                    Checklist for {checklist_title}
                </Typography>
                <Typography
                    sx={{ display: 'block', fontSize: '14px', lineHeight: '20px' }}

                    className="text-start"
                >
                    {jobData.service_type === 'GS' ? <>
                        This inspection is for gas safety purposes only and is in accordance with the requirements of the Residential Tenancies Regulations 2021 and the
                        Residential Tenancies (Rooming House Standards) Regulations 2012
                    </>
                        : <>
                            This {checklist_name} safety check is for {checklist_name} safety purposes only and is in accordance with the requirements of the Residential Tenancies
                            Regulations 2021 and is prepared in accordance with section 2 of the Australian/New Zealand Standard AS/NZS 3019, {checklist_name} installations—
                            Periodic verification to confirm that the installation is not damaged or has not deteriorated so as to impair {checklist_name} safety; and to identify
                            installation defects and departures from the requirements that may give rise to danger
                        </>
                    }
                </Typography>
            </DialogTitle>

            <DialogContent dividers={false}>
                <Typography
                    sx={{ display: 'block', marginTop: '15px', marginBottom: '15px' }}
                    component="h5"
                    variant="subtitle" className="sub-title"
                >
                    {jobData.product.description}
                </Typography>

                <Table sx={{ marginTop: '15px' }} aria-label="propertyinfo">
                    <TableHead className="bg-smoke text-body">
                        <TableRow>
                            <TableCell colSpan={4}>
                                <Typography
                                    className='fw-extrabold'

                                >
                                    A. INSTALLATION ADDRESS
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell className='fw-bold'>Land Lord</TableCell>
                            <TableCell>{jobData.property && jobData.property.propertyowner.prefix + '. ' + jobData.property.propertyowner.lastname + ' ' + jobData.property.propertyowner.firstname}</TableCell>
                            <TableCell className='fw-bold'>Agent</TableCell>
                            <TableCell>{jobData.property && jobData.property.propertyagent.prefix + '. ' + jobData.property.propertyagent.lastname + ' ' + jobData.property.propertyagent.firstname}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className='fw-bold'>Address</TableCell>
                            <TableCell>{jobData.property && jobData.property.address1 + ', ' + jobData.property.address1 + ', ' + jobData.property.suburb + ', ' + jobData.property.state + ' - ' + jobData.property.postcode}</TableCell>
                            <TableCell className='fw-bold'>Date of previous Safety Check: (if any)</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>

                <Box>
                    <Box sx={{ textAlign: 'center' }}>
                        <Fade
                            in={loading}
                            unmountOnExit
                        >
                            <CircularProgress />
                        </Fade>
                    </Box>
                    <Collapse in={clAlertOpen}>
                        <Alert variant="filled" severity={clAlertStatus}
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setCLAlertOpen(false);
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                            sx={{ mb: 2 }}
                        >
                            {clAlertMessage}
                        </Alert>
                    </Collapse>
                    {jobData.service_type === 'ES' && (<>
                        <Table sx={{ marginTop: '15px' }} aria-label="BSection">
                            <TableHead className="bg-smoke text-body">
                                <TableRow >
                                    <TableCell>
                                        <Typography

                                            className='fw-extrabold'
                                        >
                                            B. EXTENT OF THE INSTALLATION AND LIMITATIONS OF THE INSPECTION AND TESTING
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{ backgroundColor: 'white' }}>
                                    <TableCell>

                                        Details of those parts of the installation and limitations of the safety check covered by this certificate Tick those parts of the installation included
                                        in the safety check - strike out those parts of the installation if not applicable - mark NI if not included in the safety check - add additional
                                        information if required.

                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <div className='row'>

                                            {checklistData.BSFTYCHK && checklistData.BSFTYCHK.map((item, index) => (
                                                //onChange={() => { handleChange(item) }}
                                                <Fragment key={"cont-" + index}>
                                                    <div className='col-7 col-md-4 pt-2'>{item.description}</div>
                                                    <div className='col-5 col-md-2 p-0 text-end'>
                                                        <div className='row'>
                                                            <Link onClick={(e) => handleClick(e, openB[index])} className='col-2 col-md-2 mt-2' title="Add/View Details"><i className='fa fa-screwdriver-wrench'></i></Link>
                                                            <Fade
                                                                in={openB[index].checked === 'Y' ? true : false}
                                                                unmountOnExit
                                                                className='col-3 col-md-2 mt-2'
                                                            >

                                                                <Link onClick={(e) => deleteDetails(e, item, 'BSFTYCHK')} title="Remove Details"><i className='fa fa-delete-left'></i></Link>

                                                            </Fade>
                                                            <Android12Switch inputProps={{ "aria-label": "switch-" + index }} name="bsec[]" aria-describedby={item.id} onClick={(e) => handleChange(e, item, 'BSFTYCHK')} className='col-7 col-md-8' checked={openB[index].checked === 'Y' ? true : false} disabled={openB[index].checked === 'Y' ? true : false} />
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            ))
                                            }
                                        </div>

                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        <Table sx={{ marginTop: '15px' }} aria-label="CSection">
                            <TableHead className="bg-smoke text-body">
                                <TableRow >
                                    <TableCell>
                                        <Typography

                                            className='fw-extrabold'
                                        >
                                            C. SAFETY CHECK - VERIFIED BY VISUAL INSPECTION
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{ backgroundColor: 'white' }}>
                                    <TableCell>

                                        As far as practicable a VISUAL INSPECTION of the following items has been carried out per the requirements of <strong>section 3 and 4 of the
                                            Australian/New Zealand Standard AS/NZS 3019:2007 {checklist_name} installations—Periodic Verification:</strong> strike out those parts of the installation if
                                        not applicable - mark <strong>NI</strong> if not included in the safety check - add additional information if required.

                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <div className='row'>

                                            {checklistData.CSFTYCHK && checklistData.CSFTYCHK.map((item, index) => (

                                                <Fragment key={"cont-" + index}>
                                                    <div className='col-7 col-md-4 pt-2'>{item.description}</div>
                                                    <div className='col-5 col-md-2 p-0 text-end'>
                                                        <div className='row'>
                                                            <Link onClick={(e) => handleClick(e, openC[index])} className='col-2 col-md-2 mt-2' title="Add/View Details"><i className='fa-solid fa-screwdriver-wrench'></i></Link>
                                                            <Fade
                                                                in={openC[index].checked === 'Y' ? true : false}
                                                                unmountOnExit
                                                                className='col-3 col-md-2 mt-2'
                                                            >
                                                                <Link onClick={(e) => deleteDetails(e, item, 'CSFTYCHK')} className='col-3 col-md-2 mt-2' title="Remove Details"><i className='fa fa-delete-left'></i></Link>
                                                            </Fade>
                                                            <Android12Switch inputProps={{ "aria-label": "switch-" + index }} name="csec[]" aria-describedby={item.id} onClick={(e) => handleChange(e, item, 'CSFTYCHK')} className='col-7 col-md-8' checked={openC[index].checked === 'Y' ? true : false} disabled={openC[index].checked === 'Y' ? true : false} />
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            ))
                                            }
                                        </div>

                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        <Table sx={{ marginTop: '15px' }} aria-label="DSection">
                            <TableHead className="bg-smoke text-body">
                                <TableRow >
                                    <TableCell>
                                        <Typography

                                            className='fw-extrabold'
                                        >
                                            D. SAFETY CHECK - VERIFIED BY TESTING
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{ backgroundColor: 'white' }}>
                                    <TableCell>

                                        As far as practicable TESTING of the following items has been carried out per the requirements of <strong>4 of the Australian/New Zealand Standard
                                            AS/NZS 3019:2007 {checklist_name} installations—Periodic Verification:</strong> strike out those parts of the installation if not applicable - mark <strong>NI</strong> if not
                                        included in the safety check - add additional information if required.

                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <Typography className="sub-title">Polarity and correct connections testing</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <div className='row'>

                                            {checklistData.D1SFTYCHK && checklistData.D1SFTYCHK.map((item, index) => (

                                                <Fragment key={"cont-" + index}>
                                                    <div className='col-7 col-md-4 pt-2'>{item.description}</div>
                                                    <div className='col-5 col-md-2 p-0 text-end'>
                                                        <div className='row'>
                                                            <Link onClick={(e) => handleClick(e, openD1[index])} className='col-2 col-md-2 mt-2' title="Add/View Details"><i className='fa-solid fa-screwdriver-wrench'></i></Link>
                                                            <Fade
                                                                in={openD1[index].checked === 'Y' ? true : false}
                                                                unmountOnExit
                                                                className='col-3 col-md-2 mt-2'
                                                            >
                                                                <Link onClick={(e) => deleteDetails(e, item, 'D1SFTYCHK')} className='col-3 col-md-2 mt-2' title="Remove Details"><i className='fa fa-delete-left'></i></Link>
                                                            </Fade>
                                                            <Android12Switch inputProps={{ "aria-label": "switch-" + index }} name="d1sec[]" aria-describedby={item.id} onClick={(e) => handleChange(e, item, 'D1SFTYCHK')} className='col-7 col-md-8' checked={openD1[index].checked === 'Y' ? true : false} disabled={openD1[index].checked === 'Y' ? true : false} />
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            ))
                                            }
                                        </div>

                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography className="sub-title">Earth continuity testing</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <div className='row'>

                                            {checklistData.D2SFTYCHK && checklistData.D2SFTYCHK.map((item, index) => (
                                                <Fragment key={"cont-" + index}>
                                                    <div className='col-7 col-md-4 pt-2'>{item.description}</div>
                                                    <div className='col-5 col-md-2 p-0 text-end'>
                                                        <div className='row'>
                                                            <Link onClick={(e) => handleClick(e, openD2[index])} className='col-2 col-md-2 mt-2' title="Add/View Details"><i className='fa-solid fa-screwdriver-wrench'></i></Link>
                                                            <Fade
                                                                in={openD2[index].checked === 'Y' ? true : false}
                                                                unmountOnExit
                                                                className='col-3 col-md-2 mt-2'
                                                            >
                                                                <Link onClick={(e) => deleteDetails(e, item, 'D2SFTYCHK')} className='col-3 col-md-2 mt-2' title="Remove Details"><i className='fa fa-delete-left'></i></Link>
                                                            </Fade>
                                                            <Android12Switch inputProps={{ "aria-label": "switch-" + index }} name="d2sec[]" aria-describedby={item.id} onClick={(e) => handleChange(e, item, 'D2SFTYCHK')} className='col-7 col-md-8' checked={openD2[index].checked === 'Y' ? true : false} disabled={openD2[index].checked === 'Y' ? true : false} />
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            ))
                                            }
                                        </div>

                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </>)}
                    {jobData.service_type === 'GS' && !loading && (
                        <>
                            <div className='sub-title fs-xs'>Record observations, including burner pressures, and faults in the observations table below</div>

                            {checklistData && checklistData.map((GSData, idx) => (

                                <Table aria-label={"GS" + idx + "Section"} key={"GSlist" + idx}>
                                    <TableHead className="bg-smoke text-body">
                                        <TableRow className='bg-title'>
                                            <TableCell>
                                                <Typography

                                                    className='fw-extrabold text-white'
                                                >
                                                    Appliance {idx + 1} <span className='fs-xs'>(location, type, make and model):</span>
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow >
                                            <TableCell>
                                                <Typography

                                                    className='fw-extrabold'
                                                >
                                                    Installation check
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                <div className='row'>
                                                    {GSData.GASFTYCHK && GSData.GASFTYCHK.map((item, index) => (
                                                        <Fragment key={"contGS" + idx + "SY-" + index}>
                                                            <div className='col-7 col-md-4 pt-2'>{item.description}</div>
                                                            <div className='col-5 col-md-2 p-0 text-end'>
                                                                <div className='row'>
                                                                    <Link onClick={(e) => handleClick(e, item, idx + 1)} className='col-2 col-md-2 mt-2' title="Add/View Details"><i className='fa fa-screwdriver-wrench'></i></Link>

                                                                    {idx === 0 && <>
                                                                        <Fade
                                                                            in={openGS1SY[index].checked && openGS1SY[index].checked === 'Y' ? true : false}
                                                                            unmountOnExit
                                                                            className='col-3 col-md-2 mt-2'
                                                                        >

                                                                            <Link onClick={(e) => deleteDetails(e, item, 'GASFTYCHK', idx + 1)} title="Remove Details"><i className='fa fa-delete-left'></i></Link>

                                                                        </Fade>

                                                                        <Android12Switch inputProps={{ 'aria-label': 'switch-' + (idx + 1) + '-sy-' + index + '-' + item.id }} name={"GS" + (idx + 1) + "SYsec[]"} aria-describedby={'desc-' + (idx + 1) + '-sy-' + index + '-' + item.id} onClick={(e) => handleChange(e, item, 'GASFTYCHK', (idx + 1), index)} className='col-7 col-md-8' checked={openGS1SY[index].checked && openGS1SY[index].checked === 'Y' ? true : false} disabled={openGS1SY[index].checked && openGS1SY[index].checked === 'Y' ? true : false} />
                                                                    </>}

                                                                    {idx === 1 && <>
                                                                        <Fade
                                                                            in={openGS2SY[index].checked && openGS2SY[index].checked === 'Y' ? true : false}
                                                                            unmountOnExit
                                                                            className='col-3 col-md-2 mt-2'
                                                                        >

                                                                            <Link onClick={(e) => deleteDetails(e, item, 'GASFTYCHK', idx + 1)} title="Remove Details"><i className='fa fa-delete-left'></i></Link>

                                                                        </Fade>

                                                                        <Android12Switch inputProps={{ 'aria-label': 'switch-' + (idx + 1) + '-sy-' + index + '-' + item.id }} name={"GS" + (idx + 1) + "SYsec[]"} aria-describedby={'desc-' + (idx + 1) + '-sy-' + index + '-' + item.id} onClick={(e) => handleChange(e, item, 'GASFTYCHK', (idx + 1), index)} className='col-7 col-md-8' checked={openGS2SY[index].checked && openGS2SY[index].checked === 'Y' ? true : false} disabled={openGS2SY[index].checked && openGS2SY[index].checked === 'Y' ? true : false} />
                                                                    </>}

                                                                    {idx === 2 && <>
                                                                        <Fade
                                                                            in={openGS3SY[index].checked && openGS3SY[index].checked === 'Y' ? true : false}
                                                                            unmountOnExit
                                                                            className='col-3 col-md-2 mt-2'
                                                                        >

                                                                            <Link onClick={(e) => deleteDetails(e, item, 'GASFTYCHK', idx + 1)} title="Remove Details"><i className='fa fa-delete-left'></i></Link>

                                                                        </Fade>

                                                                        <Android12Switch inputProps={{ 'aria-label': 'switch-' + (idx + 1) + '-sy-' + index + '-' + item.id }} name={"GS" + (idx + 1) + "SYsec[]"} aria-describedby={'desc-' + (idx + 1) + '-sy-' + index + '-' + item.id} onClick={(e) => handleChange(e, item, 'GASFTYCHK', (idx + 1), index)} className='col-7 col-md-8' checked={openGS3SY[index].checked && openGS3SY[index].checked === 'Y' ? true : false} disabled={openGS3SY[index].checked && openGS3SY[index].checked === 'Y' ? true : false} />
                                                                    </>}

                                                                </div>

                                                            </div>
                                                        </Fragment>
                                                    ))}
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                    <TableHead className="bg-smoke text-body">
                                        <TableRow>
                                            <TableCell>
                                                <Typography

                                                    className='fw-extrabold'
                                                >
                                                    Appliance servicing <span className='fs-xs'>(For guidance on servicing Type A gas appliances refer to AS4575):</span>
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                <div className='row'>
                                                    {GSData.GASERVICE && GSData.GASERVICE.map((item, index) => (
                                                        <Fragment key={"contGS" + idx + "SE-" + index}>
                                                            <div className='col-7 col-md-4 pt-2'>{item.description}</div>
                                                            <div className='col-5 col-md-2 p-0 text-end'>
                                                                <div className='row'>
                                                                    <Link onClick={(e) => handleClick(e, item, idx + 1)} className='col-2 col-md-2 mt-2' title="Add/View Details"><i className='fa fa-screwdriver-wrench'></i></Link>

                                                                    {idx === 0 && <>
                                                                        <Fade
                                                                            in={openGS1SE[index].checked && openGS1SE[index].checked === 'Y' ? true : false}
                                                                            unmountOnExit
                                                                            className='col-3 col-md-2 mt-2'
                                                                        >

                                                                            <Link onClick={(e) => deleteDetails(e, item, 'GASERVICE', idx + 1)} title="Remove Details"><i className='fa fa-delete-left'></i></Link>

                                                                        </Fade>

                                                                        <Android12Switch inputProps={{ 'aria-label': 'switch-' + (idx + 1) + '-se-' + index + '-' + item.id }} name={"GS" + (idx + 1) + "SEsec[]"} aria-describedby={'desc-' + (idx + 1) + '-se-' + index + '-' + item.id} onClick={(e) => handleChange(e, item, 'GASERVICE', (idx + 1), index)} className='col-7 col-md-8' checked={openGS1SE[index].checked && openGS1SE[index].checked === 'Y' ? true : false} disabled={openGS1SE[index].checked && openGS1SE[index].checked === 'Y' ? true : false} />
                                                                    </>}

                                                                    {idx === 1 && <>
                                                                        <Fade
                                                                            in={openGS2SE[index].checked && openGS2SE[index].checked === 'Y' ? true : false}
                                                                            unmountOnExit
                                                                            className='col-3 col-md-2 mt-2'
                                                                        >

                                                                            <Link onClick={(e) => deleteDetails(e, item, 'GASERVICE', idx + 1)} title="Remove Details"><i className='fa fa-delete-left'></i></Link>

                                                                        </Fade>

                                                                        <Android12Switch inputProps={{ 'aria-label': 'switch-' + (idx + 1) + '-se-' + index + '-' + item.id }} name={"GS" + (idx + 1) + "SEsec[]"} aria-describedby={'desc-' + (idx + 1) + '-se-' + index + '-' + item.id} onClick={(e) => handleChange(e, item, 'GASERVICE', (idx + 1), index)} className='col-7 col-md-8' checked={openGS2SE[index].checked && openGS2SE[index].checked === 'Y' ? true : false} disabled={openGS2SE[index].checked && openGS2SE[index].checked === 'Y' ? true : false} />
                                                                    </>}

                                                                    {idx === 2 && <>
                                                                        <Fade
                                                                            in={openGS3SE[index].checked && openGS3SE[index].checked === 'Y' ? true : false}
                                                                            unmountOnExit
                                                                            className='col-3 col-md-2 mt-2'
                                                                        >

                                                                            <Link onClick={(e) => deleteDetails(e, item, 'GASERVICE', idx + 1)} title="Remove Details"><i className='fa fa-delete-left'></i></Link>

                                                                        </Fade>

                                                                        <Android12Switch inputProps={{ 'aria-label': 'switch-' + (idx + 1) + '-se-' + index + '-' + item.id }} name={"GS" + (idx + 1) + "SEsec[]"} aria-describedby={'desc-' + (idx + 1) + '-se-' + index + '-' + item.id} onClick={(e) => handleChange(e, item, 'GASERVICE', (idx + 1), index)} className='col-7 col-md-8' checked={openGS3SE[index].checked && openGS3SE[index].checked === 'Y' ? true : false} disabled={openGS3SE[index].checked && openGS3SE[index].checked === 'Y' ? true : false} />
                                                                    </>}

                                                                </div>

                                                            </div>
                                                        </Fragment>
                                                    ))}
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            ))}

                        </>
                    )}
                    <Popover
                        id={openFormId}
                        open={openForm}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <ChecklistForm serviceId={serviceId} openItems={openItems} setOpenItems={setOpenItems} CLfetchData={CLfetchData} handleClose={handleClose} />
                    </Popover>
                    {(jobData.product.code !== 'PROD3LMSM' && (jobData.service_type === 'ES' || jobData.service_type === 'SA')) && (<>
                        <Table sx={{ marginTop: '15px' }} aria-label="CSection">
                            <TableHead className="bg-smoke text-body">
                                <TableRow >
                                    <TableCell>
                                        <Typography

                                            className='fw-extrabold'
                                        >
                                            E. Smoke Alarms
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{ backgroundColor: 'white' }}>
                                    <TableCell>

                                        All smoke alarms must be tested according to the
                                        manufacturer's instructions at least once every 12 months

                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <div className='row'>
                                            <div className='col-md-3'>All smoke alarm are correctly installed and in working
                                                condition; and have been tested according to the
                                                manufacturer's instructions. </div>
                                            <div className='col-md-2'>
                                                <Android12Switch inputProps={{ "aria-label": "switch-smoke-alarm" }} name="sa_check" aria-describedby="smoke-alarm" className='col-md-8'
                                                    onChange={(e) => { setFormField((prev) => ({ ...prev, sa_check: e.target.checked ? 'Y' : 'N' })) }} checked={formField.sa_check === 'Y' ? true : false} />
                                            </div>

                                            <div className='col-md-3 pt-3'>Next smoke alarms check is
                                                due by:</div>
                                            <div className='col-md-4'>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={['DatePicker', 'DatePicker']}>

                                                        <DatePicker
                                                            disablePast
                                                            label="Next Smoke Alarm Check is due by"
                                                            format="DD/MM/YYYY"
                                                            value={dayjs(formField.sa_next_due_date)}
                                                            onChange={(newValue) => { setFormField((prev) => ({ ...prev, sa_next_due_date: newValue })) }}
                                                            readOnly={(_role === 'SYS' || _role === 'ADMSTF') ? false : true}

                                                        />

                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </div>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </>)}
                    <Table sx={{ marginTop: '15px' }} aria-label="OSection">
                        <TableHead className="bg-smoke text-body">
                            <TableRow>
                                <TableCell>
                                    <Typography

                                        className='fw-extrabold'
                                    >
                                        F. OBSERVATIONS AND RECOMMENDATIONS FOR ANY ACTIONS TO BE TAKEN
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    The following observations and recommendations are made:
                                    <TextareaAutosize style={{ backgroundColor: 'white', borderColor: '#eee' }} name="recommendation"
                                        onChange={(e) => { setFormField((prev) => ({ ...prev, recommendation: e.target.value })) }}
                                        value={formField.recommendation ? formField.recommendation : ''}
                                    >
                                    </TextareaAutosize>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <Table sx={{ marginTop: '15px' }} aria-label="CCSection">
                        <TableHead className="bg-smoke text-body">
                            <TableRow>
                                <TableCell>
                                    <Typography

                                        className='fw-extrabold'
                                    >
                                        G. {checklist_title} Certification
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <div className='row'>
                                        <div className='col-md-4'>{checklist_title}
                                            Completed By:
                                        </div>
                                        <div className='col-md-3'>{jobData.technician && jobData.technician.prefix + ' ' + jobData.technician.lastname + ' ' + jobData.technician.firstname}</div>
                                        <div className='col-md-5'>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    Licence / Registration
                                                    Number:
                                                </div>
                                                <div className='col-md-6'>
                                                    {jobData.technician && jobData.technician.license_number}
                                                </div>
                                                <div className='col-md-6 mt-4'>
                                                    Inspection Date:
                                                </div>
                                                <div className='col-md-6 mt-4'>
                                                    {jobData.job_date}
                                                </div>
                                                <div className='col-md-6 mt-4'>
                                                    Next inspection Due By:
                                                </div>
                                                <div className='col-md-6 mt-4'>
                                                    {formField.inspectnextdt}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    I the above named licenced electrician have carried out an {checklist_name} safety check of this residential tenancies per the
                                    requirements of the Residential Tenancies Regulations 2021 and set out in the Australian/New Zealand Standard
                                    AS/NZS 3019, "{checklist_name} installations—Periodic verification, and have recorded my observations and
                                    recommendations.
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <div className='row'>
                                        <div className='col-12 col-md-8'>Sign {jobData.technician && jobData.technician.prefix + ' ' + jobData.technician.lastname + ' ' + jobData.technician.firstname}</div>

                                        <div className='col-12 col-md-4'>Date {jobData.job_date}</div>
                                    </div>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Box>

                <Dialog
                    open={confirmopen}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleConfirmClose}
                    aria-describedby="alert-dialog-slide-description"

                >
                    <DialogTitle>Are you sure you want to clear the details?</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            If yes, the details will be cleared from the records and no longer be exists.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button className="themeholy-btn btn-secondary" onClick={handleConfirmClose}>Cancel</Button>
                        <Button className="themeholy-btn btn-primary" onClick={() => handleDelete({ delData })}>Yes</Button>
                    </DialogActions>
                </Dialog>
            </DialogContent>
            <DialogActions className='mb-3'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 col-md-4 col-lg-6 col-sm-2 col-xl-6 col-xxl-6'></div>
                        <div className='col-12 col-md-4 col-lg-3 col-sm-5 col-xl-3 col-xxl-3 text-end pb-2'>
                            <Button type="button" className="themeholy-btn btn-normal w-100" onClick={handleChecklistClose}>Cancel</Button>
                        </div>
                        <div className='col-12 col-md-4 col-lg-3 col-sm-5 col-xl-3 col-xxl-3 text-end pb-2'>
                            <Button type="button" className="themeholy-btn btn-primary w-100" onClick={handSubmit} disabled={loaderText}>{(loaderText) ? 'Saving...' : 'Save & Exit'}</Button>
                        </div>
                    </div>
                </div>
            </DialogActions>
        </Dialog >


    );

}

export default Checklist;