import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { apiBaseUrl } from '../../common';
import axios from 'axios';
import {
    Box, CircularProgress, Fade, Radio, RadioGroup,
    FormControl, FormControlLabel, Typography, Button, TextareaAutosize, TextField,
    Collapse, Alert, IconButton
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Logo from '../../assets/img/logo-main.png';
//import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export const QuoteService = ({ propertyId, propertyData, setDrawerOpen, formData, fetchQuoteInvoiceData }) => {
    const _token = localStorage.getItem("_token");
    const _role = localStorage.getItem("_role");
    axios.defaults.withCredentials = true;
    axios.defaults.headers['Accept'] = 'application/json';
    axios.defaults.headers['Content-Type'] = 'application/json';
    axios.defaults.headers['Authorization'] = 'Bearer ' + _token;
    const [serviceList, setServiceList] = useState([]);
    const [loader, setLoader] = useState(true);
    const [serviceIndex, setServiceIndex] = useState('');
    const [serviceData, setServiceData] = useState([]);
    const [error, setError] = useState();
    const [steps, setSteps] = useState({ one: true, two: false });
    const [formField, setFormField] = useState([{ description: "", quantity: "", charge: "", amount: "" }]);

    const [alertStatus, setAlertStatus] = useState('success');
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [loaderText, setLoaderText] = useState(false);

    const { handleSubmit, formState: { errors }, register } = useForm();

    const curDate = new Date();

    const actionFun = formData.action;


    const [overAll, setOverAll] = useState({ id: "", property_id: propertyId, service_id: "", subtotal: "0.00", tax_rate: "0.00", total: "0.00", notes: "", subject: "", due_date: curDate, quote_number: "", action: "add" });

    const fetchQuoteServiceData = () => {
        const params = { 'property_id': propertyId }
        axios.post(apiBaseUrl + 'quote/services', params)
            .then(response => response.data).then(resData => {
                //console.log(resData.data);
                if (resData.status === 'success') {
                    if (resData.data.length === 0) {
                        setSteps({ one: false, two: true });
                    } else {
                        setServiceList(resData.data);
                    }

                    setLoader(false);
                }

            }).catch((error) => {
                console.log(error);
                if (error.code === "ERR_NETWORK") {
                    error.message = "Something went wrong. Server is not repond to request. Please contact administrator."
                } else if (error.response.status === 401) {
                    // handleLogout();
                    // navigate("/login");
                }

            });
    }

    useEffect(() => {

        if (formData.action === 'edit') {
            setSteps({ one: false, two: true });
            const duedate = formData.due_date.split('/');
            //console.log(formData);
            setOverAll({ id: formData.id, property_id: propertyId, service_id: formData.service_id, subtotal: formData.subtotal, tax_rate: formData.tax_rate, total: formData.total, notes: formData.notes, subject: formData.subject, due_date: duedate[2] + '/' + duedate[1] + '/' + duedate[0], quote_number: formData.quote_number, action: formData.action });
            setFormField(formData.quotes_services)
            setLoader(false);
        } else {
            fetchQuoteServiceData();
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (event) => {
        setError('');
        setServiceIndex(event.target.value);
        setServiceData(serviceList[event.target.value]);
    };

    const handleBack = () => {
        setSteps({ one: true, two: false });
    }

    const handleFormClose = () => {
        setServiceData({});
        setSteps({ one: true, two: false });
        setDrawerOpen(false);
    }

    const handleNext = (withFlag) => {
        if (withFlag && !serviceData.id) {
            setError('Please choose service to proceed');
            return false;
        } else {
            if (withFlag)
                setOverAll((prev) => ({ ...prev, service_id: serviceData.id }));
            else {
                setOverAll((prev) => ({ ...prev, service_id: "" }));
                setServiceIndex('');
                setServiceData([]);
            }
        }

        setSteps({ one: false, two: true });
    };

    const handleFieldChange = (i, e) => {
        let newFormValues = [...formField];
        var amount = '0.00';
        var subtotal = '0.00';
        var tax_rate = '0.00';
        var total = '0.00';
        newFormValues[i][e.target.name] = e.target.value;
        if (e.target.name === 'quantity' || e.target.name === 'charge') {
            amount = parseInt(newFormValues[i]['quantity']) * parseFloat(newFormValues[i]['charge']);
            newFormValues[i].amount = amount ? amount.toFixed(2) : '0.00';

            newFormValues.map(element => subtotal = parseFloat(subtotal) + parseFloat(element.amount));

            subtotal = subtotal.toFixed(2);
            tax_rate = parseFloat(subtotal) * (10 / 100);
            tax_rate = tax_rate.toFixed(2);
            total = parseFloat(subtotal) + parseFloat(tax_rate);
            total = total.toFixed(2);

            setOverAll((prev) => ({ ...prev, subtotal: subtotal, tax_rate: tax_rate, total: total }));
        }
        setFormField(newFormValues);
    }

    const addFormFields = () => {
        setFormField([...formField, { description: "", quantity: "", charge: "", amount: "" }])
    }

    const removeFormFields = (i) => {
        let newFormValues = [...formField];
        newFormValues.splice(i, 1);
        setFormField(newFormValues)
    }

    const handleAutoClose = () => {
        window.scrollTo(0, 0);
        setTimeout(() => setAlertOpen(false), 5000);
    };

    const onQuoteSubmit = (data) => {
        data = overAll;
        data.due_date = dayjs(overAll.due_date).format('DD-MM-YYYY');
        data.items = formField;
        // console.log(data);

        var axiosCall = null;

        if (overAll.action === 'edit') {
            data._method = 'PUT';
            axiosCall = axios.post(apiBaseUrl + 'quotes/' + overAll.id, data);
        } else if (overAll.action === 'add') {
            axiosCall = axios.post(apiBaseUrl + 'quotes', data);
        }

        axiosCall.then(response => response.data).then(resData => {
            setAlertOpen(true);
            if (resData.status === 'success') {
                setAlertStatus('success');
                fetchQuoteInvoiceData();
            } else {
                setAlertStatus('error');
            }
            setAlertMessage(resData.message);
            handleAutoClose();
            setLoaderText(false);
            handleFormClose();
        }).catch((error) => {
            console.log(error);
            setLoaderText(false);
            setAlertOpen(true);
            setAlertStatus('error');
            setAlertMessage('Something went wrong with Service API. Error: ' + error.message);
            handleAutoClose();
            handleFormClose();
        });
    };

    return (
        <>
            <Typography
                variant='h5'
                className='header-top text-white p-3'
            >{actionFun && actionFun[0].toUpperCase() + actionFun.slice(1)} Quote</Typography>
            <div>
                {loader && <Box sx={{ textAlign: 'center', marginTop: '40px' }}>
                    <Fade
                        in={loader}
                        unmountOnExit
                    >
                        <CircularProgress />
                    </Fade>
                </Box>}
                {!loader && <div className="container">
                    {steps.one && <>
                        <Button type="button" className="themeholy-btn btn-primary mt-5" onClick={() => handleNext(false)}>
                            Add Pre-Quote/Estimation
                        </Button>
                        {serviceList && <div className="form-list-row mt-3">
                            <Typography className="mt-3 mb-3"
                            >Choose Service to Add Quote</Typography>
                            <div className="row fw-bold fs-xs">
                                <div className="col-3 p-0 text-center">Job ID</div>
                                <div className="col-4 p-0">Job Date</div>
                                <div className="col-5 p-0">Product</div>
                            </div>
                            <FormControl fullWidth={true}>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="female"
                                    name="radio-buttons-group"
                                    value={serviceIndex}
                                    onChange={handleChange}
                                >
                                    {serviceList.map((service, index) => (
                                        <div className="row fs-xs" key={service.id}>
                                            <div className="col-3 p-0 pb-0 text-center"><FormControlLabel value={index} control={<Radio />} label={'#' + service.id} /></div>
                                            <div className="col-4 p-0 pt-2 pb-0">{service.job_date}</div>
                                            <div className="col-5 p-0 pt-2 pb-0">{service.product.code ? service.product.code : service.description}</div>
                                        </div>
                                    ))}
                                </RadioGroup>
                            </FormControl>
                            <div className="text-center p-3">
                                {error && <div className="text-danger mb-2">{error}</div>}
                                <Button type="button" className="themeholy-btn btn-primary m-3" onClick={handleFormClose}>
                                    Close
                                </Button>
                                <Button type="button" autoFocus className="themeholy-btn btn-primary m-3" onClick={() => handleNext(true)}>
                                    Next
                                </Button>
                            </div>
                        </div>
                        }</>
                    }

                    {steps.two && <div>

                        <div className='row bg-smoke'>
                            <div className='col-md-7 p-3'>
                                <Typography

                                    component="h5"
                                    variant="subtitle-1"
                                    color="black"
                                    className="mb-4"
                                >
                                    Quotation
                                </Typography>
                                <div>
                                    {serviceData.length > 0 ? <>
                                        #{serviceData.id}{serviceData.product && ' - ' + serviceData.product.code} <br />
                                        {serviceData.description}
                                    </> : <>
                                        {propertyData.product && propertyData.product.code + '-' + propertyData.product.description}
                                    </>}
                                </div>
                            </div>
                            <div className='col-md-5 p-0'>
                                <div className='invoice-logo text-center p-3'>
                                    <img src={Logo} alt="logo" width='180' style={{ marginBottom: '15px' }} />
                                    <Typography
                                        sx={{ display: 'block' }}
                                        variant="subtitle-1"
                                        color="black"
                                    >
                                        11/17 Baretta Road, Ravenhall, VIC-3023
                                    </Typography>
                                </div>
                            </div>
                        </div>
                        <Box component="form" id="quote-form" name="quote-form" noValidate autoComplete="off" onSubmit={handleSubmit(onQuoteSubmit)}>

                            <div className="row">
                                <div className="col-md-8 p-5">
                                    <strong>Recipient:</strong><br />
                                    {serviceData.length > 0 ? <>
                                        {serviceData.property.address1},<br />
                                        {serviceData.property.address2 && <>{serviceData.property.address2},<br /></>}
                                        {serviceData.property.suburb}, {serviceData.property.state + ' - ' + serviceData.property.postcode}
                                    </> : <>
                                        {propertyData.address1},<br />
                                        {propertyData.address2 && <>{propertyData.address2},<br /></>}
                                        {propertyData.suburb}, {propertyData.state + ' - ' + propertyData.postcode}
                                    </>}
                                </div>
                                <div className="col-md-4 p-5">
                                    <div className="mb-3">
                                        <strong>Quote Number:</strong> {overAll.quote_number ? overAll.quote_number : '#####'}
                                    </div>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            disablePast
                                            className="mt-4"
                                            id="due_date"
                                            name="due_date"
                                            label="Quote Due Date"
                                            format="DD/MM/YYYY"
                                            value={dayjs(overAll.due_date)}
                                            onChange={(newValue) => { setOverAll((prev) => ({ ...prev, due_date: newValue })) }}
                                            readOnly={(_role === 'SYS' || _role === 'ADMSTF') ? false : true}
                                            error={errors.due_date ? true : false}
                                            helperText={errors.due_date?.message}
                                        />
                                    </LocalizationProvider>
                                </div>
                                <div className="col-md-8" style={{ paddingLeft: '3rem' }}>
                                    <TextField
                                        fullWidth
                                        id="subject" name="subject"
                                        variant="outlined"
                                        label="Quote Subject"
                                        {...register("subject", { required: "Subject is required" })}
                                        error={errors.subject ? true : false}
                                        helperText={errors.subject?.message}
                                        inputProps={{ value: overAll.subject ? overAll.subject : '' }}

                                        onChange={(e) => setOverAll((prev) => ({ ...prev, subject: e.target.value }))}
                                    />
                                </div>

                                <div className="col-md-12 p-5">

                                    <div className="row">
                                        <div className="col-md-12 text-end">
                                            <Button type="button" autoFocus className="themeholy-btn btn-primary m-3" onClick={() => addFormFields()}>
                                                Add
                                            </Button>
                                        </div>
                                        <div className="col-md-5 fw-bold">Description</div>
                                        <div className="col-md-1 fw-bold">Quantity #</div>
                                        <div className="col-md-2 fw-bold">Charge AUD</div>
                                        <div className="col-md-3 fw-bold">Amount AUD</div>
                                        <div className="col-md-1 fw-bold"></div>
                                    </div>

                                    {formField.map((element, index) => (
                                        <div className="row" key={index}>


                                            <div className="col-md-5">
                                                <TextField
                                                    fullWidth
                                                    id={"description_" + index}
                                                    name="description"
                                                    variant="outlined"
                                                    inputProps={{ value: element.description, maxLength: 100 }}

                                                    onChange={e => handleFieldChange(index, e)}
                                                />
                                            </div>
                                            <div className="col-md-1">
                                                <TextField
                                                    fullWidth
                                                    id={"quantity_" + index}
                                                    name="quantity"
                                                    variant="outlined"
                                                    inputProps={{ value: element.quantity, maxLength: 2 }}

                                                    onChange={e => handleFieldChange(index, e)}
                                                /></div>
                                            <div className="col-md-2">
                                                <TextField
                                                    fullWidth
                                                    id={"charge_" + index}
                                                    name="charge"
                                                    variant="outlined"
                                                    inputProps={{ value: element.charge, maxLength: 6 }}

                                                    onChange={e => handleFieldChange(index, e)}
                                                /></div>
                                            <div className="col-md-3">
                                                <TextField
                                                    fullWidth
                                                    id={"amount_" + index}
                                                    name="amount"
                                                    variant="outlined"
                                                    inputProps={{ value: element.amount, maxLength: 4, readOnly: true }}

                                                    onChange={e => handleFieldChange(index, e)}
                                                /></div>
                                            <div className="col-md-1 m-auto text-end">
                                                {
                                                    index ?
                                                        <Link to='#' onClick={() => removeFormFields(index)}><i className="fa fa-trash" ></i></Link>
                                                        : null
                                                }
                                            </div>


                                        </div>
                                    ))}

                                    <div className="row mt-3">
                                        <div className="col-md-6 p-3">
                                            Note:
                                            <TextareaAutosize style={{ backgroundColor: 'white', borderColor: '#eee' }}
                                                onChange={(e) => { setOverAll((prev) => ({ ...prev, notes: e.target.value })) }}
                                                value={overAll.notes ? overAll.notes : ''}
                                            >
                                            </TextareaAutosize>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="row text-end fs-xxl fw-bold">
                                                <div className="col-md-8 p-3">
                                                    Subtotal AUD :
                                                </div>
                                                <div className="col-md-4 p-3">
                                                    {overAll.subtotal}
                                                </div>
                                                <div className="col-md-8 p-3">
                                                    GST 10% AUD :
                                                </div>
                                                <div className="col-md-4 p-3">
                                                    {overAll.tax_rate}
                                                </div>
                                                <div className="col-md-8 p-3">
                                                    Total AUD :
                                                </div>
                                                <div className="col-md-4 p-3">
                                                    {overAll.total}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 text-center p-5">

                                    <Collapse in={alertOpen}>
                                        <Alert variant="filled" severity={alertStatus}
                                            action={
                                                <IconButton
                                                    aria-label="close"
                                                    color="inherit"
                                                    size="small"
                                                    onClick={() => {
                                                        setAlertOpen(false);
                                                    }}
                                                >
                                                    <CloseIcon fontSize="inherit" />
                                                </IconButton>
                                            }
                                            sx={{ mb: 2 }}
                                        >
                                            {alertMessage}
                                        </Alert>
                                    </Collapse>

                                    {serviceList &&
                                        <Button type="button" className="themeholy-btn btn-primary m-3" onClick={handleBack}>
                                            Back
                                        </Button>
                                    }
                                    <Button type="button" className="themeholy-btn btn-primary m-3" onClick={handleFormClose}>
                                        Close
                                    </Button>

                                    <Button type="submit" autoFocus className="themeholy-btn btn-primary m-3" disabled={loaderText}>{loaderText ? 'Submitting' : 'Submit'}
                                    </Button>
                                </div>
                            </div>
                        </Box>
                    </div>
                    }

                </div>}

            </div >
        </>
    );
}