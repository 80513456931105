import { useState, useEffect, forwardRef, createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { GridRowsProp, GridColDef } from '@mui/x-data-grid';
import { Alert, IconButton, Collapse, Button, Chip, Dialog, Slide, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import DataTableList from '../DataTableList';
import { apiBaseUrl } from '../../common';
import axios from 'axios';
import { MasterForm } from "./MasterForm";
import { AccountContext } from "../../pages/MyAccount";
import { AppContext } from "../../pages/Main";
export const FormContext = createContext(null);

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Masters = (props) => {
    const navigate = useNavigate(null);

    const _token = localStorage.getItem("_token");
    axios.defaults.withCredentials = true;
    axios.defaults.headers['Accept'] = 'application/json';
    axios.defaults.headers['Content-Type'] = 'application/json';
    axios.defaults.headers['Authorization'] = 'Bearer ' + _token;

    const { handleLogout } = useContext(AppContext);

    const { setBackDropOpen } = useContext(AccountContext);
    const [loader, setLoader] = useState(true);
    const [loaderText, setLoaderText] = useState(false);
    const [DTColumns, setDTColumns] = useState([]);
    const [alertStatus, setAlertStatus] = useState('success');
    const [alertOpen, setAlertOpen] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [confirmopen, setConfirmOpen] = useState(false);
    const [delData, setDelData] = useState({});

    const [masterTypeId, setMasterTypeId] = useState('');
    let mTypeArr = { 'TYPE': 'User Type', 'PREFIX': 'Prefix', 'JBSTS': 'Job Status' }
    const masterFormType = mTypeArr[props.type];

    const [formField, setFormField] = useState({
        'code': '',
        'description': '',
        'status': 'Y',
        'action': '',
    });
    //const [rowKeyVal, setRowKeyVal] = useState(0);
    const [alertMessage, setAlertMessage] = useState('');


    const fetchData = () => {
        setBackDropOpen(true);
        axios.get(apiBaseUrl + 'lkps?type=' + props.type)
            .then(response => response.data).then(resData => {
                //console.log(resData.data);
                if (resData.status === 'success') {
                    setDTColumns(resData.data);
                    setLoader(false);
                } else {
                    setAlertOpen(true);
                    setAlertStatus('error');
                    setAlertMessage(resData.message);
                    handleAutoClose();
                }
                setBackDropOpen(false);

            }).catch((error) => {
                console.log(error);
                if (error.code === "ERR_NETWORK") {
                    error.message = "Something went wrong. Server is not repond to request. Please contact administrator."
                } else if (error.response.status === 401) {
                    handleLogout();
                    navigate('/login');
                }
                setAlertOpen(true);
                setAlertStatus('error');
                setAlertMessage(error.message);
                handleAutoClose();
                setBackDropOpen(false);
            });
    }

    useEffect(() => {
        
        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.type]);

    const rows: GridRowsProp = DTColumns;

    const columns: GridColDef[] = [
        { field: 'sno', headerName: '#', width: 100, renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1 },
        { field: 'code', headerName: 'Code', width: 250 },
        { field: 'description', headerName: 'Description', width: 300 },
        { field: 'status', headerName: 'Status', width: 200, renderCell: (params) => (params.row.status === 'Y' || params.row.status === 'D') ? <Chip label="Active" color="primary" size="small" /> : <Chip label="Inactive" color="warning" size="small" />, valueGetter: (params) => (params.row.status === 'Y') ? 'Active' : 'Inactive' },
        { field: 'actions', headerName: 'Actions', width: 150, sortable: false, renderCell: (params) => { return params.row.status !== 'D' ? <><Button type="button" onClick={(e) => handleEdit(params.api.getRowIndexRelativeToVisibleRows(params.row.id), params.row)} style={{ minWidth: '24px' }}><i className="fa fa-edit"></i></Button> <Button type="button" onClick={(e) => deleteRecord(params.row.id, params.row.category)} style={{ minWidth: '24px' }}><i className="fa fa-trash"></i></Button></>:'' } },
    ];

    const handleEdit = (key, data) => {
        setLoaderText(false);

        setMasterTypeId(data.id);
        //setRowKeyVal(key);
        setFormField({
            'code': data.code,
            'description': data.description,
            'status': data.status,
            'action': 'edit',
        });
        setDialogOpen(true);

        setTimeout(() => { document.getElementById('code').focus() }, 100);
    };

    const handleAdd = () => {
        setLoaderText(false);
        setMasterTypeId('');
        setFormField({
            'code': '',
            'description': '',
            'status': 'Y',
            'action': 'add',
        });
        setDialogOpen(true);
        setTimeout(() => { document.getElementById('code').focus() }, 100);

    };

    const deleteRecord = (id, category) => {
        var data = {
            "id": id,
            "category": category,
            "_method": 'DELETE'
        }
        setDelData(data);
        setConfirmOpen(true);
    }

    const handleDelete = () => {

        axios.defaults.withCredentials = true;
        axios.defaults.headers['Accept'] = 'application/json';
        axios.defaults.headers['Content-Type'] = 'application/json';
        axios.defaults.headers['Authorization'] = 'Bearer ' + _token;

        axios.post(apiBaseUrl + 'lkps/' + delData.id, delData)
            .then(response => response.data).then(resData => {
                setAlertOpen(true);
                if (resData.status === 'success') {
                    setAlertStatus('success');
                    fetchData();
                } else {
                    setAlertStatus('error');
                }
                setAlertMessage(resData.message);
                handleAutoClose();
                setLoaderText(false);
                setConfirmOpen(false);
                setDelData({});
            }).catch((error) => {
                console.log(error);
                setLoaderText(false);
                setAlertOpen(true);
                setAlertStatus('error');
                setAlertMessage('Something went wrong with ' + masterFormType + ' API. Error: ' + error.message);
                handleAutoClose();
                setConfirmOpen(false);
                setDelData({});
            });
    };

    const handleAutoClose = () => {
        window.scrollTo(0, 0);
        setTimeout(() => setAlertOpen(false), 5000);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        setMasterTypeId('');
        setFormField({
            'code': '',
            'description': '',
            'status': 'Y',
            'action': '',
        });
    };

    const handleConfirmClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setConfirmOpen(false);
            setDelData({});
        }
    };

    const onSubmit = (data) => {
        setLoaderText(true);

        //console.log(data);

        axios.defaults.withCredentials = true;
        axios.defaults.headers['Accept'] = 'application/json';
        axios.defaults.headers['Content-Type'] = 'application/json';
        axios.defaults.headers['Authorization'] = 'Bearer ' + _token;

        var axiosCall = null;
        data.category = props.type;

        if (formField.action === 'edit') {
            data._method = 'PUT';
            axiosCall = axios.post(apiBaseUrl + 'lkps/' + masterTypeId, data);
        } else if (formField.action === 'add') {
            axiosCall = axios.post(apiBaseUrl + 'lkps', data);
        }

        axiosCall.then(response => response.data).then(resData => {
            // console.log(rowKeyVal);
            setAlertOpen(true);
            if (resData.status === 'success') {
                setAlertStatus('success');
                handleDialogClose();
                fetchData();
            } else {
                setAlertStatus('error');
            }
            setAlertMessage(resData.message);
            handleAutoClose();
            setLoaderText(false);
        }).catch((error) => {
            console.log(error);
            setLoaderText(false);
            setAlertOpen(true);
            setAlertStatus('error');
            setAlertMessage('Something went wrong with ' + masterFormType + ' API. Error: ' + error.message);
            handleAutoClose();
        });
    };

    return (
        <>
            <Collapse in={alertOpen}>
                <Alert variant="filled" severity={alertStatus}
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setAlertOpen(false);
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    sx={{ mb: 2 }}
                >
                    {alertMessage}
                </Alert>
            </Collapse>

            <Dialog
                open={confirmopen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleConfirmClose}
                aria-describedby="alert-dialog-slide-description"

            >
                <DialogTitle>Are you sure you want to delete this {masterFormType}?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        If yes, {masterFormType} will be deleted from the records and no longer be exists.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className="themeholy-btn btn-secondary" onClick={handleConfirmClose}>Cancel</Button>
                    <Button className="themeholy-btn btn-primary" onClick={() => handleDelete({ delData })}>Yes</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                fullScreen
                open={dialogOpen}
                onClose={handleDialogClose}
                TransitionComponent={Transition}
            >
                <FormContext.Provider value={{
                    handleDialogClose, onSubmit, loaderText, formField, setFormField, alertStatus,
                    alertMessage,
                    alertOpen,
                    setAlertOpen
                }}>
                    <MasterForm formType={props.type} />
                </FormContext.Provider>

            </Dialog>
            <div style={{ display: "flex", marginBottom: "15px" }}>
                <Button className="themeholy-btn btn-primary" style={{ marginLeft: "auto" }} onClick={handleAdd}>Add New {masterFormType}</Button>
            </div>
            <DataTableList columns={columns} rows={rows} loading={loader} />
        </>
    );
}

export default Masters;