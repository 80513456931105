import { useState, useEffect, forwardRef, createContext, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { GridRowsProp, GridColDef } from '@mui/x-data-grid';
import { Alert, IconButton, Collapse, Button, Dialog, Slide, DialogTitle, DialogContent, DialogContentText, DialogActions, Chip } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import DataTableList from '../DataTableList';
import { apiBaseUrl } from '../../common';
import axios from 'axios';
import { UserForm } from "./UserForm";
import { UsersContext } from "../../pages/Users";
import { AppContext } from "../../pages/Main";
import { PostcodeData } from '../../services/PostcodeData';
import { PrefixData } from "../../services/PrefixData";
import { RolesData } from "../../services/RolesData";
import { UserData } from '../../services/UsersData';

export const UserFormContext = createContext(null);

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ManageUsers = (props) => {
    const navigate = useNavigate(null);
    const _token = localStorage.getItem("_token");
    axios.defaults.withCredentials = true;
    axios.defaults.headers['Accept'] = 'application/json';
    axios.defaults.headers['Content-Type'] = 'application/json';
    axios.defaults.headers['Authorization'] = 'Bearer ' + _token;

    const { handleLogout } = useContext(AppContext);

    const { setBackDropOpen } = useContext(UsersContext);
    const [loader, setLoader] = useState(true);
    const [loaderText, setLoaderText] = useState(false);
    const [DTColumns, setDTColumns] = useState([]);
    const [alertStatus, setAlertStatus] = useState('success');
    const [alertOpen, setAlertOpen] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [confirmopen, setConfirmOpen] = useState(false);
    const [delData, setDelData] = useState({});
    const [noteOpen, setNoteOpen] = useState(false);
    const [notes, setNotes] = useState('');
    const [userOptions, setUserOptions] = useState(null);
    const [prefixOptions, setPrefixOptions] = useState(null);
    const [postcodeOptions, setPostcodeOptions] = useState(null);
    const [roleOptions, setRoleOptions] = useState(null);

    const [openForm, setOpenForm] = useState(false);

    const [userId, setUserId] = useState('');
    let uTypeArr = { 'ADMSTF': 'Admin Staff', 'AGENT': 'Agent', 'LDLORD': 'Land Lord', 'TENANT': 'Tenant', 'E-TRADIE': 'Electrician Tradie', 'P-TRADIE': 'Plumber Tradie' }
    const userFormType = uTypeArr[props.type];

    const [formField, setFormField] = useState({
        'firstname': '',
        'lastname': '',
        'agents': [],
        'agency_name': '',
        'company': '',
        'license_number': '',
        'prefix': '',
        'role_id': '',
        'email': '',
        'mobile': '',
        'address1': '',
        'address2': '',
        'suburb': '',
        'state': '',
        'postcode': '',
        'alertnote': '',
        'showalert': '',
        'status': 'Y',
        'action': ''
    });
    //const [rowKeyVal, setRowKeyVal] = useState(0);
    const [alertMessage, setAlertMessage] = useState('');

    const fetchData = () => {
        setBackDropOpen(true);
        axios.get(apiBaseUrl + 'individuals?type=' + props.type)
            .then(response => response.data).then(resData => {
                if (resData.status === 'success') {
                    setDTColumns(resData.data);
                    setLoader(false);
                } else {
                    setAlertOpen(true);
                    setAlertStatus('error');
                    setAlertMessage(resData.message);
                    handleAutoClose();
                }
                setBackDropOpen(false);

            }).catch((error) => {
                console.log(error);
                if (error.code === "ERR_NETWORK") {
                    error.message = "Something went wrong. Server is not repond to request. Please contact administrator."
                } else if (error.response.status === 401) {
                    handleLogout();
                    navigate("/login");
                }
                setAlertOpen(true);
                setAlertStatus('error');
                setAlertMessage(error.message);
                handleAutoClose();
                setBackDropOpen(false);
            });
    }

    const loadData = () => {
        UserData.fetchData().then(userDataList => {
            setUserOptions(userDataList);
            PostcodeData.fetchData().then(postCodeDataList => {
                setPostcodeOptions(postCodeDataList);
                PrefixData.fetchData().then(prefixDataList => {
                    setPrefixOptions(prefixDataList);
                    RolesData.fetchData().then(roleDataList => {
                        setRoleOptions(roleDataList);
                        setOpenForm(true);
                    });
                });
            });
        });
    }

    useEffect(() => {

        fetchData();
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.type]);

    const rows: GridRowsProp = DTColumns;

    const columns: GridColDef[] = [
        { field: 'sno', headerName: '#', width: 50, filterable: false, renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1 },
        { field: 'lastname', headerName: 'Name', width: 150, filterable: true, renderCell: (params) => { return params.row.lastname + ' ' + params.row.firstname }, valueGetter: (params) => params.row.lastname + ' ' + params.row.firstname },
        { field: (props.type === 'AGENT') ? 'agency_name' : (props.type === 'ADMSTF') ? 'role_id' : 'license_number', headerName: (props.type === 'AGENT') ? 'Agency Name' : (props.type === 'ADMSTF') ? 'Role' : 'License Number', renderCell: (params) => (props.type === 'AGENT') ? params.row.agency_name : (props.type === 'ADMSTF') ? params.row.role && params.row.role.description : params.row.license_number, valueGetter: (params) => (props.type === 'AGENT') ? params.row.agency_name : (props.type === 'ADMSTF') ? params.row.role && params.row.role.description : params.row.license_number, width: 150, hide: true, filterable: true },
        { field: 'email', headerName: 'Email', width: 225, filterable: true, renderCell: (params) => { return params.row.individualuser.email }, valueGetter: (params) => params.row.individualuser.email },
        { field: 'mobile', headerName: 'Mobile', width: 125, filterable: true, renderCell: (params) => { return params.row.individualuser.mobile }, valueGetter: (params) => params.row.individualuser.mobile },
        { field: 'address', headerName: 'Address', width: 280, filterable: true, renderCell: (params) => { return (<>{params.row.address1}, {params.row.address2}{params.row.address2 ? ',' : ''} {params.row.suburb},  {params.row.state} - {params.row.postcode}</>) }, valueGetter: (params) => params.row.address1 + ',' + params.row.address2 + ',' + params.row.suburb + ',' + params.row.state + '-' + params.row.postcode },
        { field: 'status', headerName: 'Status', width: 100, filterable: true, renderCell: (params) => (params.row.status === 'Y') ? <Chip label="Active" color="primary" size="small" /> : <Chip label="Inactive" color="warning" size="small" />, valueGetter: (params) => (params.row.status === 'Y') ? 'Active' : 'Inactive' },
        { field: 'actions', headerName: 'Actions', width: 100, sortable: false, filterable: false, renderCell: (params) => { return (<><Button type="button" onClick={(e) => handleEdit(params.api.getRowIndexRelativeToVisibleRows(params.row.id), params.row)} style={{ minWidth: '24px' }}><i className="fa fa-edit"></i></Button> <Button type="button" onClick={(e) => deleteRecord(params.row.id)} style={{ minWidth: '24px' }}><i className="fa fa-trash"></i></Button> {params.row.showalert === 'Y' && <Button type="button" onClick={(e) => viewAlert(params.row.alertnote)} style={{ minWidth: '24px' }}><i className="fa fa-message"></i></Button>}</>) } },
    ];



    const handleEdit = (key, data) => {
        setLoaderText(false);

        setUserId(data.id);
        //setRowKeyVal(key);        

        setFormField({
            'firstname': data.firstname,
            'lastname': data.lastname,
            'agents': data.agents,
            'agency_name': data.agency_name,
            'company': data.company,
            'license_number': data.license_number,
            'prefix': data.prefix,
            'role_id': data.role_id,
            'email': data.individualuser.email,
            'mobile': data.individualuser.mobile,
            'address1': data.address1,
            'address2': data.address2,
            'suburb': data.suburb,
            'state': data.state,
            'postcode': data.postcode,
            'alertnote': data.alertnote,
            'showalert': data.showalert,
            'status': data.status,
            'action': 'edit'
        });

        setDialogOpen(true);

        return true;
    };

    const handleAdd = () => {
        setLoaderText(false);
        setUserId('');

        setFormField({
            'firstname': '',
            'lastname': '',
            'agents': [],
            'agency_name': '',
            'company': '',
            'license_number': '',
            'prefix': '',
            'role_id': '',
            'email': '',
            'mobile': '',
            'address1': '',
            'address2': '',
            'suburb': '',
            'state': '',
            'postcode': '',
            'alertnote': '',
            'showalert': '',
            'status': 'Y',
            'action': 'add'
        });
        setDialogOpen(true);
        return true;
    };

    const deleteRecord = (id) => {
        var data = {
            "id": id,
            "_method": 'DELETE'
        }
        setDelData(data);
        setConfirmOpen(true);
    }

    const handleDelete = () => {

        axios.post(apiBaseUrl + 'individuals/' + delData.id, delData)
            .then(response => response.data).then(resData => {
                setAlertOpen(true);
                if (resData.status === 'success') {
                    setAlertStatus('success');
                    fetchData();
                } else {
                    setAlertStatus('error');
                }
                setAlertMessage(resData.message);
                handleAutoClose();
                setLoaderText(false);
                setConfirmOpen(false);
                setDelData({});
            }).catch((error) => {
                console.log(error);
                setLoaderText(false);
                setAlertOpen(true);
                setAlertStatus('error');
                setAlertMessage('Something went wrong with Individuals API. Error: ' + error.message);
                handleAutoClose();
                setConfirmOpen(false);
                setDelData({});
            });
    };

    const handleAutoClose = () => {
        window.scrollTo(0, 0);
        setTimeout(() => setAlertOpen(false), 5000);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        setUserId('');
        setFormField({
            'firstname': '',
            'lastname': '',
            'agents': [],
            'agency_name': '',
            'company': '',
            'license_number': '',
            'perfix': '',
            'role_id': '',
            'email': '',
            'mobile': '',
            'address1': '',
            'address2': '',
            'suburb': '',
            'state': '',
            'postcode': '',
            'alertnote': '',
            'showalert': '',
            'status': '',
            'action': '',
        });
    };

    const handleConfirmClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setConfirmOpen(false);
            setDelData({});
        }
    };

    const onSubmit = (data) => {
        //console.log(formField);  
        setLoaderText(true);

        //console.log(formField.showalert);

        //console.log(userId);

        data.suburb = formField.suburb;
        data.state = formField.state;
        data.postcode = formField.postcode;
        data.showalert = formField.showalert;
        data.agents = formField.agents;

        //console.log(data); 

        var axiosCall = null;

        if (formField.action === 'edit') {
            data._method = 'PUT';
            axiosCall = axios.post(apiBaseUrl + 'individuals/' + userId, data);
        } else if (formField.action === 'add') {
            data.code = props.type;
            axiosCall = axios.post(apiBaseUrl + 'individuals', data);
        }

        axiosCall.then(response => response.data).then(resData => {

            // console.log(rowKeyVal);
            setAlertOpen(true);
            if (resData.status === 'success') {
                setAlertStatus('success');
                handleDialogClose();
                fetchData();
                //}
            } else {
                setAlertStatus('error');
            }
            setAlertMessage(resData.message);
            handleAutoClose();
            setLoaderText(false);
        }).catch((error) => {
            console.log(error);
            setLoaderText(false);
            setAlertOpen(true);
            setAlertStatus('error');
            setAlertMessage('Something went wrong with ' + userFormType + 's API. Error: ' + error.message);
            handleAutoClose();
        });
    };

    const viewAlert = (notes) => {
        setNoteOpen(true);
        setNotes(notes);
    };

    const handleNoteClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setNoteOpen(false);
            setNotes('');
        }
    };

    return (
        <>
            <Collapse in={alertOpen}>
                <Alert variant="filled" severity={alertStatus}
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setAlertOpen(false);
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    sx={{ mb: 2, id: "alertmessage" }}
                >
                    {alertMessage}
                </Alert>
            </Collapse>

            <Dialog
                open={confirmopen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleConfirmClose}
                aria-describedby="alert-dialog-slide-description"

            >
                <DialogTitle>Are you sure you want to delete this {userFormType}?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        If yes, {userFormType} will be deleted from the records and no longer be exists.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className="themeholy-btn btn-secondary" onClick={handleConfirmClose}>Cancel</Button>
                    <Button className="themeholy-btn btn-primary" onClick={() => handleDelete({ delData })}>Yes</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                fullWidth
                open={noteOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleNoteClose}
                aria-describedby="alert-dialog-slide-notes"

            >
                <DialogTitle sx={{ bgcolor: '#000000', color: '#ffffff', marginBottom: '15px' }}>Notes</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-notes">
                        <i className="fa fa-info-circle"></i> {notes}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className="themeholy-btn btn-primary" onClick={handleNoteClose}>Ok</Button>
                </DialogActions>
            </Dialog>
            {openForm && (
                <Dialog
                    fullScreen
                    open={dialogOpen}
                    onClose={handleDialogClose}
                    TransitionComponent={Transition}
                >

                    <UserFormContext.Provider value={
                        {
                            handleDialogClose,
                            onSubmit,
                            loaderText,
                            formField,
                            setFormField,
                            alertStatus,
                            alertMessage,
                            alertOpen,
                            setAlertOpen,
                            postcodeOptions,
                            prefixOptions,
                            userOptions,
                            roleOptions
                        }
                    }>

                        <UserForm formType={props.type} />
                    </UserFormContext.Provider>

                </Dialog>
            )}
            <div style={{ display: "flex", marginBottom: "15px" }}>
                <Button className="themeholy-btn btn-primary" style={{ marginLeft: "auto" }} onClick={handleAdd}>Add New {userFormType}</Button>
            </div>
            <DataTableList columns={columns} rows={rows} loading={loader} columnVisibilityModel={{ license_number: (props.type === 'E-TRADIE' || props.type === 'P-TRADIE') }} />
        </>
    );
}

export default ManageUsers;