export const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
export const webBaseUrl = process.env.REACT_APP_WEB_BASE_URL;
export const appBaseUrl = process.env.REACT_APP_BASE_URL;

export const placeholder = {
    PREFIX: '[PREFIX]',
    FIRSTNAME: '[FIRST_NAME]',
    LASTNAME: '[LAST_NAME]',
    DUEDATE: '[DUE_DATE]',
    AGENT: '[AGENT]',
    SERVICETYPE: '[SERVICE_TYPE]',
    INVOICENO: '[INVOICE_NUMBER]',
    INVOICEDATE: '[INVOICE_DATE]',
    PAIDAMOUNT: '[PAID_AMOUNT]',
}