import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Box, Button, Alert, IconButton, Collapse, FormControl, InputLabel, InputAdornment, OutlinedInput } from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CloseIcon from '@mui/icons-material/Close';
import { apiBaseUrl } from '../../common';
import axios from 'axios';
import PasswordChecklist from "react-password-checklist"
//import { useNavigate } from 'react-router-dom';

const ChangePassword = () => {
    const _token = localStorage.getItem("_token");
    axios.defaults.withCredentials = true;
    axios.defaults.headers['Accept'] = 'application/json';
    axios.defaults.headers['Content-Type'] = 'application/json';
    axios.defaults.headers['Authorization'] = 'Bearer ' + _token;

    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);


    const { register, handleSubmit, formState: { errors } } = useForm();

    //const navigate = useNavigate(null);
    const [loader, setLoader] = useState(false);
    const [alertStatus, setAlertStatus] = useState('success');
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    const [oldpassword, setOldPassword] = useState("");
    const [password, setPassword] = useState("");
    const [passwordAgain, setPasswordAgain] = useState("");

    const [newpassins, setNewpassins] = useState('hide');
    const [confirmpassins, setConfirmpassins] = useState('hide');
    //let inputRef;

    useEffect(() => {
        //if (inputRef) inputRef.focus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmit = (data) => {
        //console.log(data);

        setLoader(true);
        if (data.old_password) {

            axios.post(apiBaseUrl + 'auth/changepassword', data).then(response => response.data).then(resData => {
                setAlertOpen(true);
                if (resData.status === 'success') {
                    setAlertStatus('success');
                    setOldPassword('');
                    setPassword('');
                    setPasswordAgain('');
                    setNewpassins('hide');
                    setConfirmpassins('hide');
                } else {
                    setAlertStatus('error');
                }
                setAlertMessage(resData.message);
                handleAutoClose();
                setLoader(false);
            }).catch((error) => {
                console.log(error);
                setLoader(false);
                setAlertOpen(true);
                setAlertStatus('error');
                setAlertMessage('Something went wrong with Individual API. Error: ' + error.message);
                handleAutoClose();
            });

        }
    };

    const handleAutoClose = () => {
        window.scrollTo(0, 0);
        setTimeout(() => setAlertOpen(false), 5000);
    };

    const handleClickShowOldPassword = () => {
        setShowOldPassword((show) => !show);
        if (!showOldPassword) setTimeout(() => setShowOldPassword(false), 2000);
    }
    const handleClickShowNewPassword = () => {
        setShowNewPassword((show) => !show);
        if (!showNewPassword) setTimeout(() => setShowNewPassword(false), 2000);
    }
    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword((show) => !show);
        if (!showConfirmPassword) setTimeout(() => setShowConfirmPassword(false), 2000);
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleOldPasswd = (e) => {
        setOldPassword(e.target.value);
        if (e.target.value) {
            if (oldpassword === password) {

            }
        }
    }

    const handleNewPasswd = (e) => {
        setPassword(e.target.value);
        if (e.target.value) {
            setNewpassins('');
        } else {
            setNewpassins('hide');
        }
    }

    const handleConfirmPasswd = (e) => {
        setPasswordAgain(e.target.value)
        if (e.target.value) {
            setConfirmpassins('');
        } else {
            setConfirmpassins('hide');
        }
    }

    return (
        <>

            <Collapse in={alertOpen}>
                <Alert variant="filled" severity={alertStatus}
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setAlertOpen(false);
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    sx={{ mb: 2 }}
                >
                    {alertMessage}
                </Alert>
            </Collapse>

            <Box component="form" id="change-password-form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-lg-6 m-auto">
                            <FormControl fullWidth variant="outlined">
                                <InputLabel htmlFor="old-password">Old Password *</InputLabel>
                                <OutlinedInput
                                    id="old-password"
                                    type={showOldPassword ? 'text' : 'password'}
                                    {...register("old_password", { required: "Old Password is required" })}
                                    /* inputRef={input => {
                                        inputRef = input;
                                    }} */
                                    onChange={handleOldPasswd}
                                    inputProps={{ maxLength: 8, value: oldpassword }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowOldPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showOldPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Old Password *"
                                />
                                <label className="error text-red text-sm text-start">{errors.old_password?.message}</label>
                            </FormControl>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel htmlFor="new-password">New Password *</InputLabel>
                                <OutlinedInput
                                    id="new-password"
                                    type={showNewPassword ? 'text' : 'password'}
                                    {...register("new_password", {
                                        required: "New Password is required", validate: (value) => {
                                            return oldpassword !== value || "New Password should not match!";
                                        }
                                    })}
                                    inputProps={{ maxLength: 8, value: password }}
                                    error={errors.new_password ? true : false}
                                    onChange={handleNewPasswd}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowNewPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showNewPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="New Password *"
                                />
                                <label className="error text-red text-sm text-start">{errors.new_password?.message}</label>
                            </FormControl>
                            <PasswordChecklist fullWidth className={"text-start " + newpassins}
                                rules={["capital", "specialChar", "number", "minLength"]}
                                style={{ width: '35ch' }}
                                iconSize={12}
                                minLength={8}
                                value={password}
                                valueAgain={passwordAgain}
                                onChange={(isValid, failedRules) => { }}
                                messages={{
                                    capital: "New Password has a capital letter.",
                                    specialChar: "New Password has special characters.",
                                    number: "New Password has a number.",
                                    minLength: "New Password has 8 caracteres.",
                                }}
                            />
                            <FormControl fullWidth variant="outlined">
                                <InputLabel htmlFor="confirm-password">Confirm Password *</InputLabel>
                                <OutlinedInput
                                    id="confirm-password"
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    {...register("confirm_password", {
                                        required: "Confirm Password is required", validate: (value) => {
                                            return password === value || "New Password and Confirm Password should be the same!";
                                        }
                                    })}
                                    inputProps={{ maxLength: 8, value: passwordAgain }}
                                    error={errors.confirm_password ? true : false}
                                    onChange={handleConfirmPasswd}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowConfirmPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Confirm Password *"
                                />
                                <label className="error text-red text-sm">{errors.confirm_password?.message}</label>
                            </FormControl>

                            <PasswordChecklist fullWidth className={"text-start " + confirmpassins}
                                rules={["match"]}
                                iconSize={12}
                                value={password}
                                valueAgain={passwordAgain}
                                messages={{
                                    match: "New Password has match with Confirm Password.",
                                }}
                            />
                        </div>
                    </div>
                    <Button type="submit" className="themeholy-btn btn-primary" variant="contained" disabled={loader ? true : false}>{(loader) ? 'Processing...' : 'Change Password'}</Button>
                </div>
            </Box>
        </>
    );
}

export default ChangePassword;