import { useState, useEffect, createContext, forwardRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { GridRowsProp, GridColDef } from '@mui/x-data-grid';
import { Alert, IconButton, Collapse, Button, Dialog, Slide, Chip, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import DataTableList from '../DataTableList';
import { apiBaseUrl } from '../../common';
import axios from 'axios';
import { PropertyForm } from "./PropertyForm";
import { PropertyView } from "./PropertyView";
import { UserData } from '../../services/UsersData';
import { PostcodeData } from '../../services/PostcodeData';
import { LookUpsData } from '../../services/LookUpsData';
import { AppContext } from "../../pages/Main";
import { PropertyContext } from "../../pages/Properties";
import dayjs from 'dayjs';

export const FormContext = createContext(null);

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ManageProperties = () => {
    const navigate = useNavigate(null);
    const _token = localStorage.getItem("_token");
    const _role = localStorage.getItem("_role");
    axios.defaults.withCredentials = true;
    axios.defaults.headers['Accept'] = 'application/json';
    axios.defaults.headers['Content-Type'] = 'application/json';
    axios.defaults.headers['Authorization'] = 'Bearer ' + _token;
    const { handleLogout } = useContext(AppContext);
    const { setBackDropOpen } = useContext(PropertyContext);
    const [loader, setLoader] = useState(true);
    const [loaderText, setLoaderText] = useState(false);
    const [DTColumns, setDTColumns] = useState([]);
    const [alertStatus, setAlertStatus] = useState('success');
    const [alertOpen, setAlertOpen] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogViewOpen, setDialogViewOpen] = useState(false);
    const [confirmopen, setConfirmOpen] = useState(false);
    const [noteOpen, setNoteOpen] = useState(false);
    const [notes, setNotes] = useState('');
    const [delData, setDelData] = useState({});
    const [formChangeFlag, setFormChangeFlag] = useState(false);
    const [viewUpdateFlag, setViewUpdateFlag] = useState(false);
    const [userOptions, setUserOptions] = useState(null);
    const [postcodeOptions, setPostcodeOptions] = useState(null);
    const [lookUpsOptions, setLookUpsOptions] = useState(null);
    const [openForm, setOpenForm] = useState(false);
    const [propertyId, setPropertyId] = useState('');
    const [formField, setFormField] = useState({
        'property_id': '',
        'owner_id': '',
        'agent_id': '',
        'tenancy_name': '',
        'tenancy_mobile': '',
        'agent_name': '',
        'agency_name': '',
        'address1': '',
        'address2': '',
        'suburb': '',
        'state': '',
        'postcode': '',
        'alertnote': '',
        'showalert': '',
        'service_duedate': '',
        //'products': [],
        'product_code': '',
        'product_desc': '',
        'status': 'Y',
        'action': ''
    });

    const [viewField, setViewField] = useState({
        'owner_id': '',
        'agent_id': '',
        'tenancy_name': '',
        'tenancy_mobile': '',
        'owner_prefix': '',
        'owner_name': '',
        'agent_prefix': '',
        'agent_name': '',
        'agency_name': '',
        'owner_email': '',
        'owner_mobile': '',
        'agent_mobile': '',
        'address1': '',
        'address2': '',
        'suburb': '',
        'state': '',
        'postcode': '',
        'owner_address1': '',
        'owner_address2': '',
        'owner_suburb': '',
        'owner_state': '',
        'owner_postcode': '',
        'status': '',
        'payments': {
            'amount': 0.00,
            'received': 0.00,
            'balance': 0.00,
        }
    });

    const [alertMessage, setAlertMessage] = useState('');

    const fetchData = () => {
        setBackDropOpen(true);
        axios.get(apiBaseUrl + 'properties')
            .then(response => response.data).then(resData => {
                //console.log(resData.data);
                if (resData.status === 'success') {
                    setDTColumns(resData.data);
                    setLoader(false);
                } else {
                    setAlertOpen(true);
                    setAlertStatus('error');
                    setAlertMessage(resData.message);
                    handleAutoClose();
                }
                setBackDropOpen(false);

            }).catch((error) => {
                console.log(error);
                if (error.code === "ERR_NETWORK") {
                    error.message = "Something went wrong. Server is not repond to request. Please contact administrator."
                } else if (error.response.status === 401) {
                    handleLogout();
                    navigate("/login");
                }
                setAlertOpen(true);
                setAlertStatus('error');
                setAlertMessage(error.message);
                handleAutoClose();
                setBackDropOpen(false);
            });
    }

    const loadData = () => {

        PostcodeData.fetchData().then(postCodeDataList => {
            setPostcodeOptions(postCodeDataList);
            UserData.fetchData().then(userDataList => {
                setUserOptions(userDataList);
                LookUpsData.fetchData().then(lookUpsDataList => {
                    if (!lookUpsDataList.PRODUCT) {
                        lookUpsDataList.PRODUCT = [];
                    }
                    setLookUpsOptions(lookUpsDataList);
                    setOpenForm(true);
                });
            });
        });

    }

    useEffect(() => {
        if (_role !== 'LDLORD') {
            fetchData();
        }

        loadData();
        // console.log(_token);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const rows: GridRowsProp = DTColumns;

    const columns: GridColDef[] = [
        { field: 'sno', headerName: '#', width: 50, renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1 },
        { field: 'owner.lastname', headerName: 'Land Lord', width: 150, renderCell: (params) => { return params.row.owner.lastname + ' ' + params.row.owner.firstname + ' (' + params.row.owneruser.mobile + ')' }, valueGetter: (params) => params.row.owner.lastname + ' ' + params.row.owner.firstname + ' (' + params.row.owneruser.mobile + ')' },
        { field: 'agent_id', headerName: 'Agent', width: 150, renderCell: (params) => { return (params.row.agent && params.row.agentuser) ? params.row.agent.lastname + ' ' + params.row.agent.firstname + ' (' + params.row.agentuser.mobile + ')' : '' }, valueGetter: (params) => (params.row.agent && params.row.agentuser) ? params.row.agent.lastname + ' ' + params.row.agent.firstname + ' (' + params.row.agentuser.mobile + ')' : '' },
        { field: 'tenancy_name', headerName: 'Tenant', width: 150, renderCell: (params) => { return params.row.tenancy_name + ' (' + params.row.tenancy_mobile + ')' }, valueGetter: (params) => params.row.tenancy_name + ' (' + params.row.tenancy_mobile + ')' },
        { field: 'service_duedate', headerName: 'Service Due Date', width: 130 },
        { field: 'address', headerName: 'Address', width: 170, renderCell: (params) => { return (<>{params.row.address1}<br /> {params.row.address2}</>) }, valueGetter: (params) => params.row.address1 + ' ' + params.row.address2 },
        { field: 'suburb', headerName: 'Suburb', width: 180 },
        { field: 'state', headerName: 'State', width: 80 },
        { field: 'postcode', headerName: 'Postcode', width: 80 },
        { field: 'status', headerName: 'Status', width: 100, renderCell: (params) => (params.row.status === 'Y') ? <Chip label="Active" color="primary" size="small" /> : (params.row.status === 'P') ? <Chip label="Pending" color="error" size="small" /> : <Chip label="Inactive" color="warning" size="small" />, valueGetter: (params) => (params.row.status === 'Y') ? 'Active' : (params.row.status === 'P') ? 'Pending' : 'Inactive' },
        {
            field: 'actions', headerName: 'Actions', width: 100, sortable: false, renderCell: (params) => {
                return (<><Button type="button" onClick={(e) => handleView(params.api.getRowIndexRelativeToVisibleRows(params.row.id), params.row)} style={{ minWidth: '24px' }}><i className="fa fa-eye"></i></Button>
                    {(_role === 'SYS' || _role === 'ADMSTF') && (<><Button type="button" onClick={(e) => handleEdit(params.row)} style={{ minWidth: '24px' }}><i className="fa fa-edit"></i></Button> <Button type="button" onClick={(e) => deleteRecord(params.row.id)} style={{ minWidth: '24px' }}><i className="fa fa-trash"></i></Button></>)}
                    {params.row.showalert === 'Y' && <Button type="button" onClick={(e) => viewAlert(params.row.alertnote)} style={{ minWidth: '24px' }}><i className="fa fa-message"></i></Button>}</>)
            }
        },
    ];

    const handleView = (key, data) => {
        //console.log(data);
        setPropertyId(data.id);
        //setRowKeyVal(key);
        setLoaderText(false);

        setViewField({
            'owner_id': data.owner_id,
            'owner_prefix': data.owner.prefix,
            'owner_name': data.owner.lastname + ' ' + data.owner.firstname,
            'owner_email': data.owneruser.email,
            'owner_mobile': data.owneruser.mobile,
            'owner_address1': data.owner.address1,
            'owner_address2': data.owner.address2,
            'owner_suburb': data.owner.suburb,
            'owner_state': data.owner.state,
            'owner_postcode': data.owner.postcode,
            'agent_id': data.agent_id,
            'agent_prefix': data.agent ? data.agent.prefix : '',
            'tenancy_name': data.tenant_name,
            'tenancy_mobile': data.tenancy_mobile,
            'agent_name': (data.agent.lastname ? data.agent.lastname : '') + ' ' + (data.agent.firstname ? data.agent.firstname : ''),
            'agency_name': data.agent ? data.agent.agency_name : '',
            'agent_mobile': data.agentuser ? data.agentuser.mobile : '',
            'address1': data.address1,
            'address2': data.address2,
            'suburb': data.suburb,
            'state': data.state,
            'postcode': data.postcode,
            //'products': data.products,
            'product': data.product,
            'product_code': data.product_code,
            'product_desc': data.product.description ? data.product.description + ' - $' + data.product.service_charge : '',
            'status': data.status,
            'payments': data.payments,
            'action': 'view'
        });

        // setImages(data.picture);

        setViewUpdateFlag(true);

        if (!dialogViewOpen) setDialogViewOpen(true);
    }

    const viewAlert = (notes) => {
        setNoteOpen(true);
        setNotes(notes);
    };

    const handleAdd = (data) => {
        //setLoaderText(false);
        //console.log(data);    
        //setPropertyId('');

        setFormField({
            'property_id': '',
            'owner_id': (data.owner_id) ? data.owner_id : '',
            'owner_prefix': (data.owner_prefix) ? data.owner_prefix : '',
            'owner_name': (data.owner_name) ? data.owner_name : '',
            'owner_mobile': (data.owner_mbile) ? data.owner_mbile : '',
            'agent_id': (data.agent_id) ? data.agent_id : '',
            'agent_prefix': (data.agent_prefix) ? data.agent_prefix : '',
            'tenancy_name': (data.tenancy_name) ? data.tenancy_name : '',
            'tenancy_mobile': (data.tenancy_mobile) ? data.tenancy_mobile : '',
            'agent_name': (data.agent_name) ? data.agent_name : '',
            'agency_name': (data.agency_name) ? data.agency_name : '',
            'agent_mobile': (data.agent_mobile) ? data.agent_mobile : '',
            'address1': '',
            'address2': '',
            'suburb': '',
            'state': '',
            'postcode': '',
            'alertnote': '',
            'showalert': '',
            'service_duedate': '',
            'picture': [],
            //'products': [],
            'product_code': '',
            'product_desc': '',
            'status': 'Y',
            'action': 'add'
        });
        setDialogOpen(true);
        //setDialogViewOpen(false);
    };

    const handleEdit = (data) => {
        setLoaderText(false);
        //console.log(data);
        //setPropertyId(data.id);  
        const serviceduedate = data.service_duedate.split('/');
        setFormField({
            'property_id': data.id,
            'owner_id': data.owner_id,
            'owner_prefix': data.owner.prefix,
            'owner_name': data.owner.lastname + ' ' + data.owner.firstname,
            'owner_email': data.owneruser.email,
            'owner_mobile': data.owneruser.mobile,
            'agent_id': data.agent_id,
            'agent_prefix': data.agent ? data.agent.prefix : '',
            'tenancy_name': data.tenancy_name,
            'tenancy_mobile': data.tenancy_mobile,
            'agent_name': (data.agent.lastname ? data.agent.lastname + ' ' : '') + (data.agent.firstname ? data.agent.firstname : ''),
            'agency_name': data.agency_name,
            'agent_mobile': data.agentuser ? data.agentuser.mobile : '',
            'address1': data.address1,
            'address2': data.address2,
            'suburb': data.suburb,
            'state': data.state,
            'postcode': data.postcode,
            'alertnote': data.alertnote,
            'service_duedate': serviceduedate[2] + '/' + serviceduedate[1] + '/' + serviceduedate[0],
            //'products': data.products,
            'product_code': data.product_code,
            'product_desc': data.product.description ? data.product.description + ' - $' + data.product.service_charge : '',
            'showalert': data.showalert,
            'status': data.status,
            'action': 'edit'
        });

        setDialogOpen(true);
    };

    const checkFormChange = () => {
        setFormChangeFlag(true);
    };

    const handleAutoClose = () => {
        window.scrollTo(0, 0);
        setTimeout(() => setAlertOpen(false), 5000);
    };

    const handleViewDialogClose = (_, refList) => {
        setDialogViewOpen(false);
        setPropertyId('');
        setViewField({
            'owner_id': '',
            'agent_id': '',
            'tenancy_name': '',
            'tenancy_mobile': '',
            'owner_prefix': '',
            'owner_name': '',
            'agent_prefix': '',
            'agent_name': '',
            'agency_name': '',
            'owner_email': '',
            'owner_mobile': '',
            'agent_mobile': '',
            'address1': '',
            'address2': '',
            'suburb': '',
            'state': '',
            'postcode': '',
            'owner_address1': '',
            'owner_address2': '',
            'owner_suburb': '',
            'owner_state': '',
            'owner_postcode': '',
            'status': '',
            'payments': {
                'amount': 0.00,
                'received': 0.00,
                'balance': 0.00,
            }
        });
        if (refList) fetchData();
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        setFormField({
            'property_id': '',
            'owner_id': '',
            'agent_id': '',
            'tenancy_name': '',
            'tenancy_mobile': '',
            'agent_name': '',
            'agency_name': '',
            'address1': '',
            'address2': '',
            'suburb': '',
            'state': '',
            'postcode': '',
            'alertnote': '',
            'showalert': '',
            'service_duedate': '',
            'picture': [],
            'products': [],
            'product_code': '',
            'product_desc': '',
            'status': '',
            'action': '',
        });

    };

    const deleteRecord = (id) => {
        var data = {
            "id": id,
            "_method": 'DELETE'
        }
        setDelData(data);
        setConfirmOpen(true);
    }

    const handleDelete = () => {
        axios.post(apiBaseUrl + 'properties/' + delData.id, delData)
            .then(response => response.data).then(resData => {
                setAlertOpen(true);
                if (resData.status === 'success') {
                    setAlertStatus('success');
                    fetchData();
                } else {
                    setAlertStatus('error');
                }
                setAlertMessage(resData.message);
                handleAutoClose();
                //setLoaderText(false);
                setConfirmOpen(false);
                setDelData({});
            }).catch((error) => {
                console.log(error);
                //setLoaderText(false);
                setAlertOpen(true);
                setAlertStatus('error');
                setAlertMessage('Something went wrong with Property API. Error: ' + error.message);
                handleAutoClose();
                setConfirmOpen(false);
                setDelData({});
            });
    };

    const handleConfirmClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setConfirmOpen(false);
            setDelData({});
        }
    };

    const handleNoteClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setNoteOpen(false);
            setNotes('');
        }
    };

    const onSubmit = (data) => {
        setLoaderText(true);

        if (_role === 'AGENT') {
            data.agent_id = formField.agent_id;

            if (formField.action === 'add') {
                data.status = 'P';
            }
        }
        if (formField.owner_id !== data.owner_id) data.owner_id = formField.owner_id;
        if (formField.agent_id !== data.agent_id) data.agent_id = formField.agent_id;

        // console.log(formField);


        data.suburb = formField.suburb;
        data.state = formField.state;
        data.postcode = formField.postcode;

        data.service_duedate = dayjs(formField.service_duedate).format('DD-MM-YYYY');

        //data.products = formField.products;
        data.product_code = formField.product_code;

        if (!data.showalert) {
            data.showalert = formField.showalert === 'Y' ? 'Y' : 'N';
        }

        // console.log(data); 

        //console.log(formChangeFlag);
        // console.log(formField.property_id);

        if (formChangeFlag) {

            var axiosCall = null;

            //console.log(formField.action);

            //console.log(data);

            if (formField.action === 'edit') {
                data._method = 'PUT';
                axiosCall = axios.post(apiBaseUrl + 'properties/' + formField.property_id, data);
            } else if (formField.action === 'add') {
                axiosCall = axios.post(apiBaseUrl + 'properties', data);
            }

            axiosCall.then(response => response.data).then(resData => {
                setAlertOpen(true);
                if (resData.status === 'success') {
                    setAlertStatus('success');
                    handleDialogClose();
                    fetchData();
                    setViewUpdateFlag(false);
                    //setFormField((prev) => ({ ...prev, owner_id: data.owner_id }));
                    setPropertyId(resData.item.id);
                } else {
                    setAlertStatus('error');
                }
                setAlertMessage(resData.message);
                handleAutoClose();
                setLoaderText(false);
            }).catch((error) => {
                console.log(error);
                setLoaderText(false);
                setAlertOpen(true);
                setAlertStatus('error');
                setAlertMessage('Something went wrong with Properties API. Error: ' + error.message);
                handleAutoClose();
            });

        } else {
            handleDialogClose();
            setViewUpdateFlag(true);
            //setFormField((prev) => ({ ...prev, owner_id: data.owner_id }));
            //if(!dialogViewOpen) setDialogViewOpen(true);
        }
    };

    return (
        <>

            <Dialog
                fullScreen
                open={dialogOpen}
                onClose={handleDialogClose}
                TransitionComponent={Transition}
            >

                <FormContext.Provider value={
                    {
                        handleDialogClose,
                        onSubmit,
                        loaderText,
                        formField,
                        setFormField,
                        alertStatus,
                        alertMessage,
                        alertOpen,
                        setAlertOpen,
                        userOptions,
                        setUserOptions,
                        postcodeOptions,
                        lookUpsOptions,
                        propertyId,
                        checkFormChange,
                        openForm
                    }
                }>

                    <PropertyForm />
                </FormContext.Provider>

            </Dialog>


            {_role !== 'LDLORD' ? (
                <>

                    <Collapse in={alertOpen}>
                        <Alert variant="filled" severity={alertStatus}
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setAlertOpen(false);
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                            sx={{ mb: 2, id: "alertmessage" }}
                        >
                            {alertMessage}
                        </Alert>
                    </Collapse>

                    <Dialog
                        open={confirmopen}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleConfirmClose}
                        aria-describedby="alert-dialog-slide-description"

                    >
                        <DialogTitle>Are you sure you want to delete this property?</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                                If yes, property will be deleted from the records and no longer be exists.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button className="themeholy-btn btn-secondary" onClick={handleConfirmClose}>Cancel</Button>
                            <Button className="themeholy-btn btn-primary" onClick={() => handleDelete({ delData })}>Yes</Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        fullWidth
                        open={noteOpen}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleNoteClose}
                        aria-describedby="alert-dialog-slide-notes"

                    >
                        <DialogTitle sx={{ bgcolor: '#000000', color: '#ffffff', marginBottom: '15px' }}>Notes</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-notes">
                                <i className="fa fa-info-circle"></i> {notes}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button className="themeholy-btn btn-primary" onClick={handleNoteClose}>Ok</Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        fullScreen
                        open={dialogViewOpen}
                        onClose={handleViewDialogClose}
                        TransitionComponent={Transition}
                    >
                        <FormContext.Provider value={
                            {
                                handleViewDialogClose,
                                setLoaderText,
                                handleAdd,
                                handleEdit,
                                handleView,
                                viewField,
                                setViewField,
                                userOptions,
                                postcodeOptions,
                                propertyId,
                                setPropertyId,
                                viewUpdateFlag
                            }
                        }>

                            <PropertyView />
                        </FormContext.Provider>

                    </Dialog>

                    <div style={{ display: "flex", marginBottom: "15px" }}>
                        <Button className="themeholy-btn btn-primary" style={{ marginLeft: "auto" }} onClick={() => handleAdd({})}>Add New Property</Button>
                    </div>
                    <DataTableList columns={columns} rows={rows} loading={loader} /></>)
                : (
                    <FormContext.Provider value={
                        {
                            handleViewDialogClose,
                            setLoaderText,
                            handleAdd,
                            handleEdit,
                            handleView,
                            formField,
                            setFormField,
                            userOptions,
                            postcodeOptions,
                            propertyId,
                            setPropertyId,
                            viewField,
                            setViewField,
                            viewUpdateFlag
                        }
                    }>

                        <PropertyView />
                    </FormContext.Provider>
                )}

        </>
    );
}

export default ManageProperties;