import { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import DataTableList from '../components/DataTableList';
import { Chip, Button, Drawer } from "@mui/material";
import InvoiceDialog from "../components/Modal/Invoice";
import { QuoteService } from "../components/Modal/QuoteForm";
import ServiceReportDialog from "../components/Modal/ServiceReport";
import { apiBaseUrl } from '../common';
import axios from 'axios';

const _token = localStorage.getItem("_token");
//const _role = localStorage.getItem("_role");
axios.defaults.withCredentials = true;
axios.defaults.headers['Accept'] = 'application/json';
axios.defaults.headers['Content-Type'] = 'application/json';
axios.defaults.headers['Authorization'] = 'Bearer ' + _token;


export const Quotation = ({ propertyId }) => {
    const [DTColumns, setDTColumns] = useState([]);
    const [loader, setLoader] = useState(true);
    const [drawerOpen, setDrawerOpen] = useState(false);

    const fetchData = () => {
        const params = { 'property_id': propertyId }
        axios.get(apiBaseUrl + 'quotes', { params })
            .then(response => response.data).then(resData => {
                //console.log(resData.data);
                if (resData.status === 'success') {
                    setDTColumns(resData.data);
                    setLoader(false);
                }

            }).catch((error) => {
                console.log(error);
                if (error.code === "ERR_NETWORK") {
                    error.message = "Something went wrong. Server is not repond to request. Please contact administrator."
                } else if (error.response.status === 401) {
                    // handleLogout();
                    // navigate("/login");
                }

            });
    }

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [propertyId]);


    const handleView = () => {

    }


    const rows: GridRowsProp = DTColumns;

    const columns: GridColDef[] = [
        { field: 'sno', headerName: '#', width: 50, filterable: false, renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1 },
        { field: 'quote_number', headerName: 'Quote #', width: 100, filterable: true },
        { field: 'subject', headerName: 'Subject', width: 150, filterable: true },
        { field: 'description', headerName: 'Description', width: 150, filterable: true },
        { field: 'subtotal', headerName: 'Sub Total AUD', width: 100, filterable: true, renderCell: (params) => '$' + params.row.subtotal },
        { field: 'tax_rate', headerName: 'Tax Rate AUD', width: 150, filterable: true, renderCell: (params) => '$' + params.row.tax_rate },
        { field: 'total', headerName: 'Total AUD', width: 100, filterable: true, renderCell: (params) => '$' + params.row.total },
        { field: 'due_date', headerName: 'Due Date', width: 150, filterable: true },
        { field: 'actions', headerName: 'Actions', width: 100, sortable: false, renderCell: (params) => { return (<Button type="button" onClick={(e) => handleView(params.row)} style={{ minWidth: '24px' }}><i className="fa fa-eye"></i></Button>) } }
    ];


    return (
        <Fragment>
            <div style={{ display: "flex", marginBottom: "15px" }}>
                <Button className="themeholy-btn btn-primary" style={{ marginLeft: "auto" }} onClick={() => setDrawerOpen(true)}>Add New Quote</Button>
            </div>
            <DataTableList columns={columns} rows={rows} loading={loader} />
            {drawerOpen && <Drawer anchor="right" open={drawerOpen} sx={{ zIndex: 1400 }} PaperProps={{ className: 'container p-0' }}>
                <QuoteService propertyId={propertyId} setDrawerOpen={setDrawerOpen} />
            </Drawer>}
        </Fragment>
    );
}

export const Invoice = ({ propertyId, propertyData }) => {
    const [DTColumns, setDTColumns] = useState([]);
    const [loader, setLoader] = useState(true);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [invoiceData, setInvoiceData] = useState({});
    const [formData, setFormData] = useState({});
    const [drawerOpen, setDrawerOpen] = useState(false);

    const fetchQuoteInvoiceData = () => {
        const params = { 'property_id': propertyId }
        axios.get(apiBaseUrl + 'quotes', { params })
            .then(response => response.data).then(resData => {
                //console.log(resData.data);
                if (resData.status === 'success') {
                    setDTColumns(resData.data);
                    setLoader(false);
                }

            }).catch((error) => {
                console.log(error);
                if (error.code === "ERR_NETWORK") {
                    error.message = "Something went wrong. Server is not repond to request. Please contact administrator."
                } else if (error.response.status === 401) {
                    // handleLogout();
                    // navigate("/login");
                }

            });
    }

    useEffect(() => {
        fetchQuoteInvoiceData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [propertyId]);

    /*  const handleView = (invoiceData) => {
         setInvoiceData(invoiceData);
         setDialogOpen(true);
     } */

    const handleEdit = (data) => {
        data.action = 'edit';
        setFormData(data);
        setInvoiceData({});
        setDrawerOpen(true);
    }

    const handleDialogClose = () => {
        setFormData({});
        setDialogOpen(false);
    }

    const rows: GridRowsProp = DTColumns;

    const columns: GridColDef[] = [
        { field: 'sno', headerName: '#', width: 50, filterable: false, renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1 },
        { field: 'quote_number', headerName: 'Quote #', width: 100, filterable: true },
        { field: 'subject', headerName: 'Subject', width: 250, filterable: true },
        { field: 'subtotal', type: 'number', headerName: 'Subtotal AUD', width: 150, filterable: true, renderCell: (params) => params.row.subtotal.toFixed(2), valueGetter: (params) => params.row.subtotal.toFixed(2) },
        { field: 'tax_rate', type: 'number', headerName: 'Tax Rate AUD', width: 150, filterable: true, renderCell: (params) => params.row.tax_rate.toFixed(2), valueGetter: (params) => params.row.tax_rate.toFixed(2) },
        { field: 'total', type: 'number', headerName: 'Amount AUD', width: 150, filterable: true, renderCell: (params) => params.row.total.toFixed(2), valueGetter: (params) => params.row.total.toFixed(2) },
        { field: 'due_date', headerName: 'Send Due Date', width: 150, filterable: true },
        //{ field: 'paid_status', headerName: 'Paid Status', width: 150, sortable: false, filterable: false, renderCell: (params) => (params.row.paid_status === 'Y') ? <Chip label="Paid" color="primary" size="small" /> : <Chip label="Pay Now" color="warning" size="small" />, valueGetter: (params) => (params.row.status === 'Y') ? 'Paid' : 'Pay Now' },
        { field: 'actions', headerName: 'Actions', width: 100, sortable: false, renderCell: (params) => { return (<Button type="button" onClick={(e) => handleEdit(params.row)} style={{ minWidth: '24px' }}><i className="fa fa-pencil"></i></Button>) } }
    ];

    /* const handleAdd = () => {

    } */

    return (
        <Fragment>
            <div className='text-end mb-3'>
                <Button className="themeholy-btn btn-primary" onClick={() => setDrawerOpen(true)}>Add New Quote</Button>

                {/* <Button className="themeholy-btn btn-primary" style={{ marginLeft: '15px' }} onClick={handleAdd}>Add New Invoice</Button> */}
            </div>
            <DataTableList columns={columns} rows={rows} loading={loader} />

            {drawerOpen && <Drawer anchor="right" open={drawerOpen} sx={{ zIndex: 1400 }} >
                <QuoteService propertyId={propertyId} propertyData={propertyData} setDrawerOpen={setDrawerOpen} formData={formData} fetchQuoteInvoiceData={fetchQuoteInvoiceData} />
            </Drawer>}

            {dialogOpen && <InvoiceDialog dialogOpen={true} handleDialogClose={handleDialogClose} invoiceData={invoiceData} />}


        </Fragment>
    );
}

export const PaymentHistory = () => {

    const [loader, setLoader] = useState(true);

    const rows: GridRowsProp = [
        { id: 1, payment_date: '01/04/2024', paid_amount: '1000', transaction_id: '6353524354', payment_mode: 'Credit Card', created_by: 'Admin' },
        { id: 2, payment_date: '02/04/2024', paid_amount: '1200', transaction_id: '3543544438', payment_mode: 'Bank Transfer', created_by: 'Agent' },
    ];

    const columns: GridColDef[] = [
        { field: 'sno', headerName: '#', width: 100, filterable: false, renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1 },
        { field: 'payment_date', headerName: 'Payment Date', width: 200, filterable: true },
        { field: 'paid_amount', headerName: 'Paid Amount', width: 200, filterable: true, renderCell: (params) => '$' + params.row.paid_amount },
        { field: 'transaction_id', headerName: 'Transaction ID', width: 250, filterable: true },
        { field: 'payment_mode', headerName: 'Payment Mode', width: 250, filterable: true },
        { field: 'created_by', headerName: 'Created By', width: 250, sortable: false, filterable: false },

    ];

    useEffect(() => {
        setLoader(false);
    }, []);

    const handleAdd = () => {

    }

    return (
        <Fragment>
            <div style={{ display: "flex", marginBottom: "15px" }}>
                <Button className="themeholy-btn btn-primary" style={{ marginLeft: "auto" }} onClick={handleAdd}>Add New Payment</Button>
            </div>
            <DataTableList columns={columns} rows={rows} loading={loader} />
        </Fragment>
    );
}

export const Activities = () => {

    const [loader, setLoader] = useState(true);

    const rows: GridRowsProp = [
        { id: 1, activity_date: '01/04/2024', activity_type: 'Electrical', check_in: '09:24 AM', check_out: '03:00 PM', duration: '06:24', category: 'Maintenance', service_type: 'Electrical Safety Checks', comments: 'The job will complete in 1 day' },
        { id: 2, activity_date: '02/04/2024', activity_type: 'Plumbing', check_in: '10:00 AM', check_out: '05:00 PM', duration: '08:00', category: 'On Call', service_type: 'Plumbing Safety Checks', comments: 'The plumbing work has been completed. invoice to be generated.' },
        { id: 3, activity_date: '03/04/2024', activity_type: 'Electrical', check_in: '09:00 AM', check_out: '05:00 PM', duration: '09:00', category: 'Replacement', service_type: 'Smoke Alarm Safety Checks', comments: 'Replaced smoke alarm' },
        { id: 4, activity_date: '04/04/2024', activity_type: 'Electrical', check_in: '10:10 AM', check_out: '05:00 PM', duration: '09:10', category: 'Replacement', service_type: 'Gas Safety Checks', comments: '' },
    ];

    const columns: GridColDef[] = [
        { field: 'sno', headerName: '#', width: 50, filterable: false, renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1 },
        { field: 'activity_date', headerName: 'Activity Date', width: 100, filterable: true },
        { field: 'activity_type', headerName: 'Activity Type', width: 150, filterable: true },
        { field: 'check_in', headerName: 'Checkin', width: 100, filterable: true },
        { field: 'check_out', headerName: 'Checkout', width: 100, filterable: true },
        { field: 'duration', headerName: 'Duration', width: 100, sortable: false, filterable: false },
        { field: 'category', headerName: 'Category', width: 150, sortable: false, filterable: false },
        { field: 'service_type', headerName: 'Service Type', width: 250, sortable: false, filterable: false },
        { field: 'comments', headerName: 'Comments', width: 250, sortable: false, filterable: false },
    ];

    useEffect(() => {
        setLoader(false);
    }, []);

    const handleAdd = () => {

    }

    return (
        <Fragment>
            <div style={{ display: "flex", marginBottom: "15px" }}>
                <Button className="themeholy-btn btn-primary" style={{ marginLeft: "auto" }} onClick={handleAdd}>Add New Activity</Button>
            </div>
            <DataTableList columns={columns} rows={rows} loading={loader} />
        </Fragment>
    );
}

export const Appointments = () => {

    const [loader, setLoader] = useState(true);

    const rows: GridRowsProp = [
        { id: 1, appointment_date: '30/03/2024', activity_time: '09:00 AM', service_type: 'Electrical Safety Checks', tech_person: 'Michael Doeal', job_status: 'Owner Agreed the Appointment', reschedled: 'N' },
        { id: 2, appointment_date: '20/04/2024', activity_time: '', service_type: 'Plumbing Safety Checks', tech_person: 'Daniel Joe', job_status: 'Rescheduled by Owner', reschedled: 'Y' },

    ];

    const columns: GridColDef[] = [
        { field: 'sno', headerName: '#', width: 50, filterable: false, renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1 },
        { field: 'appointment_date', headerName: 'Appointment Date', width: 150, filterable: true },
        { field: 'activity_time', headerName: 'Activity Time', width: 100, filterable: true },
        { field: 'service_type', headerName: 'Service Type', width: 200, filterable: true },
        { field: 'tech_person', headerName: 'Tech Person', width: 250, sortable: false, filterable: false },
        { field: 'job_status', headerName: 'Job Status', width: 250, sortable: false, filterable: false, renderCell: (params) => <Chip label={params.row.job_status} color="secondary" size="small" /> },
        { field: 'rescheduled', headerName: 'Rescheduled', width: 150, sortable: false, filterable: false, renderCell: (params) => (params.row.reschedled === 'Y') ? <Chip label="Yes" color="primary" size="small" /> : <Chip label="No" color="warning" size="small" />, valueGetter: (params) => (params.row.reschedled === 'Y') ? 'Yes' : 'No' },
    ];

    useEffect(() => {
        setLoader(false);
    }, []);

    const handleAdd = () => {

    }

    return (
        <Fragment>
            <div style={{ display: "flex", marginBottom: "15px" }}>
                <Button className="themeholy-btn btn-primary" style={{ marginLeft: "auto" }} onClick={handleAdd}>Add New Appointment</Button>
            </div>
            <DataTableList columns={columns} rows={rows} loading={loader} />
        </Fragment>
    );
}

export const ChangeLogs = () => {

    const [loader, setLoader] = useState(true);

    const rows: GridRowsProp = [
        { id: 1, updated_at: '10/03/2024 09:00 AM', changed_field: 'Email ID', old: 'Email ID', new: 'Email Address', updated_by: 'Ag001' },
        { id: 2, updated_at: '05/04/2024 09:23 AM', changed_field: 'Phone', old: 'Mobile', new: 'Mobile Number', updated_by: 'Cl001' },

    ];

    const columns: GridColDef[] = [
        { field: 'sno', headerName: '#', width: 50, filterable: false, renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1 },
        { field: 'updated_at', headerName: 'Changed Date & Time', width: 250, filterable: true },
        { field: 'changed_field', headerName: 'Changed Field', width: 200, filterable: true },
        { field: 'old', headerName: 'Old Value', width: 200, filterable: true },
        { field: 'new', headerName: 'New Value', width: 250, sortable: false, filterable: false },
        { field: 'updated_by', headerName: 'Updated By', width: 250, sortable: false, filterable: false },
    ];

    useEffect(() => {
        setLoader(false);
    }, []);

    return (
        <Fragment>
            <DataTableList columns={columns} rows={rows} loading={loader} />
        </Fragment>
    );
}

export const ServiceReport = ({ propertyId }) => {

    const [DTColumns, setDTColumns] = useState([]);
    const [loader, setLoader] = useState(true);
    const [jobData, setJobData] = useState({});
    const [dialogOpen, setDialogOpen] = useState(false);

    const fetchData = () => {
        const params = { 'property_id': propertyId }
        axios.get(apiBaseUrl + 'services', { params })
            .then(response => response.data).then(resData => {
                //console.log(resData.data);
                if (resData.status === 'success') {
                    setDTColumns(resData.data);
                    setLoader(false);
                }

            }).catch((error) => {
                console.log(error);
                if (error.code === "ERR_NETWORK") {
                    error.message = "Something went wrong. Server is not repond to request. Please contact administrator."
                } else if (error.response.status === 401) {
                    // handleLogout();
                    // navigate("/login");
                }

            });
    }

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [propertyId]);

    const showText = (params) => {
        return (<>{params.row.product_code} {params.row.invoices && <><br />Invoice Number: {params.row.invoices.invoice_number}</>}</>);
    }


    const rows: GridRowsProp = DTColumns;

    const columns: GridColDef[] = [
        { field: 'sno', headerName: '#', width: 150, filterable: false, renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1 },
        { field: 'service_date', headerName: 'Service Date', width: 180, filterable: true },
        { field: 'job_date', headerName: 'Job Date', width: 180, filterable: true },
        { field: 'product_code', headerName: 'Service', width: 250, renderCell: (params) => showText(params), valueGetter: (params) => params.row.product_code + ' ' + (params.row.invoices ? params.row.invoices.invoice_number : '') },
        { field: 'technician_id', headerName: 'Technician', width: 180, renderCell: (params) => (params.row.technician.lastname ? params.row.technician.lastname : '') + ' ' + (params.row.technician.firstname ? params.row.technician.firstname : ''), valueGetter: (params) => (params.row.technician.lastname ? params.row.technician.lastname : '') + ' ' + (params.row.technician.firstname ? params.row.technician.firstname : '') },
        { field: 'action', headerName: 'Action', width: 200, sortable: false, renderCell: (params) => { return (<><Button type="button" onClick={() => { openServiceReport(params.row) }}><i className="fa fa-download"></i></Button></>) } }
    ];

    //<Link to="https://safetyninja.360membershipcrm.com.au/ElectricalSafetyChecklist.pdf" target="_blank"><Button type="button"><i className="fa fa-th-list"></i></Button></Link>

    const openServiceReport = (data) => {
        setJobData(data);
        setDialogOpen(true);
    }


    const handleDialogClose = () => {
        setDialogOpen(false);
    }

    return (
        <Fragment>
            <DataTableList columns={columns} rows={rows} loading={loader} />
            {dialogOpen && <ServiceReportDialog dialogOpen={true} handleDialogClose={handleDialogClose} jobData={jobData} />}
        </Fragment>
    );
}

export const ECertificate = () => {

    const [loader, setLoader] = useState(true);

    const rows: GridRowsProp = [
        { id: 1, created_at: '20/03/2024 09:00 AM', certificate: 'complaince_services_10302023.pdf', action: '' },
        { id: 2, created_at: '05/04/2024 09:23 AM', certificate: 'complaince_services_10202023.pdf', action: '' },

    ];

    const columns: GridColDef[] = [
        { field: 'sno', headerName: '#', width: 50, filterable: false, renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1 },
        { field: 'created_at', headerName: 'Date & Time', width: 250, filterable: true },
        { field: 'certificate', headerName: 'Certificate', width: 300, filterable: true, renderCell: (params) => <Link to="#">{params.row.certificate}</Link> },
        { field: 'action', headerName: 'Action', width: 200, sortable: false, renderCell: (params) => { return (<><Button type="button"><i className="fa fa-eye"></i></Button> <Button type="button"><i className="fa fa-download"></i></Button></>) } }
    ];

    useEffect(() => {
        setLoader(false);
    }, []);

    return (
        <Fragment>
            <DataTableList columns={columns} rows={rows} loading={loader} />
        </Fragment>
    );
}


export const CommunicationHistory = () => {

    const [loader, setLoader] = useState(true);

    const rows: GridRowsProp = [];

    const columns: GridColDef[] = [
        { field: 'sno', headerName: '#', width: 50, filterable: false, renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1 },
        { field: 'created_at', headerName: 'Date & Time', width: 250, filterable: true },
        { field: 'communicated_by', headerName: 'Via', width: 200, filterable: true },
        { field: 'from', headerName: 'From', width: 200, filterable: true },
        { field: 'to', headerName: 'To', width: 250, sortable: false, filterable: false },
        { field: 'subject', headerName: 'Subject', width: 250, sortable: false, filterable: false },
    ];

    useEffect(() => {
        setLoader(false);
    }, []);

    return (
        <Fragment>
            <DataTableList columns={columns} rows={rows} loading={loader} />
        </Fragment>
    );
}