import React, { useEffect, useState, createContext } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from "./Login";
import Register from "./Register";
import Dashboard from "./Dashboard";
//import {webBaseUrl} from '../common';
import MyAccount from "./MyAccount";
import Users from "./Users";
import Properties from "./Properties";
import Jobs from "./Jobs";
import NoPage from './NoPage';

import { ThemeProvider, createTheme } from '@mui/material/styles';

const THEME = createTheme({
    typography: {
        allVariants: {
            "fontFamily": `'Oswald', sans-serif`
        }
    }
});

export const AppContext = createContext(null);

const Main = () => {
    const [authenticated, setAuthenticated] = useState(false);
    const _token = localStorage.getItem("_token");
    const _role = localStorage.getItem("_role");

    useEffect(() => {
        if (_token) setAuthenticated(true);
        //console.log(_role);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // eslint-disable-next-line
    const handleLogout = () => {
        localStorage.removeItem('_profile_id');
        localStorage.removeItem('_role');
        localStorage.removeItem('_token');
        localStorage.removeItem('_token_expires');
        setAuthenticated(false);
    };

    return (
        <ThemeProvider theme={THEME}>
            <AppContext.Provider value={{ authenticated, handleLogout }}>
                <Router>
                    <Routes>
                        <Route basename='/*'>
                            <Route index element={(authenticated) ? ((_role !== 'LDLORD' && _role !== 'AGENT') ? <Dashboard /> : ((_role === 'AGENT') ? <Users /> : <Properties />)) : <Login />} />
                            <Route path="login" element={<Login />} />
                            <Route path="landlord-register" element={<Register type="LDLORD" />} />
                            <Route path="agent-register" element={<Register type="AGENT" />} />
                            <Route path="dashboard" element={<Dashboard />} />
                            <Route path="my-account/*" element={<MyAccount />} />

                            {(!_role || (_role && (_role === 'SYS' || _role === 'ADMSTF'))) && <Route path="users/*" element={<Users />} />}

                            {(!_role || (_role && _role === 'AGENT')) && <Route path="land-lords/*" element={<Users />} />}

                            {(!_role || (_role && (_role !== 'E-TRADIE' && _role !== 'P-TRADIE'))) && <Route path="properties" element={<Properties />} />}
                            <Route path="jobs" element={<Jobs />} />
                            <Route path="*" element={<NoPage />} />
                        </Route>
                    </Routes>
                </Router>
            </AppContext.Provider>
        </ThemeProvider>
    );
}

export default Main;