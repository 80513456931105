import { useState, useEffect, useContext, Fragment, createContext } from "react";
import { useForm, Controller } from "react-hook-form";
import {
    Alert, Collapse, Box, TextField, IconButton, Button, AppBar, Toolbar, Typography,
    FormControl, InputLabel, Select, MenuItem, Autocomplete, Checkbox, FormControlLabel,
    createFilterOptions, CircularProgress//, Chip
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { FormContext } from "./ManageProperties";
//import ImageUploading from "react-images-uploading";
import { PatternFormat } from 'react-number-format';
import { apiBaseUrl } from '../../common';
import axios from 'axios';
import { DialogUserForm } from "../Modal/DialogUserForm";
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
//import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
/* import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox'; */

/* const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />; */

const filter = createFilterOptions();

export const UserFormContext = createContext(null);

export const PropertyForm = () => {

    const _token = localStorage.getItem("_token");
    const _role = localStorage.getItem("_role");
    axios.defaults.withCredentials = true;
    axios.defaults.headers['Accept'] = 'application/json';
    axios.defaults.headers['Content-Type'] = 'application/json';
    if (_token) axios.defaults.headers['Authorization'] = 'Bearer ' + _token;

    const { handleDialogClose, onSubmit, loaderText, formField, setFormField, alertStatus, alertMessage, alertOpen, setAlertOpen, userOptions, setUserOptions, postcodeOptions, checkFormChange, propertyId, lookUpsOptions, openForm } = useContext(FormContext);

    const [dialogValue, setDialogValue] = useState('');

    const [userFormType, setUserFormType] = useState('');
    const [dateError, setDateError] = useState(false);

    const [addDialogOpen, toggleAddDialog] = useState(false);

    const [pcodeValue, setPcodeValue] = useState({ code: formField.postcode, suburb: formField.suburb });
    //const [pcodeInput, setPcodeInput] = useState(formField.suburb);
    const actionFun = formField.action;

    const [agentData, setAgentData] = useState([]);

    const [ownerInput, setOwnerInput] = useState(formField.owner_name + (formField.owner_mobile ? ' (' + formField.owner_mobile + ')' : ''));
    const [agentInput, setAgentInput] = useState(formField.agent_name + (formField.agent_mobile ? ' (' + formField.agent_mobile + ')' : ''));
    //const [productCodeInput, setProductCodeInput] = useState(formField.product_desc);
    // const [tenantInput, setTenantInput] = useState(formField.tenancy_name);

    const [showAlertCheck, setShowAlertCheck] = useState(((formField.showalert === 'Y') ? true : false));

    const { register, control, handleSubmit, formState: { errors }, reset } = useForm();

    //const [images, setImages] = useState([]);
    //const [remAllImg, setRemAllImg] = useState('hide');
    //const maxNumber = 10;

    //let inputRef;

    const getSelectedItem = (id, code) => {
        //console.log(userOptions);   
        // eslint-disable-next-line
        const item = userOptions.filter(a => a.code === code).find((opt) => {
            if (opt && opt.id === id) {
                return opt;
            }
        });

        return item || null;
    }

    const fetchAgentData = (id) => {
        const params = { owner_id: id }

        axios.post(apiBaseUrl + 'auth/users', params).then(response => response.data).then(resData => {
            if (resData.status === 'success') {
                setAgentData(resData.data);
            }
        }).catch((error) => {
            console.log(error);
            if (error.code === "ERR_NETWORK") {

            }
            setAgentData([]);
        });
    }


    useEffect(() => {
        //console.log(dialogValue);  
        if (dialogValue) {
            updateAgentData();
        }
        if (formField.action === 'edit') {
            //if(dialogField.owner_id !== formField.owner_id){  
            fetchAgentData(formField.owner_id);
            //}
            reset(formField);
        }
        //if (inputRef) inputRef.focus();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dialogValue]);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    /* const onImageChange = (imageList, addUpdateIndex) => {
        // data for submit
        //console.log(imageList, addUpdateIndex);
        setImages(imageList);

        //setFormField((prev) => ({ ...prev, picture: imageList }));

        if (imageList.length > 1) {
            setRemAllImg('show');
        } else {
            setRemAllImg('hide');
        }
    }; */

    const handleMobileChange = (e) => {
        if (e.target.value.match("(_+)") === null) {
            setFormField((prev) => ({ ...prev, mobile: e.target.value }))
            return e.target.value;
        } else return '';
    }

    const updateAgentData = (id) => {
        const params = {};
        if (id) {
            params.owner_id = id;
        } else {
            params.code = 'AGENT';
        }
        axios.post(apiBaseUrl + 'auth/users', params).then(response => response.data).then(resData => {
            if (resData.status === 'success') {
                setAgentData(resData.data);
            }
        }).catch((error) => {
            console.log(error);
            if (error.code === "ERR_NETWORK") {

            }
            setAgentData([]);
        });
        setAgentInput('');
        setFormField((prev) => ({ ...prev, agent_id: '', agent_name: '', agent_prefix: '' }));

    }

    /* const getSelectedProducts = (products) => {
        let productItems = [];

        if (products && lookUpsOptions.PRODUCT.length > 0) {
            let i = 0;
            // eslint-disable-next-line   
            lookUpsOptions.PRODUCT.find((opt) => {
                // eslint-disable-next-line 
                products.map((item) => {
                    if (opt.code === item) {
                        productItems[i] = opt;
                        i++;
                    }
                });
            });
        }
        //console.log(productItems);

        return productItems || [];
    } */

    const getProductSelectedItem = (code) => {
        // eslint-disable-next-line
        const item = lookUpsOptions.PRODUCT.filter(a => a.code === code).find((opt) => {
            return opt;
        });

        return item || '';
    }

    return (
        <Fragment>
            <Box component="form" id="propertyForm" noValidate autoComplete="off" onChange={checkFormChange} onSubmit={handleSubmit(onSubmit)}>
                <AppBar sx={{ position: 'fixed' }} className="app-header-top">
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleDialogClose}
                            aria-label="close"
                            className="me-auto"
                        >
                            <CloseIcon />
                            <Typography sx={{ p: 2, flex: 1, width: 'auto' }} variant="h6" component="div" className="d-none d-lg-block">
                                {actionFun && actionFun[0].toUpperCase() + actionFun.slice(1)} Property
                            </Typography>
                        </IconButton>

                        <Button type="button" autoFocus className="themeholy-btn btn-normal" style={{ marginRight: '10px' }} onClick={handleDialogClose}>
                            Cancel
                        </Button>
                        <Button type="submit" autoFocus className="themeholy-btn btn-normal" disabled={loaderText}>
                            {(loaderText) ? 'Saving...' : 'Save'}
                        </Button>
                    </Toolbar>
                </AppBar>
                <div className="container" style={{ paddingTop: '90px' }}>

                    <Collapse in={alertOpen}>
                        <Alert variant="filled" severity={alertStatus}
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setAlertOpen(false);
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                            sx={{ mb: 2 }}
                        >
                            {alertMessage}
                        </Alert>
                    </Collapse>

                    {/*postcodeOptions && formField.action && pcodeValue  formField.action && (*/}
                    {openForm ?

                        <div className="row">
                            <div className="col-md-12 d-block d-lg-none">
                                <Typography sx={{ marginBottom: '15px' }} variant="h6" component="div">
                                    {actionFun && actionFun[0].toUpperCase() + actionFun.slice(1)} Property
                                </Typography>
                            </div>
                            <div className="col-md-6">

                                <>
                                    <h5 className="mb-3" style={{ textAlign: 'left' }}>Property Info:</h5>
                                    {(propertyId !== '' && formField.owner_id) ?
                                        <div className="row">
                                            <Typography sx={{ mb: 5 }} variant="body" component="label" className="col-md-4">
                                                Land Lord
                                                <input type='hidden' value={formField.owner_id} {...register("owner_id", { required: false })} />
                                            </Typography>
                                            <Typography sx={{ mb: 5 }} variant="body" component="div" className="col-md-8">
                                                {formField.owner_prefix}. {formField.owner_name}
                                            </Typography>
                                        </div>
                                        :

                                        <Controller
                                            render={(props) => (
                                                <Autocomplete
                                                    disableClearable
                                                    options={userOptions.filter(a => a.code === "LDLORD")}
                                                    getOptionLabel={(option) => option.label}
                                                    filterOptions={(options, params) => {
                                                        const filtered = filter(options, params);

                                                        //console.log(params);

                                                        if (params.inputValue && (!filtered.length || (filtered.length > 0 && filtered[0].label !== params.inputValue))) {
                                                            //console.log('text');
                                                            filtered.push({
                                                                id: '',
                                                                code: '',
                                                                label: `Add "${params.inputValue}"`,
                                                            });
                                                        }

                                                        return filtered;
                                                    }}

                                                    renderOption={(args, option) => (
                                                        <Box component="li" {...args} key={option.id}>
                                                            {option.label}
                                                        </Box>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Land Lord *"
                                                            variant="outlined"
                                                            error={!!props.fieldState.error}
                                                            helperText={props.fieldState.error?.message}
                                                        />
                                                    )}
                                                    onChange={(_, data) => {
                                                        console.log(data);
                                                        if (data.id === '' && typeof data.label === 'string') {
                                                            // timeout to avoid instant validation of the dialog's form.
                                                            setTimeout(() => {
                                                                toggleAddDialog(true);
                                                                setFormField((prev) => ({ ...prev, owner_id: '', owner_name: data.label.replace(new RegExp(/^Add |"/, "g"), ''), owner_prefix: '' }));
                                                                setUserFormType('LDLORD');
                                                                setDialogValue(data.label.replace(new RegExp(/^Add |"/, "g"), ''));
                                                            });
                                                        } else if (data.id !== '') {
                                                            props.field.onChange(data.id)
                                                            setFormField((prev) => ({ ...prev, owner_id: data.id, owner_name: data.label, owner_prefix: data.prefix }));
                                                            if (_role !== 'AGENT') updateAgentData(data.id);
                                                            setOwnerInput(data.label);
                                                            checkFormChange();
                                                        }

                                                    }}
                                                    inputValue={ownerInput}

                                                    onInputChange={(_, newInputValue, eventType) => {
                                                        if (eventType === 'reset') {
                                                            setOwnerInput(ownerInput);
                                                        } else {
                                                            setOwnerInput(newInputValue);
                                                        }
                                                    }}

                                                />
                                            )}

                                            value={getSelectedItem(formField.owner_id, "LDLORD")}
                                            name="owner_id"
                                            control={control}
                                            rules={{
                                                required: "Land Lord is required"
                                            }}
                                        />
                                    }
                                    {_role !== 'AGENT' && (<>
                                        {(propertyId !== '' && formField.agent_id) ?
                                            <div className="row">
                                                <Typography sx={{ mb: 5 }} variant="body" component="label" className="col-md-4">
                                                    Agent
                                                    <input type='hidden' value={formField.agent_id} {...register("agent_id", { required: false })} />
                                                </Typography>
                                                <Typography sx={{ mb: 5 }} variant="body" component="div" className="col-md-8">
                                                    {formField.agent_prefix}. {formField.agent_name}
                                                </Typography>
                                            </div>
                                            :


                                            <Autocomplete
                                                sx={{ width: '100%' }}
                                                options={agentData}
                                                getOptionLabel={(option) => option.label}
                                                renderOption={(args, option) => (
                                                    <Box component="li" {...args} key={option.id}>
                                                        {option.label}
                                                    </Box>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Agent"
                                                        variant="outlined"
                                                    />
                                                )}
                                                onChange={(_, data) => {
                                                    //props.field.onChange(data.id)
                                                    if (data) {
                                                        setFormField((prev) => ({ ...prev, agent_id: data.id, agent_name: data.label, agent_prefix: data.prefix }));
                                                        setAgentInput(data.label);
                                                    } else {
                                                        setFormField((prev) => ({ ...prev, agent_id: '', agent_name: '', agent_prefix: '' }));
                                                        setAgentInput('');
                                                    }
                                                    checkFormChange();
                                                }}
                                                inputValue={agentInput}

                                                onInputChange={(_, newInputValue, eventType) => {
                                                    if (eventType === 'reset') {
                                                        setAgentInput(agentInput);
                                                    } else {
                                                        setAgentInput(newInputValue);
                                                    }
                                                }}
                                                isOptionEqualToValue={(options, value) => options.valueOf === value.valueOf}

                                                value={getSelectedItem(formField.agent_id, "AGENT")}
                                                name="agent_id"

                                            />


                                        }

                                    </>)}

                                    <TextField
                                        fullWidth
                                        id="tenancy_name" name="tenancy_name" variant="outlined" error={errors.tenancy_name ? true : false} label="Tenancy Name *"
                                        helperText={errors.tenancy_name?.message}

                                        inputProps={{ maxLength: 100, value: formField.tenancy_name }}

                                        {...register("tenancy_name", { required: "Tenancy Name is required" })}

                                        onChange={(e) => setFormField((prev) => ({ ...prev, tenancy_name: e.target.value }))}

                                    />
                                    <Controller
                                        name="tenancy_mobile"
                                        control={control}
                                        rules={{
                                            required: "A valid mobile number is required",
                                            pattern: {
                                                value: /^04\d{8}$/,
                                                message: 'Please enter a valid mobile number',
                                            }
                                        }}

                                        render={({ field, fieldState }) => (
                                            <PatternFormat
                                                label="Tenancy Mobile *"
                                                customInput={TextField}
                                                type="tel"
                                                format="04########"
                                                allowEmptyFormatting
                                                fullWidth
                                                mask="_"
                                                error={!!fieldState.error}
                                                helperText={fieldState.error?.message}
                                                value={formField.tenancy_mobile}
                                                onChange={(e) => { field.onChange(handleMobileChange(e)) }}
                                            />
                                        )}
                                    />

                                </>

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        disablePast
                                        id="service_duedate"
                                        name="service_duedate"
                                        label="Service Due Date"
                                        format="DD/MM/YYYY"
                                        value={dayjs(formField.service_duedate)}
                                        {...register("service_duedate", { required: "Service Due Date is required" })}
                                        onChange={(newValue) => { setFormField((prev) => ({ ...prev, service_duedate: newValue })); checkFormChange(); }}
                                        readOnly={(_role === 'SYS' || _role === 'ADMSTF') ? false : true}
                                        onError={(newError) => setDateError(newError)}
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                                helperText: dateError ? 'Enter/Choose service date' : ''
                                            },
                                        }}
                                    />
                                </LocalizationProvider>

                                <Autocomplete
                                    sx={{ width: '100%' }}

                                    id="product_code"
                                    name="product_code"
                                    value={getProductSelectedItem(formField.product_code)}

                                    onChange={(event, newValue) => {
                                        if (newValue) {
                                            setFormField((prev) => ({ ...prev, product_code: newValue.code }));
                                            // setProductCodeInput(newValue.label);
                                            checkFormChange();
                                        } else {
                                            setFormField((prev) => ({ ...prev, product_code: '' }));
                                            checkFormChange();
                                        }
                                    }}
                                    /* inputValue={formField.product_desc}
                                    onInputChange={(event, newInputValue, eventType) => {
                                        if (eventType === 'reset') {
                                            setProductCodeInput(productCodeInput);
                                        } else {
                                            setProductCodeInput(newInputValue);
                                        }
                                    }} */

                                    getOptionLabel={(option) => option.label ? option.label : ''}

                                    options={lookUpsOptions.PRODUCT}

                                    renderOption={(props, option) => (
                                        <Box component="li" {...props} key={option.id}>
                                            {option.label}
                                        </Box>
                                    )}

                                    //isOptionEqualToValue={(options, value) => options.valueOf === value.valueOf}

                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Product *"
                                            {...register("product_code", { required: "Product is required" })}
                                            error={errors.product_code ? true : false}
                                            helperText={errors.product_code?.message}
                                        />
                                    )}

                                />


                            </div>
                            <div className="col-md-6">
                                <h5 className="mb-3" style={{ textAlign: 'left' }}>Address Info:</h5>

                                <TextField
                                    fullWidth
                                    id="address1" name="address1" variant="outlined" error={errors.address1 ? true : false} label="Address 1"
                                    helperText={errors.address1?.message}

                                    inputProps={{ maxLength: 40, value: formField.address1 }}

                                    {...register("address1", { required: "Address 1 is required" })}

                                    onChange={(e) => setFormField((prev) => ({ ...prev, address1: e.target.value, changedFlag: 'Y' }))}

                                />
                                <TextField
                                    fullWidth
                                    id="address2" name="address2" variant="outlined" error={errors.address2 ? true : false} label="Address 2"
                                    helperText={errors.address2?.message}

                                    inputProps={{ maxLength: 40, value: ((formField.address2) ? formField.address2 : '') }}

                                    {...register("address2", { required: false })}
                                    onChange={(e) => setFormField((prev) => ({ ...prev, address2: e.target.value }))}

                                />

                                <Autocomplete
                                    sx={{ width: '100%' }}

                                    id="suburb"
                                    name="suburb"
                                    value={pcodeValue}

                                    onChange={(event, newValue) => {
                                        if (newValue) {
                                            setPcodeValue({ code: newValue.code, suburb: newValue.suburb, state_shortname: newValue.state_shortname });
                                            setFormField((prev) => ({ ...prev, suburb: newValue?.suburb, state: newValue?.state_shortname, postcode: newValue?.code }));

                                            //setPcodeInput(newValue.suburb);
                                            checkFormChange();
                                        } else {
                                            setPcodeValue({ code: '', suburb: '', state_shortname: '' });
                                            setFormField((prev) => ({ ...prev, suburb: '', state: '', postcode: '' }));
                                        }
                                    }}
                                    /* inputValue={pcodeInput}
                                    onInputChange={(event, newInputValue, eventType) => {
                                        if (eventType === 'reset') {
                                            setPcodeInput(pcodeInput);
                                        } else {
                                            setPcodeInput(newInputValue);
                                        }
                                    }} */

                                    getOptionLabel={(option) => option.suburb ? option.suburb : ''}

                                    options={postcodeOptions}

                                    renderOption={(props, option) => (
                                        <Box component="li" {...props} key={option.id}>

                                            {option.suburb}, {option.state_shortname} - {option.code}.
                                        </Box>
                                    )}

                                    //isOptionEqualToValue={(options, value) => options.valueOf === value.valueOf}

                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Search Suburb"
                                            error={errors.suburb ? true : false}
                                            helperText={errors.suburb?.message}
                                            {...register("suburb", { required: "Suburb is required" })}

                                        />
                                    )}

                                />

                                <TextField fullWidth id="state" name="state" variant="outlined" error={errors.state ? true : false} label="State" helperText={errors.state?.message} {...register("state", { required: false })} inputProps={{ maxLength: 4, value: formField.state, readOnly: true }} onChange={(e) => setFormField((prev) => ({ ...prev, state: e.target.value }))} />

                                <TextField
                                    fullWidth
                                    id="postcode" name="postcode"
                                    variant="outlined" error={errors.postcode ? true : false}
                                    label="Postcode"
                                    helperText={errors.postcode?.message}
                                    inputProps={{ maxLength: 4, value: formField.postcode, readOnly: true }}

                                    {...register("postcode", { required: false })}
                                    onChange={(e) => setFormField((prev) => ({ ...prev, postcode: e.target.value }))}
                                />

                                {(_role === 'SYS' || _role === 'ADMSTF' || formField.status !== 'P') && actionFun === 'edit' && (
                                    <FormControl fullWidth>
                                        <InputLabel id="status"> Select Status </InputLabel>
                                        <Select
                                            labelId="status"
                                            id="status"
                                            name="status"
                                            error={errors.status ? true : false}
                                            value={formField.status}
                                            label="Select Status"
                                            {...register("status", { required: "Status is required" })}
                                            onChange={(e) => { setFormField((prev) => ({ ...prev, status: e.target.value })); checkFormChange() }}
                                            MenuProps={MenuProps}
                                        >
                                            <MenuItem value='P'>Pending</MenuItem>
                                            <MenuItem value='Y'>Active</MenuItem>
                                            <MenuItem value='N'>Inactive</MenuItem>
                                        </Select>
                                    </FormControl>
                                )}
                            </div>
                            <div className="col-md-12">
                                <div className="text-end">
                                    <FormControlLabel control={<Checkbox name="showalert"
                                        checked={showAlertCheck}
                                        onChange={(event) => { setShowAlertCheck(event.target.checked); setFormField((prev) => ({ ...prev, showalert: event.target.checked ? 'Y' : 'N' })) }} />}
                                        label="Show Alert" labelPlacement="start"

                                    />
                                </div>
                                <textarea
                                    id="alertnote"
                                    name="alertnote"
                                    rows={4}
                                    style={{ backgroundColor: '#ffffff', border: '1px solid #ccc', borderRadius: '4px', width: '100%' }}
                                    placeholder="Notes"

                                    {...register("alertnote", { required: false })}
                                    onChange={(e) => setFormField((prev) => ({ ...prev, alertnote: e.target.value }))}

                                    defaultValue={formField.alertnote}

                                />

                            </div>
                            <div className="col-md-12 mt-3 text-center">
                                <div className='row'>
                                    <div className='col-12 col-md-4 col-lg-6 col-sm-2 col-xl-6 col-xxl-6'></div>
                                    <div className='col-12 col-md-4 col-lg-3 col-sm-5 col-xl-3 col-xxl-3 text-end mb-3'>
                                        <Button type="button" className="themeholy-btn btn-normal w-100" onClick={handleDialogClose}>Cancel</Button>

                                    </div>
                                    <div className='col-12 col-md-4 col-lg-3 col-sm-5 col-xl-3 col-xxl-3 text-end'>
                                        <Button type="submit" autoFocus className="themeholy-btn btn-primary w-100" disabled={loaderText}>{(loaderText) ? 'Saving...' : 'Save'}</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : <div className="text-center p-5 m-5"><CircularProgress /><br />Form is loading. Please wait...</div>}

                    {/*)*/}

                </div>

            </Box>

            <UserFormContext.Provider
                value={{ userFormType, addDialogOpen, toggleAddDialog, dialogValue, setDialogValue, setUserOptions, setOwnerInput, setFormField }}
            >

                <DialogUserForm />

            </UserFormContext.Provider>



        </Fragment>
    );


}