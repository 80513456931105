import { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
    Dialog, DialogActions, DialogContent, DialogTitle, Typography, IconButton, Chip,
    Accordion, AccordionSummary, AccordionDetails, Button, Box, Fade, CircularProgress, Popover
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
//import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FilterIcon from '@mui/icons-material/Filter';
import { apiBaseUrl } from '../../common';
import axios from 'axios';

const _token = localStorage.getItem("_token");
//const _role = localStorage.getItem("_role");
axios.defaults.withCredentials = true;
axios.defaults.headers['Accept'] = 'application/json';
axios.defaults.headers['Content-Type'] = 'application/json';
axios.defaults.headers['Authorization'] = 'Bearer ' + _token;

const AccordionPanel = ({ serviceId, pdtdesc }) => {
    const [loading, setLoading] = useState(true);
    const [chicklistData, setChicklistData] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const openMedia = Boolean(anchorEl);
    const [openMediaId, setOpenMediaId] = useState('');
    const [media, setMedia] = useState(null);

    const CLfetchData = () => {

        axios.get(apiBaseUrl + 'servicereport/' + serviceId)
            .then(response => response.data).then(resData => {

                if (resData.status === 'success') {
                    // console.log(resData.data);
                    setChicklistData(resData.data);
                }
                setLoading(false);

            }).catch((error) => {
                setLoading(false);
            });
    }

    const handleClick = (e, item) => {
        if (item.pictures.length > 0) {
            setOpenMediaId(item.id);
            setMedia(item);
            setAnchorEl(e.currentTarget);
        }
    };

    const handleClose = () => {
        setMedia(null);
        setOpenMediaId('');
        setAnchorEl(null);
    };

    useEffect(() => {

        CLfetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (

        <Fragment>
            <div className='col-md-12 mt-3'>
                <table>
                    <thead>
                        <tr>
                            <th>Service Type</th>
                            <th>No. of items</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><Chip label="Complaint" color="success" size="small" /></td>
                            <td>{chicklistData['Complaint'] ? chicklistData['Complaint'] : 0}</td>
                        </tr>
                        <tr>
                            <td><Chip label="Recommendation" color="primary" size="small" /></td>
                            <td>{chicklistData['Recommendation'] ? chicklistData['Recommendation'] : 0}</td>
                        </tr>
                        <tr>
                            <td><Chip label="Faulty" color="warning" size="small" /></td>
                            <td>{chicklistData['Faulty'] ? chicklistData['Faulty'] : 0}</td>
                        </tr>
                        <tr>
                            <td><Chip label="Non-Complaint" size="small" color="info" /></td>
                            <td>{chicklistData['Non Complaint'] ? chicklistData['Non Complaint'] : 0}</td>
                        </tr>
                        <tr>
                            <td><Chip label="Safety Issue" size="small" color="secondary" /></td>
                            <td>{chicklistData['Safety Issue'] ? chicklistData['Safety Issue'] : 0}</td>
                        </tr>
                        <tr>
                            <td><Chip label="Disconnected" size="small" color="error" /></td>
                            <td>{chicklistData['Disconnected'] ? chicklistData['Disconnected'] : 0}</td>
                        </tr>
                        <tr>
                            <td><Chip label="Not Tested" size="small" color="warning" /></td>
                            <td>{chicklistData['Not Tested'] ? chicklistData['Not Tested'] : 0}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <Typography variant="subtitle" className="sub-title">{pdtdesc}</Typography>

            <Box sx={{ textAlign: 'center' }}>
                <Fade
                    in={loading}
                    unmountOnExit
                >
                    <CircularProgress />
                </Fade>
            </Box>


            {!loading && <>
                {chicklistData && <>
                    {chicklistData['done'] && <>

                        {chicklistData['done']['BSFTYCHK'] && <>
                            <Typography

                                className='fw-extrabold sub-title mt-3'
                            >B. EXTENT OF THE INSTALLATION AND LIMITATIONS OF THE INSPECTION AND TESTING</Typography>
                            {
                                chicklistData['done']['BSFTYCHK'].map((item, index) =>
                                (
                                    <Fragment key={"doneB_" + index}><AccordionContent item={item} index={index} section="doneB" handleClick={handleClick} /></Fragment>
                                ))
                            }
                        </>}

                        {chicklistData['done']['CSFTYCHK'] && <>
                            <Typography

                                className='fw-extrabold sub-title mt-3'
                            >C. SAFETY CHECK - VERIFIED BY VISUAL INSPECTION</Typography>
                            {
                                chicklistData['done']['CSFTYCHK'].map((item, index) =>
                                (
                                    <Fragment key={"doneC_" + index}><AccordionContent item={item} index={index} section="doneC" handleClick={handleClick} /></Fragment>
                                ))
                            }
                        </>}
                        {chicklistData['done']['D1SFTYCHK'] && <>
                            <Typography

                                className='fw-extrabold sub-title mt-3'
                            >D. SAFETY CHECK - VERIFIED BY TESTING</Typography>
                            {
                                chicklistData['done']['D1SFTYCHK'].map((item, index) =>
                                (
                                    <Fragment key={"doneD1_" + index}><AccordionContent item={item} index={index} section="doneD1" handleClick={handleClick} /></Fragment>
                                ))
                            }
                        </>}

                        {

                            chicklistData['done']['D2SFTYCHK'] && chicklistData['done']['D2SFTYCHK'].map((item, index) =>
                            (
                                <Fragment key={"doneD2_" + index}><AccordionContent item={item} index={index} section="doneD2" handleClick={handleClick} /></Fragment>
                            ))
                        }

                        {chicklistData['done']['GASFTYCHK'] && <>
                            <Typography

                                className='fw-extrabold sub-title mt-3'
                            >C. GAS SAFETY CHECK - VERIFIED BY VISUAL INSPECTION</Typography>
                            {
                                chicklistData['done']['GASFTYCHK'].map((item, index) =>
                                (
                                    <Fragment key={"doneGS_SY_" + index}><AccordionContent item={item} index={index} section="doneC" handleClick={handleClick} /></Fragment>
                                ))
                            }
                        </>}

                        {chicklistData['done']['GASERVICE'] && <>
                            <Typography

                                className='fw-extrabold sub-title mt-3'
                            >D. GAS SERVICE CHECK - VERIFIED BY VISUAL INSPECTION</Typography>
                            {
                                chicklistData['done']['GASERVICE'].map((item, index) =>
                                (
                                    <Fragment key={"doneGS_SE_" + index}><AccordionContent item={item} index={index} section="doneC" handleClick={handleClick} /></Fragment>
                                ))
                            }
                        </>}
                    </>}
                    {chicklistData['undone'] &&
                        <Accordion>
                            <AccordionSummary>
                                <Typography variant="subtitle" className="sub-title m-1">Pending Services</Typography>
                                <span className="text-theme p-1"><i className='fa fa-circle-chevron-down'></i></span>
                            </AccordionSummary>
                            <AccordionDetails>
                                {chicklistData['undone']['BSFTYCHK'] && <>
                                    <Typography

                                        className='fw-extrabold sub-title mt-3'
                                    >B. EXTENT OF THE INSTALLATION AND LIMITATIONS OF THE INSPECTION AND TESTING</Typography>
                                    {
                                        chicklistData['undone']['BSFTYCHK'].map((item, index) =>
                                        (
                                            <Fragment key={"undoneB_" + index}><AccordionContent item={item} index={index} section="undoneB" handleClick={handleClick} /></Fragment>
                                        ))
                                    }
                                </>}
                                {chicklistData['undone']['CSFTYCHK'] && <>
                                    <Typography

                                        className='fw-extrabold sub-title mt-3'
                                    >C. SAFETY CHECK - VERIFIED BY VISUAL INSPECTION</Typography>
                                    {
                                        chicklistData['undone']['CSFTYCHK'].map((item, index) =>
                                        (
                                            <Fragment key={"undoneC_" + index}><AccordionContent item={item} index={index} section="undoneC" handleClick={handleClick} /></Fragment>
                                        ))
                                    }
                                </>}
                                {chicklistData['undone']['D1SFTYCHK'] && <>
                                    <Typography

                                        className='fw-extrabold sub-title mt-3'
                                    >D. SAFETY CHECK - VERIFIED BY TESTING</Typography>
                                    {
                                        chicklistData['undone']['D1SFTYCHK'].map((item, index) =>
                                        (
                                            <Fragment key={"undoneD1_" + index}><AccordionContent item={item} index={index} section="undoneD1" handleClick={handleClick} /></Fragment>
                                        ))
                                    }
                                </>}
                                {
                                    chicklistData['undone']['D2SFTYCHK'] && chicklistData['undone']['D2SFTYCHK'].map((item, index) =>
                                    (
                                        <Fragment key={"undoneD2_" + index}><AccordionContent item={item} index={index} section="undoneD2" handleClick={handleClick} /></Fragment>
                                    ))
                                }
                            </AccordionDetails>
                        </Accordion>
                    }

                </>}
            </>}

            <Popover
                id={openMediaId}
                open={openMedia}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}

            >
                {media && (<Fragment>
                    <Typography

                        className='fw-extrabold sub-title m-3 mb-0'
                    >{media.description}</Typography>
                    <IconButton
                        aria-label="popclose"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 0,
                            color: 'black',
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <div className="container p-5 pt-3" style={{ width: '800px' }}>
                        <div className="row">
                            {media.pictures && media.pictures.map((image, index) => (
                                <div className='col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxl-3 p-2 d-flex' key={index}>
                                    <div className="card">
                                        <div className="card-body image-item text-center">
                                            <img src={image.data_url} alt={"image" + index} width='218px' />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </Fragment>)}
            </Popover>
        </Fragment>
    );
}

const AccordionContent = ({ section, item, index, handleClick }) => {

    const colorChip = {
        'Complaint': 'success',
        'Recommendation': 'primary',
        'Disconnected': 'error',
        'Faulty': 'warning',
        'Non Complaint': 'info',
        'Not Tested': 'warning',
        'Safety Issue': 'secondary'
    }

    return (
        <Accordion className='mt-3 mb-3 bg-black' key={section + '_' + index}>
            <AccordionSummary
                expandIcon={false}
                aria-controls={section + '_' + index + "_panel1-content"}
                id={section + '_' + index + "_panel1-header"}
                color="white"
                sx={{ display: 'block' }}
                className='p-0'
            >
                <div className="row text-white m-auto mt-3" style={{ width: '100%' }}>
                    <div className="col-md-6">
                        <p className='text-white'>{item.description}</p>
                        <div style={{ fontSize: '12px' }}>
                            <i className='fa fa-location-arrow'></i> Location/Position: {item.location} <br />
                            <i className='fa fa-circle-chevron-down'></i> Click For More Info
                        </div>

                    </div>
                    <div className="col-md-6 text-end">
                        {item.service_type && (<>{colorChip[item.service_type] ? <Chip label={item.service_type} color={colorChip[item.service_type]} size="small" /> : <Chip label={item.service_type} size="small" />}<br /></>)}
                        <Link onClick={(e) => handleClick(e, item)} className='text-white' style={{ fontSize: '12px' }}>{item.pictures.length} Media <i className="fa fa-images"></i></Link>
                        <div className='row mt-3'>
                            <div className='col-6'></div>
                            <div className='col-3'><span className={"text-" + (item.safe === 'Y' ? 'success' : 'danger')}><i className={"fa fa-circle-" + (item.safe === 'Y' ? 'check' : 'xmark')}></i></span> Safe</div>
                            <div className='col-3'><span className={"text-" + (item.complaint === 'Y' ? 'success' : 'danger')}><i className={"fa fa-circle-" + (item.complaint === 'Y' ? 'check' : 'xmark')}></i></span> Complaint</div>
                        </div>
                    </div>
                </div>

            </AccordionSummary>
            <AccordionDetails className='bg-white'>
                <div className='text-end'>{item.comments}</div>
                <div>
                    <label>Additional Notes</label>
                    {item.additional_notes}
                </div>
                <Accordion className='mt-3 mb-3 bg-smoke'>
                    <AccordionSummary>
                        <div className='row' style={{ width: '100%' }}>
                            <div className='col-md-6'>
                                <div className='row'>
                                    <div className='col-md-1 h4 text-center'><i className='fa fa-list-check'></i> </div>
                                    <div className='col-md-10'>Service Checklist <br />
                                        <span style={{ fontSize: '12px' }}>Click here to view a summary of the checks performed</span>
                                    </div>
                                </div>

                            </div>
                            <div className='col-md-6 text-end'>

                                Click For More Info <i className='fa fa-circle-chevron-down'></i>

                            </div>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className='row' style={{ width: '100%' }}>
                            <div className='col-md-6'>
                                {item.summary}
                            </div>
                            {item.pictures.length > 0 && (
                                <div className='col-md-6 text-end'><Chip onClick={(e) => handleClick(e, item)} icon={<FilterIcon />} label={"View " + item.pictures.length + " Media"} color="warning" size="small" /></div>
                            )}
                        </div>
                    </AccordionDetails>
                </Accordion>
            </AccordionDetails>
        </Accordion>
    )
}



const ServiceReportDialog = ({ dialogOpen, handleDialogClose, jobData }) => {

    let checklist_title = '';

    if (jobData.product.code === 'PROD3LMSM') {
        checklist_title = 'Electrical Safety Check Only';
    } else if (jobData.product.code === 'PROD4LMSM') {
        checklist_title = 'Smoke Alarm Safety Check Only';
    } else if (jobData.service_type === 'ES') {
        checklist_title = 'Electrical & Smoke Alarm Safety Check';
    } else if (jobData.service_type === 'GS') {
        checklist_title = 'Gas Safety Check';
    }


    return (
        <Dialog
            fullWidth={true}
            maxWidth='lg'
            open={dialogOpen}
            onClose={handleDialogClose}
            scroll='body'
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >

            <DialogTitle component="header" id="scroll-dialog-title" className="app-header-top">
                <div className='row'>
                    <div className='col-md-9'>
                        <Typography
                            sx={{ display: 'block', marginTop: '15px', marginBottom: '15px' }}
                            component="h3"
                            variant="subtitle-1"
                            color="white"
                        >
                            Service Report
                        </Typography>
                        <Typography
                            sx={{ display: 'block', marginTop: '15px', marginBottom: '15px' }}
                            component="h6"
                            variant="subtitle-1"
                            color="white"
                        >
                            {checklist_title}
                        </Typography>
                    </div>
                    <div className='col-md-3'>
                        <div className="mobile-logo">
                            <Typography
                                sx={{ display: 'block', marginTop: '15px', marginBottom: '15px' }}
                                component="h3"
                                variant="subtitle-1"
                                color="white"
                            >
                                Safety Ninja
                            </Typography>
                            <p>11/17 Baretta Road, Ravenhall, VIC-3023</p>
                        </div>

                    </div>
                </div>
            </DialogTitle>

            <IconButton
                aria-label="close"
                onClick={handleDialogClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: 'white',
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>

                <div className='row'>
                    <div className='col-md-7 mb-3'>
                        <div className='row'>
                            <div className='col-1 h5'><i className='fa fa-home'></i></div>
                            <div className='col-11'>
                                <Typography variant="body1">Property Address</Typography>
                                <Typography variant="body2">{jobData.property.address1 + (jobData.property.address2 ? ', ' + jobData.property.address2 : '') + ', ' + jobData.property.suburb + ', ' + jobData.property.state + ' - ' + jobData.property.postcode}</Typography>
                            </div>
                            <div className='col-1 h5'><i className='fa fa-building' style={{ marginLeft: '5px' }}></i></div>
                            <div className='col-11'>
                                <Typography variant="body1">Land Lord</Typography>
                                <Typography variant="body2">{jobData.property.propertyowner.prefix + ' ' + jobData.property.propertyowner.lastname + ' ' + jobData.property.propertyowner.firstname}</Typography>
                            </div>
                            {jobData.propertyagent && (
                                <>
                                    <div className='col-1 h5'><i className='fa fa-building'></i></div>
                                    <div className='col-11'>
                                        <Typography variant="body1">Agency</Typography>
                                        <Typography variant="body2">{jobData.property.propertyagent.agency}</Typography>
                                    </div>

                                    <div className='col-1 h5'><i className='fa fa-user'></i></div>
                                    <div className='col-11'>
                                        <Typography variant="body1">Agent</Typography>
                                        <Typography variant="body2">{jobData.property.propertyagent.prefix + ' ' + jobData.property.propertyagent.lastname + ' ' + jobData.property.propertyagent.firstname}</Typography>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <div className='col-md-5'>
                        <div className='row'>
                            <div className='col-1'><i className='fa fa-calendar'></i></div>
                            {/*  <label className='col-5'>Service Date</label>:
                            <span className='col-5'>{jobData.service_date}</span> */}
                            <label className='col-5'>Job Date</label>:
                            <span className='col-5'>{jobData.job_date}</span>
                            <div className='col-1'><i className='fa fa-hashtag'></i></div>
                            <label className='col-5'>Service Number</label>:
                            <span className='col-5'>{jobData.id.toString().padStart(4, "0")}</span>
                            <div className='col-1'><i className='fa fa-user'></i></div>
                            <label className='col-5'>Technician Name</label>:
                            <span className='col-5'>{jobData.technician.prefix + ' ' + jobData.technician.lastname + ' ' + jobData.technician.firstname}</span>
                            <div className='col-1'><i className='fa fa-hashtag'></i></div>
                            <label className='col-5'>Licence Number</label>:
                            <span className='col-5'>{jobData.technician.license_number}</span>
                        </div>
                    </div>
                </div>

                <AccordionPanel serviceId={jobData.id} pdtdesc={jobData.product.description} />

            </DialogContent>
            <DialogActions className='p-4'>
                <Button onClick={handleDialogClose}>Close</Button>
            </DialogActions>

        </Dialog>
    );
}

export default ServiceReportDialog;