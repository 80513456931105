import {
    Dialog, DialogActions, DialogContent, DialogTitle, Typography, IconButton, Button, 
    Table, TableBody, TableRow, TableHead,
    TableFooter,
    TableCell,
    Chip
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Logo from '../../assets/img/splash_logo.png';
import dayjs from 'dayjs';

//const _token = localStorage.getItem("_token");
//const _role = localStorage.getItem("_role");

const InvoiceDialog = ({ dialogOpen, handleDialogClose, invoiceData }) => {

    return (
        <Dialog
            fullWidth={true}
            maxWidth='lg'
            open={dialogOpen}
            onClose={handleDialogClose}
            scroll='body'
            aria-labelledby="invoice-dialog"
            aria-describedby="scroll-dialog-description"
        >

            <DialogTitle component="header" id="invoice-dialog" className="app-header-top p-0">
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-7 pt-4 pb-4'>
                            <Typography
                                sx={{ display: 'block', margin: '15px 35px' }}
                                component="h3"
                                variant="subtitle-1"
                                color="white"
                            >
                                Invoice
                            </Typography>
                        </div>
                        <div className='col-md-5 p-0'>
                            <div className='invoice-logo text-center pt-4 pb-4'>
                                <img src={Logo} className="App-logo" alt="logo" style={{ marginLeft: '15%' }} />
                                <Typography
                                    sx={{ display: 'block', marginLeft: '15%' }}
                                    variant="subtitle-1"
                                    color="black"
                                >
                                    11/17 Baretta Road, Ravenhall, VIC-3023
                                </Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </DialogTitle>

            <IconButton
                aria-label="close"
                onClick={handleDialogClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: 'black',
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <div className="container mt-5">
                    <div className='row'>
                        <div className='col-md-8'>
                            <h6>Property Address:</h6>
                            <Typography
                                sx={{ display: 'block' }}
                                variant="body"
                            >
                                {invoiceData.services.property.address1 + ','} <br />
                                {invoiceData.services.property.address2 && invoiceData.services.property.address2 + ',<br />'}
                                {invoiceData.services.property.suburb + ','} <br />
                                {invoiceData.services.property.state + ' - ' + invoiceData.services.property.postcode}

                            </Typography>
                        </div>
                        <div className='col-md-4'>
                            <div className='row'>
                                <div className='col-md-6'>Invoice Date</div>
                                <div className='col-md-6'>: {invoiceData.invoice_date}</div>
                                <div className='col-md-6'>Invoice Number</div>
                                <div className='col-md-6'>: {invoiceData.invoice_number}</div>
                                <div className='col-md-6'>Service Date</div>
                                <div className='col-md-6'>: {dayjs(invoiceData.services.service_date).format('DD-MM-YYYY')}</div>
                                <div className='col-md-6'>Technician</div>
                                <div className='col-md-6'>: {(invoiceData.services.technician.prefix && invoiceData.services.technician.prefix + '. ') + invoiceData.services.technician.lastname + ' ' + invoiceData.services.technician.firstname}</div>
                                <div className='col-md-6'>Licence Number</div>
                                <div className='col-md-6'>: {invoiceData.services.technician.license_number}</div>
                            </div>
                        </div>
                        <div className='col-md-12 text-end p-5'>
                            <Chip label={invoiceData.paid_status === 'Y' ? 'PAID': 'NOT PAID'} color={invoiceData.paid_status === 'Y' ? 'success': 'error'} />
                        </div>
                        <div className='col-md-12'>
                            <Table>
                                <TableHead className="bg-smoke text-body">
                                    <TableRow>
                                        <TableCell><div className="fs-md">Description</div></TableCell>
                                        <TableCell><div className="fs-md">Service Charge AUD</div></TableCell>  
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            {invoiceData.description ? invoiceData.description : invoiceData.services.product.description}
                                        </TableCell>
                                        <TableCell className='text-end'>{invoiceData.amount}</TableCell> 
                                    </TableRow>
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TableCell className='text-end'><div className="fs-md">Subtotal AUD</div></TableCell>
                                        <TableCell className='text-end'><div className="fs-md">{invoiceData.amount}</div></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className='text-end'><div className="fs-md">Total GST 10%</div></TableCell>
                                        <TableCell className='text-end'><div className="fs-md">{invoiceData.amount * 10/100}</div></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className='text-end'><div className="fs-md">Total Amount AUD</div></TableCell>
                                        <TableCell className='text-end'><div className="fs-md">{invoiceData.amount + (invoiceData.amount * 10/100)}</div></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className='text-end'><div className="fs-md">Received Amount AUD</div></TableCell>
                                        <TableCell className='text-end'><div className="fs-md">{invoiceData.received}</div></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className='text-end'><div className="sub-title m-0">Balance Amount AUD</div></TableCell>
                                        <TableCell className='text-end'><div className="sub-title m-0">{(invoiceData.amount + (invoiceData.amount * 10/100)) - invoiceData.received}</div></TableCell>
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </div>
                    </div>
                </div>

            </DialogContent>
            <DialogActions className='p-4'>
                <Button onClick={handleDialogClose}>Close</Button>
            </DialogActions>

        </Dialog>
    );
}

export default InvoiceDialog;